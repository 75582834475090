import { BaseService } from "./BaseService";
import { IUser } from '../../../shared/models/user'; 
import { AuthToken } from '../../../shared/models/auth-token'; 
import { yieldRegionService } from "./YieldRegionService";

export class AuthService extends BaseService{


    get user(): IUser | undefined | null{
        return this.getItem<IUser>("_user");
    }

    set user(_user: IUser | undefined | null){
        this.setItem("_user", _user);
    }

    login(email: string, password: string){
        return new Promise <AuthToken>((resolve, reject) => {
            this.post<AuthToken>('auth/login', { email, password }).then((res) => {
                this.token = res.accessToken;
                this.user = res.user;
                resolve(res);
            }).catch(err => reject(err))  
        });
    }

    recover(email: string, lang: string = 'en'){
        return new Promise <AuthToken>((resolve, reject) => {
            this.post<AuthToken>('auth/remind', { email, lang }).then((res) => {

                resolve(res);
            }).catch(err => reject(err))  
        });
    }

    reset(token: string, password: string, repassword: string){
        this.token = token;
        return new Promise <AuthToken>((resolve, reject) => {
            this.post<AuthToken>('auth/reset', { token, password, repassword }).then((res) => {
                this.token = null;
                resolve(res);
            }).catch(err => reject(err))  
        });
    }

    logout(){
        this.token = null;
        this.user = null;
        yieldRegionService.resetFilters();

    }

    impersonate(token: string){
        yieldRegionService.resetValidYearsCache();
        yieldRegionService.resetFilters();
        this.token = token;
        return new Promise <IUser>((resolve, reject) => {
            this.get<IUser>('auth/me').then((res) => {
                this.user = res;
                resolve(res);
            }).catch(err => reject(err))  
        });
    }

}

export const authService = new AuthService();