import Draw from "leaflet-draw"; // eslint-disable-line
import isEqual from "lodash-es/isEqual";

import { MapControl, withLeaflet } from "react-leaflet";
import leaflet, { Map, Control } from "leaflet";

const eventHandlers = {
  onEdited: "draw:edited",
  onDrawStart: "draw:drawstart",
  onDrawStop: "draw:drawstop",
  onDrawCreated: "draw:created",
  onDrawVertex: "draw:drawvertex",
  onEditStart: "draw:editstart",
  onEditMove: "draw:editmove",
  onEditResize: "draw:editresize",
  onEditVertex: "draw:editvertex",
  onEditStop: "draw:editstop",
  onDeleted: "draw:deleted",
  onDeleteStart: "draw:deletestart",
  onDeleteStop: "draw:deletestop"
};

class EditControl extends MapControl {

  createLeafletElement(props) {
    return createDrawElement(props);
  }

  onDrawCreate = (e) => {
    const { onCreated } = this.props;
    const { layerContainer } = this.props.leaflet;

    layerContainer.addLayer(e.layer);
    onCreated && onCreated(e);
  };

  componentDidMount() {
    super.componentDidMount();
    const { map } = this.props.leaflet;
    const { onMounted } = this.props;

    for (const key in eventHandlers) {
      if (this.props[key]) {
        map.on(eventHandlers[key], (event)=>{
          this.props[key](event);
        });
      }
    }

    map.on(leaflet.Draw.Event.CREATED, this.onDrawCreate);

    onMounted && onMounted(this.leafletElement);
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    const { map } = this.props.leaflet;

    map.off(leaflet.Draw.Event.CREATED, this.onDrawCreate);

    for (const key in eventHandlers) {
      if (this.props[key]) {
        map.off(eventHandlers[key], this.props[key]);
      }
    }
  }

  componentDidUpdate(prevProps) {
    // super updates positions if thats all that changed so call this first
    super.componentDidUpdate(prevProps);

    if (
      isEqual(this.props.draw, prevProps.draw) ||
      this.props.position !== prevProps.position
    ) {
      return false;
    }

    const { map } = this.props.leaflet;


    this.leafletElement.remove(map);
    this.leafletElement = createDrawElement(this.props);
    this.leafletElement.addTo(map);

    return null;
  }


  createPolygon() {
    const { map } = this.props.leaflet;
    return new leaflet.Draw.Polygon(map, {
       shapeOptions: {
          color: '#ff9100',
          fillColor: '#ff9100'
        }
    });
  }

  createComment() {
    const { map } = this.props.leaflet;
    return new leaflet.Draw.Polygon(map, {
      shapeOptions: {
        color: '#5699FF',
        fillColor: '#5699FF'
      }
    });
  }
}


function createDrawElement(props) {
  const { layerContainer } = props.leaflet;
  const { draw, edit, position } = props;
  const options = {
    edit: {
      ...edit,
      featureGroup: layerContainer,
    },
  };

  if (draw) {
    options.draw = { ...draw };
  }

  options.draw = {
      rectangle : false,
      circle: false,
      marker : false,
      circlemarker : false,
      polyline : false,
      polygon: false,
      edit: false,
      delete: false
  }

  if (position) {
    options.position = position;
  }

  return new Control.Draw(options);
}
export default withLeaflet(EditControl);
