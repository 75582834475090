import isCordova from "./components/Util/isCordova";

export const apiUrl = isCordova ? "https://www.peekelite.com/api" : "/api";
//export const apiUrl = isCordova ? 'https://test.peekelite.com/api' : '/api';
//export const apiUrl = isCordova ? 'http://localhost:8081/api' : '/api';
//export const apiUrl = isCordova ? 'http://10.0.2.2:8081/api' : '/api';

const hostname = window.location.hostname;
const localhost = "localhost";


export function getImageBucket(suffix: string = "") {
  // Verificar si el dominio comienza con 'test.'
  if(isCordova){
    if (apiUrl.indexOf("test.") > -1 || apiUrl.indexOf(localhost) > -1) {
      return "https://peekelite-stg.s3.eu-west-3.amazonaws.com/" + suffix;
    } else {
      return "https://peeklite.s3.eu-west-3.amazonaws.com/" + suffix;
    }
  }else{
    if (hostname.startsWith("test.") || hostname.startsWith(localhost)) {
      return "https://peekelite-stg.s3.eu-west-3.amazonaws.com/" + suffix;
    } else {
      return "https://peeklite.s3.eu-west-3.amazonaws.com/" + suffix;
    }
  }

}

export function getImage(image: string = "") {
  // Verificar si el dominio comienza con 'test.'
  if(isCordova){
    if (apiUrl.indexOf("test.") > -1 || apiUrl.indexOf(localhost) > -1) {
      return "https://test.peekelite.com" + image;
    } else {
      return "https://www.peekelite.com" + image;
    }
  }else{
    return image;
  }

}
