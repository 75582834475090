import { apiUrl } from "../config";
import { AlertDialog } from "../components/Dialogs/AlertDialog";

import { ReplaySubject } from "rxjs";
import { createBrowserHistory } from "history";
import { modalService } from "./ModalService";
import i18n from "../i18n";

export const onError = new ReplaySubject();

export const onTokenExpired = new ReplaySubject();

export class BaseService {
  getHeaders() {
    const headers: any = {
      "Content-Type": "application/json",
    };

    if (this.token) {
      headers["Authorization"] = "Bearer " + this.token;
    }

    return headers;
  }

  set token(t: string | null) {
    if (t == null) {
      window.localStorage.removeItem("_token");
    } else {
      window.localStorage.setItem("_token", t);
    }
  }

  get token(): string | null {
    return window.localStorage.getItem("_token");
  }

  request<T>(
    url: string,
    method: string = "GET",
    params?: any,
    otherOptions?: any
  ) {
    const history = createBrowserHistory();

    const options: any = {
      method,
      headers: this.getHeaders(),
      ...otherOptions,
    };

    if (params) {
      if (method === "GET" || method === "DELETE") {
        url += "?" + this.objectToQueryString(params);
      } else {
        if (params instanceof FormData) {
          delete options.headers["Content-Type"];
          options.body = params;
        } else {
          options.body = JSON.stringify(params);
        }
      }
    }

    //console.log("fetch ", `${apiUrl}/${url}`, options);
    return fetch(`${apiUrl}/${url}`, options).then(async (res) => {
      if (!res.ok) {
        const isHome = history.location.pathname === "/";
        if (isHome) {
          throw new Error(res.statusText);
        } else {
          if (res.status === 403) {
            const err = await res.json();
            onError.next(err);
            return null;
          } else if (res.status === 401) {
            this.token = null;
            history.push("/");
            modalService.error({
              text: i18n.t("Session expired"),
              didClose: () => {
                history.push("/");
                document.location.href = "/";
              },
            });
          } else {
            console.error(res.statusText);
            throw new Error(res.statusText);
          }
        }
      }
      return res.json();
    }) as Promise<T>;
  }

  objectToQueryString(obj: any) {
    return Object.keys(obj)
      .map((key) => key + "=" + obj[key])
      .join("&");
  }

  get<T>(url: string, params?: any, otherOptions?: any) {
    return this.request<T>(url, "GET", params, otherOptions);
  }

  put<T>(url: string, params?: any) {
    return this.request<T>(url, "PUT", params);
  }

  post<T>(url: string, params?: any, otherOptions?: any) {
    return this.request<T>(url, "POST", params, otherOptions);
  }

  delete<T>(url: string, params?: any) {
    return this.request<T>(url, "DELETE", params);
  }

  postCustom(url: string, options: any) {
    return fetch(`${apiUrl}/${url}`, {
      ...{ headers: this.getHeaders() },
      ...options,
    });
  }

  getItem<T>(key: string) {
    const item = window.localStorage.getItem(key);
    return item ? (JSON.parse(item) as T) : undefined;
  }

  setItem(key: string, val: any) {
    if (val === null || val === undefined) {
      this.removeItem(key);
    } else {
      window.localStorage.setItem(key, JSON.stringify(val));
    }
  }

  removeItem(key: string) {
    window.localStorage.removeItem(key);
  }
}
