import React, { useEffect } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { useTranslation, Trans } from "react-i18next";
import "./Companies.css";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import {
  AppBar,
  Toolbar,
  Box,
  FormControl,
  Input,
  InputAdornment,
  Icon,
  IconButton,
  Button,
  TableSortLabel,
} from "@material-ui/core";
import { CompanyStats } from "./Company.model";
import { userService } from "../../services/UserService";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      backgroundColor: "#8cc981",
      top: "60px",
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    buttons: {
      "& > *": {
        margin: theme.spacing(1),
      },
    },
    title: {
      flexGrow: 1,
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
  })
);

type Order = "asc" | "desc";

function Companies() {
  const classes = useStyles();
  const history = useHistory();

  const { t, i18n } = useTranslation();
  const [rows, setRows] = React.useState<CompanyStats[]>([]);
  const [open, setOpen] = React.useState(false);
  const [orderBy, setOrderBy] = React.useState("createdAt");
  const [order, setOrder] = React.useState<Order>("desc");
  const [q, setQ] = React.useState<string | undefined>(undefined);

  const [count, setCount] = React.useState(0);

  const headCells = [
    { id: "company", label: t("Company"), align: "left" },
    { id: "numberOfUsers", label: t("Number of users") },
    { id: "totalSurfaceNDVI", label: t("Total NDVI Surface") },
    { id: "totalSurfaceYield", label: t("Total Yield Surface") },
    { id: "totalTimeUsed", label: t("Total time used") },
    { id: "actions", label: t("Actions") },
  ];

  const createSortHandler = (property: string, event: any) => {
    const newOrder = order == "asc" ? "desc" : "asc";
    setOrderBy(property);
    setOrder(newOrder);
  };

  const loadResults = async () => {
    const result = await userService.getCompanies();
    setCount(result.length);
    setRows(result);
  };

  const resetSearch = () => {
    setQ("");
  };

  const openUsers = (company: string) => {
    history.push("/users/" + company);
  };

  useEffect(() => {
    loadResults();
  }, []);

  return (
    <>
      {/*<AppBar position="fixed" className={classes.root}>
        <Toolbar>
          <Box className="search-box" display="flex">
            <FormControl className="flex">
              <Input
                id="search-box"
                name="search-box"
                placeholder={t("Search cultures")}
                disableUnderline={true}
                autoComplete="off"
                value={q}
                onChange={(evt) => setQ(evt.target.value)}
                startAdornment={
                  <InputAdornment position="start">
                    <Icon>search</Icon>
                  </InputAdornment>
                }
                endAdornment={
                  <>
                    {q != "" ? (
                      <IconButton component="span" onClick={resetSearch}>
                        <Icon>close</Icon>
                      </IconButton>
                    ) : null}
                    <Button color="primary" onClick={search}>
                      <Trans>Search</Trans>
                    </Button>
                  </>
                }
              />
            </FormControl>
          </Box>
          <span className={classes.title}></span>
        </Toolbar>
      </AppBar>
              */}
      <div className="table-cultures-container">
        <TableContainer component={Paper}>
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align={headCell.align ? "left" : "center"}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={(evt) => createSortHandler(headCell.id, evt)}
                    >
                      {headCell.label}
                      {orderBy === headCell.id ? (
                        <span className={classes.visuallyHidden}>
                          {order === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </span>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.company}>
                  <TableCell component="th" scope="row">
                    {row.company ? row.company : "-"}
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    {row.numberOfUsers}
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    {row.totalSurfaceNDVI.toFixed(2)}
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    {row.totalSurfaceYield.toFixed(2)}
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    {(row.totalTimeUsed / 60).toFixed(2)} min
                  </TableCell>
                  <TableCell align="center">
                    <IconButton
                      color="primary"
                      onClick={() => openUsers(row.company ? row.company : "")}
                      component="span"
                    >
                      <Icon>visibility</Icon>
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
}

export default Companies;
