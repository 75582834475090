/* eslint-disable jsx-a11y/alt-text */
import React, { Component, createRef, useRef } from "react"; // let's also import Component
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  LinearProgress,
  Menu,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { Trans } from "react-i18next";
import "moment-timezone";
import { SlideTransition } from "../Util/Animations";
import { userService } from "../../services/UserService";
import { IComment } from "../../../../shared/models/comment";
import { IDamage } from "../../../../shared/models/damage";
import { IHisto, IParcelle } from "../../../../shared/models/parcelle";
import { getImageBucket } from "../../config";
import { Camera as CameraIcon, PictureInPicture as PictureIcon } from "@material-ui/icons";
import isCordova from "../Util/isCordova";

declare var Camera: any;

export class AddCommentsProps {
  open: boolean = true;
  comment?: IComment;
  onClose?: (event: Event) => void;
  onSave?: (comment: IComment) => void;
}

export class AddCommentsState {
  open: boolean = true;
  comment?: IComment;
  images?: string[];
  damage?: any;
  uploading?: boolean;
  uploaded?: number;
  remaining?: number;
  openMenu?: boolean;
  anchorEl?: HTMLElement | null;
}

export class AddComment extends Component<AddCommentsProps, AddCommentsState> {
  constructor(props: Readonly<AddCommentsProps>) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  fileRef? = createRef<HTMLInputElement>();

  state = {
    open: false,
    comment: {} as IComment,
    damages: [] as IDamage[],
    images: [] as string[],
    damage: null,
    uploading: false,
    uploaded: 0,
    remaining: 0,
    openMenu: false,
    anchorEl: null,
  };

  static getDerivedStateFromProps(
    props: AddCommentsProps,
    state: AddCommentsState
  ) {
    return {
      ...state,
      open: props.open || false,
      comment: props.comment,
      images:
        state.images && state.images.length > 0
          ? state.images
          : props.comment?.images || [],
      damage: state.damage
        ? state.damage
        : props.comment?.damage
        ? props.comment.damage._id
        : null,
    };
  }

  async componentDidMount() {
    const damages = await userService.getDamages();
    this.setState({ ...this.state, ...{ damages: damages } });
  }

  onClose(event: any) {    
    if (this.props.onClose) {
      this.props.onClose(event);
    }
  }

  async onSave(event: any) {
    if (this.props.onSave) {
      if (
        this.state.comment &&
        this.state.comment.shape &&
        this.state.comment.shape.coordinates
      ) {
        this.state.comment.shape.coordinates = this.state.comment.shape.coordinates.map(
          (coords: any) => {
            return coords.lng ? [coords.lng, coords.lat] : coords;
          }
        );
      }
      const c = this.state.comment;
      const commentRequest = {
        _id: c._id,
        parcelleName: c.parcelle.name || c.parcelleName,
        histo: { _id: c.histo._id || c.histo } as IHisto,
        parcelle: { _id: c.parcelle._id || c.parcelle } as IParcelle,
        shape: c.shape,
        comment: c.comment,
        images: this.state.images,
        damage: this.state.damage
          ? ({
              _id: this.state.damage as any,
            } as IDamage)
          : c.damage,
        estimated_return: c.estimated_return,
        actual_return: c.actual_return,
        label: c.label,
      } as IComment;

      await userService.createComment(
        commentRequest.parcelleName,
        commentRequest
      );

      this.props.onSave(this.state.comment);
    }
  }

  handleChangeClimateHazard(
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) {
    this.setState({ ...this.state, ...{ damage: event.target.value as any } });
  }

  triggerFileUpload(event?: any) {

    if(isCordova){
      this.setState({ ...this.state, openMenu: true, anchorEl: event.currentTarget});
    }else{
      this.fileRef?.current?.click();
    }
  }

  handleChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: string
  ) {
    const newState = { ...this.state } as any;
    newState.comment[field] = event.target.value;
    this.setState(newState);
  }

  onRemoveImage(event: any, image: string) {
    const images = this.state.images || [];
    const index = images.indexOf(image);
    images.splice(index, 1);
    this.setState({ ...this.state, images });
  }

  onFileSelectedFromCameraFile(camera:boolean) {
    const self = this;
    this.setState({ ...this.state, openMenu: false });
    // Opciones de la cámara/galería
    var options = {
      quality: 50,
      sourceType: camera ? Camera.PictureSourceType.CAMERA : Camera.PictureSourceType.PHOTOLIBRARY, 
      destinationType: Camera.DestinationType.FILE_URI,
      encodingType: Camera.EncodingType.JPEG,
    };

    // Utiliza el plugin de la cámara para obtener la imagen
    (navigator as any).camera.getPicture(
       (imageUri: string) => {
        let images = self.state.images || [];
        (window as any).resolveLocalFileSystemURL(imageUri, (fileEntry:any) => {
          fileEntry.file(async (file: File) => {
            const reader = new FileReader();
            reader.onloadend = async () => {
              if (reader.result instanceof ArrayBuffer) {
                const blob = new Blob([reader.result], { type: "image/jpeg" });
                const image = await userService.uploadFileComment(
                    blob, // Aquí pasas el Blob en lugar del objeto File
                    self.state.comment.parcelle.name || self.state.comment.parcelleName
                );
                images = [...images, image.fileName];
                self.setState({
                    ...self.state,
                    images,
                });
              }
            };
            reader.readAsArrayBuffer(file);
          }, (error:any) => {
              console.error(error);
          });
      }, (error:any) => {
          console.error(error);
      });
         
      },
      (error: any) => {
        console.error('Error al obtener la imagen: ', error);
      },
      options
    );
  }


  onFileSelectedFromCamera(camera: boolean) {
    const self = this;
    this.setState({ ...this.state, openMenu: false });
    // Opciones de la cámara/galería
    var options = {
        quality: 50,
        sourceType: camera ? Camera.PictureSourceType.CAMERA : Camera.PictureSourceType.PHOTOLIBRARY,
        destinationType: Camera.DestinationType.DATA_URL, // Cambiado a DATA_URL para obtener base64
        encodingType: Camera.EncodingType.JPEG,
    };

    // Utiliza el plugin de la cámara para obtener la imagen en base64
    (navigator as any).camera.getPicture(
        (base64Image: string) => {
            let images = self.state.images || [];
            
            // Convierte base64 a Blob
            const blob = self.base64ToBlob(base64Image, 'image/jpeg');

            // Suponiendo que userService.uploadFileComment puede manejar un Blob
            userService.uploadFileComment(blob, self.state.comment.parcelle.name || self.state.comment.parcelleName)
                .then(image => {
                    images = [...images, image.fileName];
                    self.setState({
                        ...self.state,
                        images,
                    });
                })
                .catch(error => {
                    console.error("Error al subir la imagen:", error);
                });
            },
            (error: any) => {
                console.error('Error al obtener la imagen: ', error);
            },
            options
        );
    }

    // Función para convertir base64 a Blob
    base64ToBlob(base64: string, contentType: string) {
        const byteCharacters = atob(base64);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += 512) {
            const slice = byteCharacters.slice(offset, offset + 512);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }


  async onChangeFile(event: any) {
    this.setState({
      ...this.state,
      uploading: true,
      uploaded: 0,
      remaining: event.target.files.length,
    });

    if (event && event.target && event.target.files) {
      let images = this.state.images || [];
      for (const file of event.target.files) {
        const image = await userService.uploadFileComment(
          file,
          this.state.comment.parcelle.name || this.state.comment.parcelleName
        );
        images = [...images, image.fileName];

        this.setState({
          ...this.state,
          uploaded: this.state.uploaded++,
          images,
        });
      }

      this.setState({
        ...this.state,
        images,
        uploading: false,
      });
    }
  }

  // render will know everything!
  render() {
    return (
      <Dialog
        open={this.state.open}
        TransitionComponent={SlideTransition as any}
        keepMounted
        onClose={(event) => this.onClose(event)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        {this.state.comment ? (
          <DialogContent>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="clima-label">
                <Trans>Weather event</Trans>
              </InputLabel>
              <Select
                labelId="clima-label"
                id="clima-select"
                value={this.state.damage}
                label={<Trans>Weather event</Trans>}
                onChange={(evt) => this.handleChangeClimateHazard(evt)}
              >
                {this.state.damages.map((damage) => (
                  <MenuItem value={damage._id}>{damage.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              autoFocus
              variant="outlined"
              margin="normal"
              id="estimated_return"
              label={<Trans>Estimated yield</Trans>}
              type="number"
              onChange={(evt) => this.handleChange(evt, "estimated_return")}
              value={this.state.comment.estimated_return || ""}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Trans>quintaux/ha</Trans>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              autoFocus
              variant="outlined"
              margin="normal"
              id="actual_return"
              label={<Trans>Actual yield</Trans>}
              type="number"
              onChange={(evt) => this.handleChange(evt, "actual_return")}
              value={this.state.comment.actual_return || ""}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Trans>quintaux/ha</Trans>
                  </InputAdornment>
                ),
              }}
            />
            {this.state.comment.shape ? (
              <TextField
                autoFocus
                variant="outlined"
                margin="normal"
                id="label"
                label={<Trans>Polygon name</Trans>}
                type="text"
                onChange={(evt) => this.handleChange(evt, "label")}
                value={this.state.comment.label || ""}
                fullWidth
              />
            ) : null}

            <TextField
              autoFocus
              variant="outlined"
              margin="normal"
              id="comments"
              label={<Trans>Comments</Trans>}
              multiline
              fullWidth
              onChange={(evt) => this.handleChange(evt, "comment")}
              value={this.state.comment.comment || ""}
              rows={5}
            />
          </DialogContent>
        ) : null}

        <input
          type="file"
          accept="image/*"
          ref={this.fileRef}
          onChange={(event) => this.onChangeFile(event)}
          style={{ display: "none" }}
        />

        {this.state.uploading && <LinearProgress />}
        {this.state && this.state.images && (
          <div
            className="images"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "10px",
              margin: "20px",
            }}
          >
            {this.state.images?.map((img, index) => (
              <div style={{ position: "relative" }}>
                <img
                  key={`img-${index}`}
                  src={getImageBucket(img)}
                  style={{ maxHeight: "100px" }}
                />
                <div style={{ position: "absolute" }}>
                  <Button
                    onClick={(event) => this.onRemoveImage(event, img)}
                    color="primary"
                  >
                    <Trans>Eliminer</Trans>
                  </Button>
                </div>
              </div>
            ))}
          </div>
        )}

        <DialogActions>
          <Button
            color="secondary"
            onClick={(event) => 
              this.triggerFileUpload(event)
            }
          >
            <Trans>Upload image</Trans>
          </Button>
          <Button onClick={(event) => this.onSave(event)} color="primary">
            <Trans>Save</Trans>
          </Button>
          <Button onClick={(event) => this.onClose(event)}>
            <Trans>Close</Trans>
          </Button>
          <Menu
            anchorEl={this.state.anchorEl}
            id="cordova-file-menu"
            keepMounted
            open={this.state.openMenu}
            onClose={(event) => this.setState({ ...this.state, openMenu: false })}
          >
            <MenuItem onClick={(event)=> this.onFileSelectedFromCamera(true)}>
            <ListItemIcon>
              <CameraIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText> <Trans>Upload from Camera</Trans> </ListItemText>
            </MenuItem>
            <MenuItem onClick={(event)=> this.onFileSelectedFromCamera(false)}>
            <ListItemIcon>
              <PictureIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText><Trans>Upload from Gallery</Trans></ListItemText>
            </MenuItem>
          </Menu>
        </DialogActions>
      </Dialog>
    );
  }
}
