import Swal, { SweetAlertResult } from "sweetalert2";
import i18n from "../i18n";
import green from "@material-ui/core/colors/green";

const bgColor = green[300];

export class ModalService {
  error(options: any = {}) {
    this.hideLoading();
    const defaults = {
      icon: "error",
      confirmButtonText: i18n.t("OK"),
      confirmButtonColor: bgColor,
    };

    return Swal.fire({ ...defaults, ...options });
  }

  info(options: any = {}) {
    this.hideLoading();
    const defaults = {
      icon: "info",
      confirmButtonText: i18n.t("OK"),
      confirmButtonColor: bgColor,
    };

    return Swal.fire({ ...defaults, ...options });
  }

  input(options: any = {}) {
    this.hideLoading();
    const defaults = {
      confirmButtonText: i18n.t("OK"),
      confirmButtonColor: bgColor,
    };

    return Swal.fire({ ...defaults, ...options });
  }
  success(options: any = {}) {
    this.hideLoading();
    const defaults = {
      icon: "success",
      confirmButtonText: i18n.t("OK"),
      confirmButtonColor: bgColor,
    };

    return Swal.fire({ ...defaults, ...options });
  }

  alert(options: any = {}) {
    this.hideLoading();
    const defaults = {
      icon: "warning",
      confirmButtonText: i18n.t("OK"),
      confirmButtonColor: bgColor,
      showCancelButton: true,
      cancelButtonText: i18n.t("Cancel"),
      cancelButtonColor: "red",
    };

    return Swal.fire({ ...defaults, ...options });
  }

  loading?: Promise<SweetAlertResult<any>>;
  showLoading() {
    this.loading = Swal.fire({
      title: i18n.t("Loading..."),
      timerProgressBar: true,
      showConfirmButton: false,
      showCancelButton: false,
      didOpen: () => Swal.showLoading(null),
    });
  }

  hideLoading() {
    if (this.loading) {
      Swal.close();
      this.loading = undefined;
    }
  }
}

export const modalService = new ModalService();
