import React, { useEffect, useRef } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { useTranslation, Trans } from "react-i18next";
import {
  Input,
  InputAdornment,
  Button,
  TableSortLabel,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Box,
  LinearProgress,
  Card,
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import { IYieldRegionsClient } from "../../../../shared/models/regions";
import { yieldRegionService } from "../../services/YieldRegionService";
import { Filters, YieldScale } from "./Filters/Filters";

import "./Simulation.css";
import { authService } from "../../services/AuthService";
import { ICulture } from "../../../../shared/models/parcelle";
import { YieldRegionQuery } from "./Yieldregions";
import { useHistory } from "react-router-dom";
import { parse, unparse } from "papaparse";
import { modalService } from "../../services/ModalService";
import { DEFAULT_SCALE, Map } from "./Map/Map";
import { Chart } from "./Chart/Chart";
import {
  ChartDistribution,
  YieldDistribution,
} from "./Chart/ChartDistribution";
import { isNumber } from "lodash-es";

let isLoading = false;

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      backgroundColor: "#8cc981",
      top: "60px",
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    buttons: {
      "& > *": {
        margin: theme.spacing(1),
      },
    },
    title: {
      flexGrow: 1,
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
  })
);

type Order = "asc" | "desc";

const headCellsE1 = [{ id: "name", label: "Region", align: "left" }];

const headCellsE2 = (label: string) => [
  { id: "departement", label, align: "left" },
];

const headCellsBase = [
  {
    id: "market_shares",
    label: "Relative weight in market share (%)",
    align: "left",
  },
  { id: "surface", label: "Insured area (thousand ha)" },
  { id: "deductible", label: "Average deductible (%)" },
  {
    id: "yield_std",
    label: "Reference price (eur/qt)",
    align: "pricing",
  },
  {
    id: "yield_ref",
    label: "Reference yield (qt/ha)",
    align: "center",
  },
  {
    id: "insured_capital",
    label: "Sum insured (thousands of euros)",
    align: "right",
  },
  {
    id: "losses",
    label: "Theoretical loss (thousands of euros)",
    align: "right",
  },
];

function Simulation() {
  const filters = yieldRegionService.filters;
  const classes = useStyles();
  const rpp = 15;
  const cp = 0;
  const history = useHistory();

  let initialVue = filters?.selectedVue || YieldScale.E1;

  if (
    authService.user &&
    authService.user.views &&
    authService.user?.views.length > 0
  ) {
    if (authService.user?.views.indexOf(initialVue) === -1) {
      initialVue = authService.user?.views[0];
    }
  }
  const { t } = useTranslation();
  const [rows, setRows] = React.useState<IYieldRegionsClient[]>([]);
  const [defaultField, setDefaultField] = React.useState<string>(
    "insured_capital"
  );

  const [rowsToSave, setRowsToSave] = React.useState<any[]>([]);

  const fileObjectRef = useRef<any>();

  const [distribution, setDistribution] = React.useState<YieldDistribution[]>(
    []
  );

  const [importVisible, setImportVisible] = React.useState(false);
  const [importActive, setImportActive] = React.useState(false);

  const [headCells, setHeadCells] = React.useState([
    ...headCellsE1,
    ...headCellsBase,
  ]);

  const [selectedRegions, setSelectedRegions] = React.useState<
    IYieldRegionsClient[]
  >([]);

  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const [noExportAllowed, setNoExportAllowed] = React.useState(false);
  const [distributionMode, setDistributionMode] = React.useState(false);

  const [yieldRegion, setDamage] = React.useState({} as IYieldRegionsClient);
  const [orderBy, setOrderBy] = React.useState(filters?.orderBy || "name");
  const [order, setOrder] = React.useState<Order>(
    (filters?.order as Order) || "desc"
  );
  const [updateOrder, setUpdateOrder] = React.useState<number>(0);

  const [q, setQ] = React.useState<string | undefined>(undefined);
  const [selectedCulture, setSelectedCulture] = React.useState<string>(
    filters?.selectedCulture || "BTH"
  );

  const [selectedClient, setSelectedClient] = React.useState<string>(
    filters?.selectedClient || ""
  );

  const [selectedCrop, setSelectedCrop] = React.useState<ICulture>();

  const [scaleFactor, setScaleFactor] = React.useState<number>(DEFAULT_SCALE);

  const [selectedVue, setSelectedVue] = React.useState<string | unknown>(
    initialVue
  );

  const [selectedRegion, setSelectedRegion] = React.useState<string | unknown>(
    filters?.selectedRegion
  );

  const [selectedYear, setSelectedYear] = React.useState<number>(
    yieldRegionService.getEfectiveYear(filters?.selectedYear)
  );

  const [noResults, setNoResults] = React.useState(false);

  const [page, setPage] = React.useState(0);
  const [count, setCount] = React.useState(0);

  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [yieldRegionQuery, setYieldRegionQuery] = React.useState(
    new YieldRegionQuery(
      rpp,
      cp,
      filters?.orderBy || "name",
      (filters?.order as Order) || "asc",
      initialVue,
      filters?.selectedRegion,
      filters?.selectedCulture || "BTH",
      filters?.selectedClient,
      filters?.selectedYear
    )
  );

  const sortRows = (a: any, b: any) => {
    const direction = order || "asc";
    const property = orderBy || "name";
    if (property === "name" || property === "departement") {
      const a_name =
        property === "departement"
          ? a.region_id?.name
          : a.parent_region_id?.name || a.name;
      const b_name =
        property === "departement"
          ? b.region_id?.name
          : b.parent_region_id?.name || b.name;

      return direction !== "asc"
        ? a_name.localeCompare(b_name)
        : b_name.localeCompare(a_name);
    } else if (property === "surface") {
      const a_name = a.surface;
      const b_name = b.surface;
      return direction !== "asc" ? a_name - b_name : b_name - a_name;
    } else if (property === "yield_av_surface") {
      const a_name = (a.yield_av * a.region_id!.surface) / 100;
      const b_name = (b.yield_av * b.region_id!.surface) / 100;
      return direction !== "asc" ? a_name - b_name : b_name - a_name;
    } else {
      const a_name = parseFloat(a[property]);
      const b_name = parseFloat(b[property]);

      return direction === "asc" ? a_name - b_name : b_name - a_name;
    }
  };

  const formatter = new Intl.NumberFormat("fr-FR", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 3,
    maximumFractionDigits: 3,
  });

  const createSortHandler = (property: string) => {
    const newOrder = order === "asc" ? "desc" : "asc";
    setOrderBy(property);
    setOrder(newOrder);
    setUpdateOrder(new Date().getTime());
  };

  useEffect(() => {
    setRows([...rows].sort(sortRows));
  }, [updateOrder]);

  const calculateDistribution = (items: any) => {
    const yields = items.map((i: any) =>
      i[defaultField] !== undefined ? i[defaultField] : 0
    );
    const stepCount = 8;
    const max = Math.max(...yields);
    const min = Math.max(Math.min(...yields), 0); // Asegura que el mínimo no sea menor que 0
    const stepSize = (max - min) / (stepCount - 1); // Ajusta el paso para incluir un rango para el 0
    const vals = [];

    // Agrega el rango para el 0 por separado
    const zeroCount = yields.filter((y: number) => y === 0).length;
    if (zeroCount > 0) {
      vals.push({ yield: 0, count: zeroCount });
    }

    for (let i = 1; i < stepCount; i++) {
      const lowerBound = i === 1 ? min : min + (i - 1) * stepSize;
      const upperBound = i === stepCount - 1 ? max : min + i * stepSize;
      const count = yields.filter(
        (y: number) => y >= lowerBound && y < upperBound
      ).length;

      vals.push({
        yield: formatter.format(upperBound)?.replace("€", "k€") as any,
        count,
      });
    }

    setDistribution(vals);
    setDistributionMode(true);
  };

  const calc_losses = (row: any) => {
    if (row.deductible < 100) {
      const yield_insured = row.yield_ref * (1 - row.deductible * 0.01);
      let range = Math.ceil(yield_insured);
      let losses = row.losses;

      for (let i = 0; i < range; i++) {
        losses += row.yield_distribution[i] * (yield_insured - i);
      }

      losses *= row.pricing * row.market_shares * 0.00001;

      return losses;
    } else {
      return 0;
    }
  };

  const formatToRounded = (valor: any): number => {
    if (
      valor === undefined ||
      valor === null ||
      valor === "" ||
      isNumber(valor)
    )
      return valor;
    // Elimina el sufijo 'k€'
    let valorSinSufijo = valor.replace("k€", "").trim();

    // Reemplaza las comas por puntos si es necesario
    valorSinSufijo = valorSinSufijo.replace(",", ".");

    // Convierte a número y multiplica por 1000 (porque 'k' significa mil)
    const valorNumerico = parseFloat(valorSinSufijo) * 1000;

    // Redondea a un número entero
    return Math.round(valorNumerico);
  };

  const loadResults = async () => {
    setNoResults(false);

    if (!isLoading) {
      try {
        setLoading(true);
        isLoading = true;
        const query =
          yieldRegionQuery.region === ""
            ? { ...yieldRegionQuery, region: undefined }
            : { ...yieldRegionQuery };

        if (
          query.region === undefined &&
          query.scale === "E4" &&
          authService.user &&
          (authService.user.restriction === undefined || authService.user.restriction.length <= 0)
        ) {
          modalService.info({
            text: t("Select a region to access this View"),
          });
        } else {
          const result = await yieldRegionService.getYieldRegionByLevel(query);
          if (result.items.length === 0) {
            setNoResults(true);
          }
          if (result.items.length > 20) {
            calculateDistribution(result.items);
          } else {
            setDistributionMode(false);
          }

          const newRows = result.items
            .sort(sortRows);

          setCount(newRows.length);

          if (selectedVue === "E1") {
            setHeadCells([...headCellsE1, ...headCellsBase]);
            setRows(newRows);
          } else {
            setHeadCells([
              ...headCellsE1,
              ...headCellsE2(t(selectedVue as string)),
              ...headCellsBase,
            ]);
            setRows(newRows);
          }

          if (selectedRegions.length <= 0) {
            setSelectedRegions(newRows);
          }
        }
      } catch (ex) {
      } finally {
        isLoading = false;
        setLoading(false);
      }
    }
  };

  const handleChangeView = (view: any) => {
    //console.log("VIEW", view);
    setPage(1);
    setYieldRegionQuery({
      ...yieldRegionQuery,
      skip: 0,
      scale: view,
      region: view === "E1" ? undefined : (selectedRegion as any),
    });

    setSelectedVue(view);

    if (view === "E1") {
      setSelectedRegion(undefined);
    }
  };

  const handleChangeLosses = async (
    event: any,
    row: any,
    index: number,
    type: string
  ) => {
    row[type] = event.target.value !== "" ? parseFloat(event.target.value) : 0;

    row.deductible = row.deductible > 100 ? 100 : row.deductible;
    row.market_shares = row.market_shares > 100 ? 100 : row.market_shares;

    if (type === "insured_surface") {
      row.market_shares =
        row.surface > 0
          ? parseFloat(((row.insured_surface / row.surface) * 100).toFixed(6))
          : 0;
    }

    if (type === "market_shares" || type === "deductible") {
      row.insured_surface =
        row.surface > 0
          ? parseFloat(((row.market_shares * row.surface) / 100).toFixed(6))
          : 0;
    }

    row.insured_capital = parseFloat(
      (row.pricing * row.insured_surface * row.yield_ref).toFixed(6)
    );

    row.losses = calc_losses(row);

    setRowsToSave([
      ...rowsToSave,
      {
        _id: row._id,
        insured_surface: row.insured_surface,
        insured_capital: row.insured_capital,
        market_shares: row.market_shares,
        losses: row.losses,
        deductible: row.deductible,
        pricing: row.pricing,
        yield_ref: row.yield_ref,
      },
    ]);

    setRows([...rows.sort(sortRows)]);
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      const res = await yieldRegionService.save(rowsToSave);
    } catch (err) {
      modalService.error({ text: "ERROR: " + JSON.stringify(err) });
    } finally {
      setLoading(false);
    }
  };


  const handleChangeCulture = (culture: any, crop: any) => {
    //console.log("CULTURE", crop);
    setPage(1);
    setYieldRegionQuery({ ...yieldRegionQuery, skip: 0, crop: culture });
    setSelectedCulture(culture);
    setSelectedCrop(crop);
    // debugger;
  };

  const handleChangeClient = (client: any) => {
    //console.log("CULTURE", crop);
    if (client !== "" && client !== undefined && client !== null) {
      setPage(1);
      setYieldRegionQuery({ ...yieldRegionQuery, skip: 0, client });
      setSelectedClient(client);
    }
  };

  const handleChangeRegion = (region: any) => {
    //console.log("REGION", region);
    setPage(1);

    setSelectedRegion(region);

    if (region && selectedVue === "E1") {
      setSelectedVue(YieldScale.E2);
    } else if (region === "") {
      setSelectedVue(YieldScale.E1);
    } else {
      setYieldRegionQuery({
        ...yieldRegionQuery,
        skip: 0,
        region,
        scale: region && selectedVue === "E1" ? "E2" : (selectedVue as any),
      });
    }

    // debugger;
  };

  const handleChangeYear = (year: number) => {
    if (year !== undefined && year !== null && year !== 0) {
      setPage(1);
      setYieldRegionQuery({ ...yieldRegionQuery, skip: 0, year });
      setSelectedYear(year);
    }
  };

  useEffect(() => {
    yieldRegionService.filters = {
      ...yieldRegionService.filters,
      selectedVue: selectedVue,
    } as any;
  }, [selectedVue]);

  useEffect(() => {
    yieldRegionService.filters = {
      ...yieldRegionService.filters,
      selectedRegion: selectedRegion,
    } as any;
  }, [selectedRegion]);

  useEffect(() => {
    yieldRegionService.filters = {
      ...yieldRegionService.filters,
      selectedCulture: selectedCrop,
    } as any;
  }, [selectedCrop]);

  useEffect(() => {
    yieldRegionService.filters = {
      ...yieldRegionService.filters,
      selectedYear: selectedYear,
    } as any;
  }, [selectedYear]);

  useEffect(() => {
    if (
      selectedClient !== "" &&
      selectedClient !== undefined &&
      selectedClient !== null
    ) {
      yieldRegionService.filters = {
        ...yieldRegionService.filters,
        selectedClient: selectedClient,
      } as any;
    }
  }, [selectedClient]);

  useEffect(() => {
    if (selectedClient && selectedCrop && selectedVue) {
      loadResults();
    }
  }, [yieldRegionQuery]);

  useEffect(() => {
    if (rows && rows.length > 20) {
      calculateDistribution(rows);
    } else {
      setDistributionMode(false);
    }
  }, [defaultField]);

  const formatNumberToCSV = (num: any, decimals = 3) => {
    const formatterNum = new Intl.NumberFormat("fr-FR", {
      style: "decimal",
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    });

    return formatterNum.format(num)?.replace(/[^0-9.,]/g, "");
  };

  const exportToCSV = () => {
    if (
      authService.user?.exportCSV === true ||
      authService.user?.role.includes("admin")
    ) {
      const csv = unparse(
        {
          fields: ["_id", ...headCells.map((h) => t(h.label))],
          data: rows.map((row) => {
            const newItem = [];
            newItem.push(row._id);

            if (selectedVue === "E1") {
              newItem.push(row.name || row.region_id?.name);
            } else {
              newItem.push(row.parent_region_id!.name);
              newItem.push(row.name || row.region_id?.name);
            }

            newItem.push(row.market_shares);
            newItem.push(formatNumberToCSV(row?.insured_surface, 6));
            newItem.push(row.deductible);
            newItem.push(row.pricing);
            newItem.push(row.yield_ref);
            newItem.push(formatNumberToCSV(row.insured_capital));
            newItem.push(formatNumberToCSV(row.losses));

            return newItem;
          }),
        },
        { delimiter: ";" }
      );
      //console.log(csv);
      const universalBOM = "\uFEFF";

      const csvData = new Blob([universalBOM + csv], {
        type: "text/csv; charset=utf-8;",
      });
      const csvURL = window.URL.createObjectURL(csvData);

      const tempLink = document.createElement("a");
      tempLink.href = csvURL;
      tempLink.setAttribute("download", "download.csv");
      tempLink.click();
    } else {
      setNoExportAllowed(true);
    }
  };

  const loadSimulation = async (ids: any[]) => {
    if (!isLoading) {
      try {
        setLoading(true);
        isLoading = true;

        const result = await yieldRegionService.getYieldRegionByIds(ids);

        const newRows = result.items.sort(sortRows);

        return newRows;
      } catch (ex) {
      } finally {
        isLoading = false;
        setLoading(false);
        fileObjectRef.current.form.reset();
      }
    }
  };

  const cancelImport = () => {
    setImportVisible(false);
    setImportActive(false);
    setRowsToSave([]);
    loadResults();
  };

  const parseFloatNum = (n: any) => {
    return Number(n.replace(",", "."));
  };

  const handleFileSelected = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target && e.target.files) {
      const files = Array.from(e.target.files);

      parse(files[0] as any, {
        delimiter: ";",
        complete: async (result) => {
          const data = result.data as any[];
          const headers = data.shift();
          const ids = data.map((r) => r[0]);
          const hasRegion = headers.length >= headCellsBase.length + 3;
          const newResults = await loadSimulation(ids);
          const INDEX_market_shares = hasRegion ? 3 : 2;
          const INDEX_insured_surface = hasRegion ? 4 : 3;
          const INDEX_deductible = hasRegion ? 5 : 4;
          const INDEX_pricing = hasRegion ? 6 : 5;
          const INDEX_yield_ref = hasRegion ? 7 : 6;

          const newRows = newResults?.map((row) => {
            const newRow = { ...row };
            const csvRow = data.find((item) => item[0] === newRow._id);
            let market_shares =
              parseFloatNum(csvRow[INDEX_market_shares]) ||
              row.market_shares ||
              0;
            let insured_surface =
              parseFloatNum(csvRow[INDEX_insured_surface]) ||
              row.insured_surface ||
              0;

            newRow.deductible =
              parseFloatNum(csvRow[INDEX_deductible]) || row.deductible;
            newRow.yield_ref =
              parseFloatNum(csvRow[INDEX_yield_ref]) || row.yield_ref;
            newRow.pricing =
              parseFloatNum(csvRow[INDEX_pricing]) || row.pricing;

            newRow.market_shares = market_shares;
            newRow.insured_surface = insured_surface;

            if (csvRow[INDEX_insured_surface] !== "") {
              newRow.market_shares = Math.floor(
                (insured_surface / row.surface) * 100
              );
            }

            if (csvRow[INDEX_market_shares] !== "") {
              newRow.insured_surface = parseFloat(
                ((newRow.market_shares * row.surface) / 100).toFixed(6)
              );
            }

            newRow.insured_capital = parseFloat(
              (
                newRow.pricing *
                newRow.insured_surface *
                newRow.yield_ref
              ).toFixed(6)
            );

            newRow.losses = calc_losses(newRow);

            return newRow;
          }) as any[];

          isLoading = true;

          if (!hasRegion) {
            setSelectedVue("E1");
            setHeadCells([...headCellsE1, ...headCellsBase]);
          } else {
            let vue = "E2";
            if (t("E3") === headers[2]) {
              vue = "E3";
            }

            if (t("E4") === headers[2]) {
              vue = "E4";
            }
            setSelectedVue(vue);
            setHeadCells([
              ...headCellsE1,
              ...headCellsE2(t(vue as string)),
              ...headCellsBase,
            ]);
          }

          setRows(newRows.sort(sortRows));
          setRowsToSave(newRows);
          setImportActive(true);
          setTimeout(() => (isLoading = false), 400);
        },
      });
      console.log("files:", files);
      setImportVisible(false);
    }
  };

  return (
    <>
      <Filters
        disabled={loading}
        crop={selectedCulture}
        vue={selectedVue}
        region={selectedRegion}
        regions={selectedRegions}
        client={selectedClient}
        year={selectedYear}
        onChangeView={handleChangeView}
        onChangeCulture={handleChangeCulture}
        onChangeRegion={handleChangeRegion}
        onChangeClient={handleChangeClient}
        onChangeYear={handleChangeYear}
        command={
          <>
            {importActive ? (
              <Button
                color="secondary"
                variant="contained"
                size="small"
                onClick={() => cancelImport()}
              >
                {t("Cancel import")}
              </Button>
            ) : (
              <Button
                size="small"
                color="primary"
                variant="contained"
                onClick={() => setImportVisible(true)}
              >
                {t("Import CSV")}
              </Button>
            )}

            <Button
              size="small"
              color="primary"
              variant="contained"
              style={{ marginLeft: 10 }}
              onClick={() => history.push("/yieldregions")}
            >
              {t("Dashboard")}
            </Button>
            {rowsToSave.length > 0 && (
              <Button
                size="small"
                color="secondary"
                variant="contained"
                onClick={() => handleSave()}
                style={{ marginLeft: 10 }}
              >
                {t("Save")}
              </Button>
            )}
          </>
        }
      />
      <div
        className="table-yieldRegions-container"
        style={{ opacity: loading ? 0.4 : 1 }}
      >
        <Card className="regions-viewport">
          {loading && (
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          )}

          {noResults && (
            <div className="no-results">
              <img src="/img/not_found.png" alt="No results" />
              <h2>{t("NO RESULTS FOUND")}</h2>
            </div>
          )}

          {!noResults && (
            <Grid container spacing={4}>
              <Grid xs={12} md={6} item>
                {distributionMode ? (
                  <ChartDistribution
                    stats={distribution}
                    tickXFormatter={formatToRounded}
                    formatter={(value: any) => {
                      return formatter.format(value)?.replace("€", "k€");
                    }}
                  />
                ) : (
                  <Chart
                    fieldName={defaultField}
                    formatter={(value: any) => {
                      return formatter.format(value)?.replace("€", "k€");
                    }}
                    hideLegend={true}
                    regions={rows}
                  />
                )}
                <div className="legendOptions">
                  <RadioGroup
                    row
                    name="position"
                    defaultValue={defaultField}
                    onChange={(ev) => setDefaultField(ev.target.value)}
                  >
                    <FormControlLabel
                      value="insured_capital"
                      control={<Radio color="primary" />}
                      label={t("insured_capital")}
                    />
                    <FormControlLabel
                      value="losses"
                      control={<Radio color="primary" />}
                      label={t("losses")}
                    />
                  </RadioGroup>
                </div>
              </Grid>
              <Grid xs={12} md={6} item>
                <Map
                  regions={rows}
                  scaleFactor={scaleFactor}
                  crop={selectedCrop}
                  title={t(defaultField)}
                  fieldName={defaultField}
                  minMaxFactor="minmax"
                  gradientColors={["#ffea00", "#e97303", "#d30105"]}
                />
              </Grid>
            </Grid>
          )}
        </Card>
        <br />

        {rows && (
          <TableContainer component={Paper}>
            {loading && (
              <Box sx={{ width: "100%" }}>
                <LinearProgress />
              </Box>
            )}
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <TableRow>
                  {headCells.map((headCell) => (
                    <TableCell
                      key={headCell.id}
                      align={headCell.align ? "left" : "center"}
                      sortDirection={orderBy === headCell.id ? order : false}
                    >
                      <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : "asc"}
                        onClick={(evt) => createSortHandler(headCell.id)}
                      >
                        {t(headCell.label)}
                        {orderBy === headCell.id ? (
                          <span className={classes.visuallyHidden}>
                            {order === "desc"
                              ? "sorted descending"
                              : "sorted ascending"}
                          </span>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <TableRow key={row._id}>
                    {selectedVue === "E1" ? (
                      <TableCell component="th" scope="row">
                        {row.name || row.region_id?.name}
                      </TableCell>
                    ) : (
                      <>
                        <TableCell component="th" scope="row">
                          {row.parent_region_id?.name
                            ? row.parent_region_id!.name
                            : row.name}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.name || row.region_id?.name}
                        </TableCell>
                      </>
                    )}

                    <TableCell component="th" scope="row">
                      <Input
                        value={row.market_shares}
                        type="number"
                        inputProps={{
                          className: "rightAlign",
                          pattern: "[0-9]*",
                        }}
                        onChange={(evt: any) =>
                          handleChangeLosses(evt, row, index, "market_shares")
                        }
                        endAdornment={
                          <InputAdornment position="end">%</InputAdornment>
                        }
                      />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Input
                        value={row?.insured_surface?.toFixed(3)}
                        type="number"
                        inputProps={{
                          className: "rightAlign",
                          pattern: "[0-9]*",
                        }}
                        onChange={(evt: any) =>
                          handleChangeLosses(evt, row, index, "insured_surface")
                        }
                      />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Input
                        value={row.deductible}
                        type="number"
                        inputProps={{
                          className: "rightAlign",
                          pattern: "[0-9]*",
                        }}
                        onChange={(evt: any) =>
                          handleChangeLosses(evt, row, index, "deductible")
                        }
                        endAdornment={
                          <InputAdornment position="end">%</InputAdornment>
                        }
                      />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Input
                        value={row.pricing}
                        type="number"
                        inputProps={{
                          className: "rightAlign",
                          pattern: "[0-9]*",
                        }}
                        onChange={(evt: any) =>
                          handleChangeLosses(evt, row, index, "pricing")
                        }
                        endAdornment={
                          <InputAdornment position="end">€/qt</InputAdornment>
                        }
                      />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Input
                        value={row.yield_ref}
                        type="number"
                        inputProps={{
                          className: "rightAlign",
                          pattern: "[0-9]*",
                        }}
                        onChange={(evt: any) =>
                          handleChangeLosses(evt, row, index, "yield_ref")
                        }
                        endAdornment={
                          <InputAdornment position="end">qt/ha</InputAdornment>
                        }
                      />
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: "#eee",
                        fontWeight: "bold",
                        textAlign: "right",
                        padding: 30,
                      }}
                      component="th"
                      scope="row"
                    >
                      {formatter
                        .format(row.insured_capital)
                        ?.replace("€", "k€")}
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: "#eee",
                        fontWeight: "bold",
                        textAlign: "right",
                        padding: 30,
                      }}
                      component="th"
                      scope="row"
                    >
                      {formatter.format(row.losses)?.replace("€", "k€")}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
      <Dialog
        open={importVisible}
        //TransitionComponent={Transition}
        keepMounted
        disableEscapeKeyDown={true}
        fullWidth={true}
        onClose={() => setNoExportAllowed(false)}
      >
        <DialogTitle>
          <Trans>Import CSV</Trans>
        </DialogTitle>
        <DialogContent>
          <Trans>Instructions</Trans>
          <form>
            <input
              style={{ display: "none" }}
              ref={fileObjectRef}
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              onChange={handleFileSelected}
              type="file"
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => exportToCSV()} color="primary">
            <Trans>Download CSV</Trans>
          </Button>
          <Button
            onClick={() => {
              fileObjectRef.current.click();
            }}
            color="secondary"
          >
            <Trans>Import updated CSV</Trans>
          </Button>
          <Button onClick={() => setImportVisible(false)}>
            <Trans>Cancel</Trans>
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={noExportAllowed}
        //TransitionComponent={Transition}
        keepMounted
        disableEscapeKeyDown={true}
        fullWidth={true}
        onClose={() => setNoExportAllowed(false)}
      >
        <DialogTitle>
          <Trans>No allowed</Trans>
        </DialogTitle>
        <DialogContent>
          <Trans>You dont have permissions for this action</Trans>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setNoExportAllowed(false)} color="primary">
            <Trans>Ok</Trans>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Simulation;
