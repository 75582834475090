import React from "react";
import { AlertDialog } from "../Dialogs/AlertDialog";
import { onError } from "../../services/BaseService";
import { UserQuota } from "../../../../shared/models/user";

class ErrorMessage {
  title: string = "";
  description: string = "";
  email = false;
}

class ErrorState {
  error?: ErrorMessage | any;
}

const errors: any = {
  EXPIRES: {
    title: "Subscription date expired",
    description: "Your subscription has expired, Contact us to renew it",
    email: true,
  },
  LIMITS: {
    title: "NDVI Surface limit expired",
    description:
      "You have reached the authorized surface limit, Contact us to upgrade",
    email: true,
  },
  LIMITS_YIELD: {
    title: "YIELD Surface limit expired",
    description:
      "You have reached the authorized surface limit, Contact us to upgrade",
    email: true,
  },
  DEFAULT: {
    title: "Error",
    description: "Unknow error",
    email: true,
  },
};

export class ErrorBoundary extends React.Component<any, ErrorState> {
  constructor(props: Readonly<{}>) {
    super(props);
    this.state = { error: null };
  }

  componentDidMount() {
    onError.subscribe((error: any) => {
      const key = error.message;
      const errDef = errors[key]
        ? errors[key]
        : ({
            ...errors["DEFAULT"],
            description: error.error,
          } as ErrorMessage);
      this.setState({ error: errDef });
    });
  }

  componentDidCatch(error: any, info: any) {
    debugger;
    this.setState({ error });
  }

  render() {
    return (
      <>
        {this.state.error ? (
          <AlertDialog
            open={this.state.error != null}
            title={this.state.error.title}
            description={this.state.error.description}
            email={this.state.error.email}
            onClose={() => this.setState({ error: null })}
          ></AlertDialog>
        ) : null}
        {this.props.children}
      </>
    );
  }
}
