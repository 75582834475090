import React, { useEffect } from "react";
import EventListener from "react-event-listener";
import {
  Select,
  MenuItem,
  ListItemText,
  FormGroup,
  FormControlLabel,
  Switch,
  ButtonGroup,
  Grid,
  IconButton,
  createStyles,
  makeStyles,
  Card,
  CardContent,
  CardActions,
  Fab,
  Fade,
  Box,
  FormControl,
  Menu,
  TextField,
  InputAdornment,
  Icon,
  Typography,
  Button,
  Toolbar,
  AppBar,
  ListItemIcon,
  CircularProgress,
  Tooltip as TT,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  Map,
  Marker,
  Polygon,
  TileLayer,
  FeatureGroup,
  ImageOverlay,
  Tooltip as TooltipMap,
} from "react-leaflet";
import leaflet, { LatLng } from "leaflet";
import {
  ComposedChart,
  Text,
  Tooltip,
  CartesianGrid,
  XAxis,
  YAxis,
  Line,
  Area,
} from "recharts";
import EditControl from "./EditControl";
import { Comments } from "../Comments/Comments";
import { Comment } from "../Comments/Comment.model";
import { distance, point } from "@turf/turf";
import { Trans, useTranslation } from "react-i18next";
import * as moment from "moment";
import "moment-timezone";
import Moment from "react-moment";
import { getImageBucket } from "../../config";

import "../App/App.css";
import "./MyPosition.css";
import { AddComment } from "../Comments/AddComment";
import { userService } from "../../services/UserService";
import { utilService } from "../../services/UtilService";
import { EditParcelle } from "../Parcelle/EditParcelle";

import isCordova from "../Util/isCordova";
import { MapOutlined } from "@material-ui/icons";
import { modalService } from "../../services/ModalService";

//leaflet.Browser.touch = true;

const useStyles = makeStyles((theme) =>
  createStyles({
    menuButton: {
      marginRight: theme.spacing(2),
    },
    buttons: {
      "& > *": {
        margin: theme.spacing(1),
      },
    },
    title: {
      flexGrow: 1,
    },
  })
);

const ZOOM_NEAR = 22;
const ZOOM_FAR = 16;
const OVER_OPACITY = 0.7;
const OUT_OPACITY = 0.4;
const DISABLED_OPACITY = 0;
let lastPosition = null;
let lockInterface = false;
const tempColor = "#0060ff";
const tempAvgColor = "#00a2ff";
const rainColor = "blue";
const CHART_MIN = 400;
const CHART_MAX = 450;
const CHART_H_MIN = 200;
const CHART_H_MAX = 250;

const ndviLegend = [
  {
    key: "v_005",
    min: 0.05,
    max: 0.29,
    label: "0.05 - 0.3",
    color: "#8C0000",
    textColor: "#fff",
  },
  {
    key: "v_03",
    min: 0.3,
    max: 0.39,
    label: "0.3 - 0.4",
    color: "#FF0000",
    textColor: "#fff",
  },
  {
    key: "v_04",
    min: 0.4,
    max: 0.49,
    label: "0.4 - 0.5",
    color: "#FF7D00",
    textColor: "#000",
  },
  {
    key: "v_05",
    min: 0.5,
    max: 0.59,
    label: "0.5 - 0.6",
    color: "#FFFF00",
    textColor: "#000",
  },
  {
    key: "v_06",
    min: 0.6,
    max: 0.69,
    label: "0.6 - 0.7",
    color: "#7DFF00",
    textColor: "#000",
  },
  {
    key: "v_07",
    min: 0.7,
    max: 0.79,
    label: "0.7 - 0.8",
    color: "#00FF00",
    textColor: "#000",
  },
  {
    key: "v_08",
    min: 0.8,
    max: 0.89,
    label: "0.8 - 0.9",
    color: "#00B400",
    textColor: "#fff",
  },
  {
    key: "v_09",
    min: 0.9,
    max: 0.99,
    label: "0.9 - 1",
    color: "#005000",
    textColor: "#fff",
  },
];

const redementLegend = [
  {
    key: "v_20",
    min: 20,
    max: 20,
    label: "< 20 q/ha",
    color: "#8C0000",
    textColor: "#fff",
  },
  {
    key: "v_03",
    min: 20,
    max: 30,
    label: "20 - 30 q/ha",
    color: "#FF0000",
    textColor: "#fff",
  },
  {
    key: "v_04",
    min: 0.4,
    max: 0.49,
    label: "30 - 40 q/ha",
    color: "#FF7D00",
    textColor: "#000",
  },
  {
    key: "v_05",
    min: 0.5,
    max: 0.59,
    label: "40 - 50 q/ha",
    color: "#FFFF00",
    textColor: "#000",
  },
  {
    key: "v_06",
    min: 0.6,
    max: 0.69,
    label: "50 - 60 q/ha",
    color: "#7DFF00",
    textColor: "#000",
  },
  {
    key: "v_07",
    min: 0.7,
    max: 0.79,
    label: "60 - 70 q/ha",
    color: "#00FF00",
    textColor: "#000",
  },
  {
    key: "v_08",
    min: 0.8,
    max: 0.89,
    label: "70 - 80 q/ha",
    color: "#00B400",
    textColor: "#fff",
  },
  {
    key: "v_09",
    min: 0.9,
    max: 0.99,
    label: ">= 80 q/ha",
    color: "#005000",
    textColor: "#fff",
  },
];

const providers = [
  {
    label: "Terrain",
    map: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
  },
  {
    label: "OpenStreetMap",
    map: "https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png",
  },
];

function MyPosition({ match }) {
  const dotted_01 = isCordova ? "./img/dotted_01.png" : "/img/dotted_01.png";
  const dotted_02 = isCordova ? "./img/dotted_02.png" : "/img/dotted_02.png";
  const hail_img = isCordova ? "./img/hail.png" : "/img/hail.png";

  const esRiTile =
    "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}";

  const myIcon = new leaflet.Icon({
    iconUrl: isCordova
      ? "./img/ic_person_pin_circle.png"
      : "/img/ic_person_pin_circle.png",
    iconSize: [35, 50],
    iconAnchor: [15, 50],
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
  });

  const [provider, setProvider] = React.useState(providers[0]);

  const [url, setUrl] = React.useState(esRiTile);

  let mpos = new LatLng(48.066147, 1.0264236);

  const { t, i18n } = useTranslation();

  const [editablePoly, setEditablePoly] = React.useState(null);

  const [visible, setVisible] = React.useState(true);
  const [myPosition, setMyPosition] = React.useState(null);
  const [center, setCenter] = React.useState(mpos);
  const [zoom, setZoom] = React.useState(ZOOM_FAR);
  const [selectedPoly, setSelectedPoly] = React.useState(null);
  const [selectedObject, setSelectedObject] = React.useState(null);
  const [selectedParcelle, setSelectedParcelle] = React.useState(null);
  const [map, setMap] = React.useState(null);
  const [editControl, setEditControl] = React.useState(null);
  const [currentPoly, setCurrentPoly] = React.useState(null);
  const [showComments, setShowComments] = React.useState(false);
  const [loadComments, setLoadComments] = React.useState(false);
  const [currentComment, setCurrentComment] = React.useState(null);
  const [polygons, setPolygons] = React.useState([]);
  const [rainMode, setRainMode] = React.useState(true);
  const [tempMode, setTempMode] = React.useState(false);
  const [hailMode, setHailMode] = React.useState(true);

  const [chartVisible, setChartVisible] = React.useState(true);

  const [maxGraphWidth, setMaxGraphWidth] = React.useState(CHART_MAX);
  const [maxGraphHeight, setMaxGraphHeight] = React.useState(CHART_H_MAX);

  const [interactiveMode, setInteractiveMode] = React.useState(true);

  const [yieldStatus, setYieldStatus] = React.useState(null);

  const [currentNewPoly, setCurrentNewPoly] = React.useState(null);
  const [editControlPoly, setEditControlPoly] = React.useState(null);

  const [histo, setHisto] = React.useState(null);
  const [comments, setComments] = React.useState(null);
  const [histoIndex, setHistoIndex] = React.useState(0);
  const [collapsedInfo, setCollapsedInfo] = React.useState(false);

  const [selectedHisto, setSelectedHisto] = React.useState(null);
  const [currentImage, setCurrentImage] = React.useState(null);

  const [histoNotFound, setHistoNotFund] = React.useState(false);
  const [showEditParcelle, setShowEditParcelle] = React.useState(false);
  const [findResults, setFindResults] = React.useState([]);

  const [minMaxVals, setMinMaxVals] = React.useState(null);

  const [ndviMode, setNdviMode] = React.useState(true);

  const [reportHistos, setReportHistos] = React.useState([]);
  const [loadingReport, setLoadingReport] = React.useState(false);

  const classes = useStyles();
  let isSearching = false;
  let searchInterval = null;

  const handleChange = (event) => {
    setUrl(event.target.value);
  };

  const getLegend = (forceNdvi) => {
    const currentNdvi = forceNdvi !== undefined ? forceNdvi : ndviMode;
    return currentNdvi ? [...ndviLegend] : [...redementLegend];
  };

  const drawEnd = (event) => {
    if (currentComment) {
      drawEndHandler(event);
    } else if (currentNewPoly) {
      drawEndPolyHandler(event);
    }
  };

  const drawEndHandler = (event) => {
    //event.layer.getLatLngs();

    if (currentPoly) {
      handleEndPoly(event);
    } else if (currentComment) {
      setInteractiveMode(false);
      const shape = {
        type: "Polygon",
        coordinates: event.layer.getLatLngs()[0],
      };
      const surface = shape ? utilService.getSurfaceFromShape(shape) : 0;
      setCurrentComment({
        ...currentComment,
        surface,
        shape,
        layer: event.layer,
      });
      setTimeout(() => {
        setCurrentComment((c) => ({ ...c, create: true }));
      }, 2300);

      //handleCancelComment(event);
    }
  };

  const drawEndPolyHandler = (event) => {
    setInteractiveMode(false);
    const shape = {
      type: "Polygon",
      coordinates: utilService.convertLatLngToArray(
        event.layer.getLatLngs()[0]
      ),
    };
    const surface = shape ? utilService.getSurfaceFromShape(shape) : 0;
    setCurrentNewPoly({
      ...currentNewPoly,
      shape,
      layer: event.layer,
      surface,
    });
  };

  let searchingParcelles = false;
  const findParcelles = (lat, lng, cb) => {
    if (!searchingParcelles) {
      searchingParcelles = true;
      userService.getParcelles(lat, lng).then(
        (parcelles) => {
          const oldPolys = [...polygons];
          if (oldPolys.length > 400) {
            oldPolys.splice(0, 200);
          }

          if (selectedParcelle) {
            oldPolys.push(selectedParcelle);
          }

          let newPolys = shortPolys([
            ...oldPolys,
            ...parcelles.filter(
              (p) => !polygons.some((elm) => elm.name === p.name)
            ),
          ]);

          newPolys = newPolys.reduce((acc, current) => {
            const x = acc.find((item) => item.name === current.name);
            if (!x) {
              return acc.concat([current]);
            } else {
              return acc;
            }
          }, []);

          setPolygons(newPolys);
          if (cb) cb(newPolys);
          searchingParcelles = false;
        },
        (error) => {
          searchingParcelles = false;
        }
      );
    }
  };

  const loadExplotation = async (id) => {
    const explotation = await userService.getExplotation(id);
    if (explotation && explotation.location) {
      const newCenter = new LatLng(
        explotation.location.coordinates[1],
        explotation.location.coordinates[0]
      );
      setCenter(newCenter);
      //findParcelles(explotation.location.coordinates[1], explotation.location.coordinates[0]);
    }
  };

  const loadParcelle = async (id) => {
    const parcelle = await userService.getParcelleByName(id);

    if (parcelle && parcelle.location) {
      const newCenter = new LatLng(
        parcelle.location.coordinates[1],
        parcelle.location.coordinates[0]
      );

      findParcelles(newCenter.lat, newCenter.lng, (parcelles) => {
        const element = parcelles.find((par) => par.name == id);
        if (element && element.ref)
          handleSelectParcelle(element, {
            target: element.ref.leafletElement,
          });
      });
    }
  };

  const handlePosition = (evt) => {
    if (lockInterface) return;
    const coords = map.leafletElement.getCenter();
    lastPosition = coords;
  };

  const handleMove = (evt) => {
    if (lockInterface || lastPosition == null) return;
    const coords = map.leafletElement.getCenter();
    var from = point([coords.lng, coords.lat]);
    var to = point([lastPosition.lng, lastPosition.lat]);
    const dist = distance(from, to);
    console.log("DISTANCE", dist);
    if (dist > 0.1) {
      findParcelles(coords.lat, coords.lng);
    }
  };

  const findLocation = async (event, val) => {
    console.log(isSearching);
    if (val.length > 3 && !isSearching) {
      isSearching = true;
      try {
        const results = await userService.search(val);
        setFindResults(results);
      } catch (ex) {}
      if (searchInterval) clearTimeout(searchInterval);
      searchInterval = setTimeout(() => (isSearching = false), 1300);
    } else if (val.length > 3) {
      if (searchInterval) clearTimeout(searchInterval);
      searchInterval = setTimeout(() => findLocation(event, val), 1300);
    } else {
      setFindResults([]);
    }
  };

  const selectLocation = async (opt, val) => {
    resetAllSelections(true);

    const place = await userService.place(val.place_id);
    const coords = new LatLng(
      place.geometry.location.lat,
      place.geometry.location.lng
    );
    goToPosition(coords);
  };

  const goToPosition = (coords, showPin) => {
    if (coords.lat == center.lat && coords.lng == center.lng) return;
    lastPosition = center;
    setTimeout(() => {
      setCenter(coords);
      if (showPin) {
        setMyPosition(coords);
      }
      setZoom(ZOOM_FAR);
      findParcelles(coords.lat, coords.lng);
    }, 300);
  };

  const findMyPosition = () => {
    console.log(
      "geolocation",
      "geolocation" in navigator,
      navigator.geolocation
    );

    if ("geolocation" in navigator) {
      /* geolocation is available */
      navigator.geolocation.getCurrentPosition(
        (position) => {
          console.log("position", position);

          const coords = new LatLng(
            position.coords.latitude,
            position.coords.longitude
          );

          mpos = coords;
          goToPosition(coords, true);
        },
        (err) => {
          console.log("err", JSON.stringify(err));

          goToPosition(mpos);
        },
        { enableHighAccuracy: true }
      );
    } else {
      goToPosition(mpos);
      console.log("NONE", mpos);
    }
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);

  const [anchorElPoly, setAnchorElPoly] = React.useState(null);
  const openMenuPoly = Boolean(anchorElPoly);

  const isSimilarPoly = (poly1, poly2) => {
    const extractBaseName = (name) => name ? name.replace(/_.*/, '') : name;
  
    const name1 = extractBaseName(poly1?.options?.name || '');
    const name2 = extractBaseName(poly2?.options?.name || '');
  
    //console.log("isSimilarPoly", name1, name2, name1 === name2);
  
    // Comparar las raíces de los nombres
    return name1 === name2;
  };
  

  const overPoly = (poly) => {
    if (poly && poly.setStyle && selectedParcelle !== poly) {
      poly.setStyle({ fillOpacity: OVER_OPACITY });
    }

    if (selectedPoly && selectedPoly.setStyle) {
      selectedPoly.setStyle({ fillOpacity: 0 });
    }

  };

  const outPoly = (poly, customOpacity) => {
    if (poly && poly.setStyle && selectedParcelle !== poly) {
      const opacity = customOpacity !== undefined ? customOpacity : OUT_OPACITY;
      poly.setStyle({ fillOpacity: opacity });
    }

    if (selectedPoly && selectedPoly.setStyle) {
      selectedPoly.setStyle({ fillOpacity: 0 });
    }
  };

  const handleOver = (event) => {
    if (event.target !== selectedPoly && !isSimilarPoly(event.target, selectedPoly)) {
      overPoly(event.target);
    }
  };

  const handleOut = (event, customOpacity) => {
    if (event.target !== selectedPoly && !isSimilarPoly(event.target, selectedPoly)) {
      console.log("opacity", customOpacity);
      outPoly(event.target, customOpacity);
    }
  };

  const handleOpenCommentsMenu = (event) => {
    setAnchorEl(event.target);
  };

  const handleCloseCommentsMenu = (event) => {
    setAnchorEl(null);
  };

  const handleOpenPolyMenu = (event) => {
    setAnchorElPoly(event.target);
  };

  const handleClosePolyMenu = (event) => {
    setAnchorElPoly(null);
  };

  const resetAllSelections = (ignoreZoom) => {
    const leaflet = map && map.leafletElement ? map.leafletElement : null;
    if (leaflet) {
      if (selectedPoly) {
        outPoly(selectedPoly);
      }

      setSelectedObject(null);
      setSelectedPoly(null);
      setSelectedParcelle(null);
      setSelectedHisto(null);

      if (!ignoreZoom) {
        const leaflet = map.leafletElement;
        leaflet.setZoom(ZOOM_FAR, { animate: true });
      }
    }
  };

  const handleStartPoly = (event) => {
    resetAllSelections();

    const poly = editControl.createPolygon();
    poly.enable();
    setCurrentPoly(poly);
  };

  const handleClosePoly = async (event) => {
    modalService.showLoading();
    try {
      //TODO: Open modal with new metadata
      const shape = currentNewPoly.shape;
      shape.coordinates.push(shape.coordinates[0]);
      let newParcelle = await userService.editParcelleContour(
        selectedParcelle.name,
        shape
      );

      setNdviMode(true);
      setCurrentNewPoly(null);
      setInteractiveMode(true);

      setTimeout(async () => {
        lockInterface = false;
        newParcelle = await userService.getParcelleByName(newParcelle.name);
        updateParcelleSurface(newParcelle);
        setPolygons((p) => [...p, newParcelle]);
        loadHisto(newParcelle);
        handleSelectParcelle(newParcelle, {
          target: newParcelle.ref?.leafletElement,
        }, true);
        setShowEditParcelle(true);
        modalService.hideLoading();

      }, 2000);
    } catch (err) {
      modalService.hideLoading();
      modalService.error(err);
    }
  };

  const handleEndPoly = (event) => {
    if (currentPoly) {
      currentPoly.poly.disable();
      if (currentPoly.layer) {
        map.leafletElement.removeLayer(currentPoly.layer);
      }
      setCurrentPoly(null);
    }
  };

  const handleEndNewPoly = (event) => {
    if (currentNewPoly) {
      currentNewPoly.poly.disable();
      if (currentNewPoly.layer) {
        map.leafletElement.removeLayer(currentNewPoly.layer);
      }
      setCurrentNewPoly(null);
    }
  };

  const handleAddComment = (event) => {
    const poly = editControl.createComment();
    const comment = new Comment();
    poly.enable();

    comment.poly = poly;
    comment.parcelle = selectedParcelle;
    comment.histo = selectedHisto;
    setCurrentComment(comment);
    setAnchorEl(null);

    setInteractiveMode(false);
  };

  const handleEditPoly = (event) => {
    const poly = editControlPoly.createPolygon();
    poly.enable();

    setCurrentNewPoly({ poly });

    setAnchorElPoly(null);

    setInteractiveMode(false);
  };

  const handleAddSimpleComment = (event) => {
    const comment = new Comment();
    comment.create = true;
    comment.parcelle = selectedParcelle;
    comment.histo = selectedHisto;

    setCurrentComment(comment);
    setAnchorEl(null);
  };

  const handleEditComment = (comment) => {
    comment.create = true;
    setCurrentComment(comment);
    setAnchorEl(null);
    setInteractiveMode(false);
  };

  const handlePolyClick = (event, comment) => {
    if (!interactiveMode) return;
    comment.create = true;
    setCurrentComment(comment);
    setAnchorEl(null);
  };

  const handleCancelComment = (event) => {
    if (currentComment && currentComment.poly) {
      currentComment.poly.disable();
      if (currentComment.layer) {
        map.leafletElement.removeLayer(currentComment.layer);
      }
    }
    setCurrentComment(null);
    setInteractiveMode(true);
  };

  const handleSaveComment = (event) => {
    if (
      currentComment &&
      currentComment.poly &&
      currentComment.poly._poly._latlngs.length >= 3
    ) {
      currentComment.poly._finishShape();
      drawEndHandler(event);
      setInteractiveMode(true);
    }
  };

  const reloadComments = () => {
    setLoadComments(true);
  };

  const saveComment = (comment) => {
    if (comment.layer) {
      map.leafletElement.removeLayer(comment.layer);
    }

    comment.layer = null;
    comment.poly = null;
    comment.create = false;
    if (comment.shape) {
      comment.surface = utilService.getSurfaceFromShape(comment.shape);
    }
    comment.createdAt = new Date();

    const currentIndex = selectedHisto.comments
      ? selectedHisto.comments.indexOf(currentComment)
      : -1;
    let comments_tmp = selectedHisto.comments
      ? [...selectedHisto.comments]
      : [];

    const newComments =
      currentIndex == -1 ? [comment, ...(comments_tmp || [])] : comments_tmp;
    if (currentIndex > -1) {
      newComments[currentIndex] = comment;
    }

    const newSelectedHisto = {
      ...selectedHisto,
      comments: newComments,
    };

    const index = histo && histo.length ? histo.indexOf(selectedHisto) : -1;
    if (index > -1) {
      histo[index] = newSelectedHisto;
    }

    setCurrentComment(null);
    reloadComments();
    setSelectedHisto(newSelectedHisto);
    setShowComments(true);
    setInteractiveMode(true);
  };

  const editSelectedPoly = () => {
    selectedPoly.editing.enable();
  };

  const updateParcelleSurface = (parcelle) => {
    parcelle.surface = utilService.getSurfaceFromShape(parcelle.shape);
  };

  const handleSelectParcelle = (parcelle, event, force = false) => {

    if (!interactiveMode && !force) return;

    if (parcelle === selectedParcelle) {
      // Verificar si el nombre del polígono contiene un '_'
      const hasPrefix = parcelle.name.includes("_");
      const baseName = hasPrefix ? parcelle.name.split("_")[0] : parcelle.name;

      // Filtrar los polígonos que coinciden con la base del nombre, incluyendo el actual.
      const matchingPolygons = polygons.filter(
        (p) => p.name === baseName || p.name.startsWith(baseName + "_")
      );

      // Si solo hay uno, no hay necesidad de rotar.
      if (matchingPolygons && matchingPolygons.length <= 1) {
        return;
      }

      // Encontrar el índice del polígono actualmente seleccionado dentro de los que coinciden.
      const currentIndex = matchingPolygons.findIndex((p) => p === parcelle);

      // Calcular el índice del siguiente polígono a seleccionar.
      // Si el polígono actual es el último, volver al primero (rotación).
      const nextIndex = (currentIndex + 1) % matchingPolygons.length;

      // Seleccionar el siguiente polígono.
      const nextParcelle = matchingPolygons[nextIndex];
      if (nextParcelle) {
        handleSelectParcelle(nextParcelle, {
          target: nextParcelle.ref.leafletElement,
        });
        return;
      }
    }

    lockInterface = true;

    if (selectedPoly) {
      outPoly(selectedPoly);
    }

    const poly = event.target;
    updateNdvi(parcelle);

    setSelectedPoly(event.target);

    updateParcelleSurface(parcelle);

    setSelectedParcelle(parcelle);
    setNdviMode(true);

    loadHisto(parcelle);

    if (poly && poly.setStyle) {
      poly.setStyle({ fillOpacity: 0 });
    }

    centerToParelle(event);
  };

  const shortPolys = (polys) => {
    return polys.sort((a, b) => {
      const aHasUnderscore = a.name.includes("_");
      const bHasUnderscore = b.name.includes("_");

      if (aHasUnderscore === bHasUnderscore) {
        return a.name.localeCompare(b.name);
      }

      if (aHasUnderscore) {
        return 1;
      }

      return -1;
    });
  };

  const centerToParelle = (event) => {
    if (!interactiveMode) return;

    const poly = event.target;

    const leaflet = map.leafletElement;

    const bounds = poly.getBounds();

    leaflet.fitBounds(bounds);

    const center = bounds.getCenter();

    var earth = 6378.137, //radius of the earth in kilometer
      pi = Math.PI,
      cos = Math.cos,
      m = 1 / (((2 * pi) / 360) * earth) / 1000; //1 meter in degree

    const new_lon = center.lng + (-100 * m) / cos(center.lat * (pi / 180));
    const newLatLng = { lat: center.lat, lon: new_lon };

    leaflet.panTo(newLatLng, { animate: true });

    setTimeout(() => {
      leaflet.panTo(newLatLng, { animate: true });
      setTimeout(() => (lockInterface = false), 300);
    }, 300);
  };

  const updateNdviStats = (parcelle, histo, forceNdvi) => {
    parcelle.legend = [];
    let max = 0;
    let minTemp = 0,
      maxTemp = 0,
      minRain = 0,
      maxRain = 0;
    const selHisto = histo[0];

    parcelle.ndvi = histo
      .slice()
      .reverse()
      .map((h) => {
        if (h.average > max) max = h.average + 0.01;

        const val = {
          value: h.average,
          rainAvg: h.climaAverage ? h.climaAverage.PRECTOT_SUM : 0,
          rainDaily: h.climaDaily ? h.climaDaily.CUM_PREC : 0,
          tempMinAvg: h.climaAverage ? h.climaAverage.T2M_MIN : 0,
          tempMinDaily: h.climaDaily ? h.climaDaily.T2M_MIN : 0,
          tempMaxAvg: h.climaAverage ? h.climaAverage.T2M_MAX : 0,
          tempMaxDaily: h.climaDaily ? h.climaDaily.T2M_MAX : 0,
          date: moment(h.createdAt).format("DD/MM"),
          hail: Math.ceil(h.hail),
          hailDate: h.hailDate,
        };

        if (val.tempMinAvg < minTemp) minTemp = val.tempMinAvg;
        if (val.tempMinDaily < minTemp) minTemp = val.tempMinDaily;

        if (val.tempMaxAvg > maxTemp) maxTemp = val.tempMaxAvg;
        if (val.tempMaxDaily > maxTemp) maxTemp = val.tempMaxDaily;

        if (val.rainAvg > maxRain) maxRain = val.rainAvg;
        if (val.rainDaily > maxRain) maxRain = val.rainDaily;

        return val;
      });

    parcelle.ndviLastUpdate = selHisto.createdAt;

    parcelle.legend = getLegend(forceNdvi)
      .reverse()
      .filter((l) => max > l.min || max >= l.max);

    setSelectedParcelle(parcelle);
    setMinMaxVals({
      minTemp,
      maxTemp: Math.ceil(maxTemp + 5),
      minRain,
      maxRain: Math.ceil(maxRain + 50),
    });
  };

  const loadHisto = async (parcelle, ndviActive = true) => {
    const ys = await userService.getYieldStatus(parcelle.name);
    setHisto([]);
    const histo = ndviActive
      ? await userService.getHisto(parcelle.name)
      : await userService.getYield(parcelle.name);
    if ((histo && histo.length > 0 && ys.status === "ready") || ndviActive) {
      if (histo && histo.length) {
        const selHisto = histo[0];
        setHisto(histo);
        reloadComments();
        setHistoIndex(0);
        updateNdviStats(parcelle, histo, ndviActive);
        setSelectedHisto(selHisto);
        setHistoNotFund(false);
        setChartVisible(true);
      } else {
        setHisto(null);
        setHistoIndex(0);
        setSelectedHisto(null);
        setHistoNotFund(true);
        setChartVisible(false);
      }
    } else {
      if (!histo || histo.length <= 0 || ys.status === "not_available") {
        setHistoNotFund(true);
        setHisto(null);
        setHistoIndex(0);
        setChartVisible(false);
      }
    }
    setYieldStatus(ys.status);
  };

  const updateTempMode = (event) => {
    setChartVisible(false);
    setTempMode(event.target.checked);
    if (event.target.checked === true) {
      setRainMode(false);
    }
    setTimeout(() => setChartVisible(true), 100);
  };

  const updateRainMode = (event) => {
    setChartVisible(false);
    setRainMode(event.target.checked);
    if (event.target.checked === true) {
      setTempMode(false);
    }

    setTimeout(() => setChartVisible(true), 100);
  };

  const updateHailMode = (event) => {
    setChartVisible(false);
    setHailMode(event.target.checked);

    setTimeout(() => setChartVisible(true), 100);
  };

  const onLoadComments = (new_comments, new_histos) => {
    setLoadComments(false);
    if (new_comments != comments) {
      setComments(new_comments);
      setHisto(new_histos);
    }
    updateNdvi(selectedParcelle);
  };

  const nextHisto = () => {
    const newIndex = histoIndex - 1;
    if (newIndex >= 0) {
      setSelectedHisto(histo[newIndex]);
      setHistoIndex(newIndex);
    }
  };

  const prevHisto = () => {
    const newIndex = histoIndex + 1;
    if (histo && newIndex < histo.length) {
      setSelectedHisto(histo[newIndex]);
      setHistoIndex(newIndex);
    }
  };

  const selectHisto = (histo_id, show_comments) => {
    for (let i = 0; i < histo.length; i++) {
      if (histo && histo[i]._id == histo_id) {
        setSelectedHisto(histo[i]);
        setHistoIndex(i);
        break;
      }
    }
    setShowComments(show_comments);
  };

  const updateNdvi = (parcelle) => {
    if (!parcelle.ndvi) {
      parcelle.ndvi = generatePolyData();
    }

    for (const ndvi of ndviLegend) {
      ndvi.count = 0;
    }

    for (const val of parcelle.ndvi) {
      for (const ndvi of ndviLegend) {
        if (val.value >= ndvi.min && val.value <= ndvi.max) {
          ndvi.count = ndvi.count ? ndvi.count + 1 : 1;
        }
      }
    }

    for (const ndvi of ndviLegend) {
      const count = ndvi.count || 0;
      ndvi.percent =
        count <= 0 ? "0%" : Math.ceil((count / ndviLegend.length) * 100) + "%";
    }
  };

  const handleCloseParcelle = () => {
    resetAllSelections();
  };

  const rand = (min, max) => {
    return Math.floor(Math.random() * max) + min;
  };

  const generatePolyData = () => {
    const days = 7;
    const min = 0.04;
    const max = 1;
    let date = moment().subtract(days, "days");
    const data = [];
    for (var i = 1; i <= days; i++) {
      const maxVals = rand(4, ndviLegend.length - 1);
      const dataItem = {
        date: date.toDate(),
        value: parseFloat((Math.random() * (min - max) + max).toFixed(3)),
      };

      date.add(1, "day");
      data.push(dataItem);
    }

    return data;
  };

  const generateData = () => {
    for (const polygon of polygons) {
      if (polygon.ndvi === undefined) {
        polygon.ndvi = generatePolyData();
      }
    }
  };

  const CustomTooltip = ({ active, payload, label, ndviMode }) => {
    if (active && payload && payload.length) {
      const props = payload[0].payload;

      return (
        <div className="custom-tooltip">
          <p className="label">{`${label}`}</p>
          <p className="label-item">
            <span>
              <Trans>{ndviMode ? "NDVI" : "YIELD"}</Trans>:
            </span>{" "}
            {ndviMode
              ? payload[0].value.toFixed(2)
              : payload[0].value
                ? payload[0].value.toFixed(0)
                : "N.A"}
          </p>
          {payload.map((item, idx) => {
            if (idx > 0) {
              return (
                <p key={"tool_" + idx} className="label-item">
                  <span>{t(item.dataKey)}:</span>{" "}
                  {parseFloat(item.payload[item.dataKey]).toFixed(0)}
                </p>
              );
            }
          })}
          {hailMode && (
            <>
              <p className="label">
                <span>{t("Hail")}:</span> {props.hail || 0} mm
              </p>
              <p className="label">
                <span>{t("Hail") + " " + t("Date")}:</span> {props.hailDate}
              </p>
            </>
          )}
        </div>
      );
    }

    return null;
  };

  const handleResize = (event) => {
    console.log(window.innerWidth);
    if (window.innerWidth <= 500) {
      setMaxGraphWidth(CHART_MIN);
      setMaxGraphHeight(CHART_H_MIN);
    } else {
      setMaxGraphWidth(CHART_MAX);
      setMaxGraphHeight(CHART_H_MAX);
    }
    setVisible(true);
  };

  const changeToNdviMode = async (ndviActive) => {
    setNdviMode(ndviActive);
    const res = await loadHisto(selectedParcelle, ndviActive);
  };

  const YieldStatus = ({ status }) => {
    if (status === "cultive_required") {
      return (
        <div className="yieldInfo">
          <Trans>
            You must select the crop to view the yield map, please pulse EDIT
            and choose a valid crop or enter another crop
          </Trans>
          <Button
            size="small"
            color="primary"
            variant="contained"
            onClick={(event) => setShowEditParcelle(true)}
          >
            <Trans>Edit</Trans>
          </Button>
        </div>
      );
    } else if (status === "not_available") {
      return (
        <div className="yieldInfo">
          <Trans>The yield potential map is not available for this crop</Trans>
        </div>
      );
    } else if (status === "calculating") {
      return (
        <div className="yieldInfo">
          <Trans>Calculation of current return potentials</Trans>
        </div>
      );
    } else {
      return <div className="yieldInfo"></div>;
    }
  };

  const toggleToReport = (histo) => {
    const date = moment(histo.createdAt).format("YYYY/MM/DD");
    const index = reportHistos.indexOf(date);
    if (index > -1) {
      reportHistos.splice(index, 1);
    } else {
      reportHistos.push(date);
    }
    setReportHistos([...reportHistos]);
  };

  const isInReport = (histo) => {
    const date = moment(histo.createdAt).format("YYYY/MM/DD");
    return reportHistos.indexOf(date) > -1;
  };

  const exportReport = () => {
    setLoadingReport(true);
    const histo = [...reportHistos];
    if(isCordova){
      userService.getReportCordova(selectedParcelle.name, histo, (url) => {
        setLoadingReport(false);
      });
    }else{
      userService.getReport(selectedParcelle.name, histo, (url) => {
        setLoadingReport(false);
      });
    }

  };

  useEffect(() => {
    setUrl(provider.map);
  }, [provider]);

  useEffect(() => {
    setReportHistos([]);
  }, [selectedParcelle]);

  useEffect(() => {
    if (selectedHisto && selectedHisto.image) {
      const prefix =
        selectedHisto.image.indexOf("images") > -1
          ? getImageBucket("images/ndvi/")
          : getImageBucket();
      setCurrentImage(prefix + selectedHisto.image);
    } else {
      setCurrentImage(null);
    }
  }, [selectedHisto]);

  useEffect(() => {
    if (match.params.type && match.params.id && map) {
      const type = match.params.type;
      const id = match.params.id;

      setTimeout(() => {
        if (type == "explotation") {
          loadExplotation(id);
        } else if (type == "parcelle") {
          loadParcelle(id);
        }
      }, 300);
    } else if (map) {
      findMyPosition();
    }

    handleResize();
  }, [map]);

  useEffect(() => {
    if (editablePoly) {
      const polygon = editablePoly;
      // Accede a la instancia de Leaflet
      const leafletPolygon = polygon.leafletElement;
      // Habilita la edición de Leaflet directamente
      if (leafletPolygon.editing && leafletPolygon.editing.enable) {
        // Inicia la edición
        leafletPolygon.editing.enable();
      }
    }
  }, [editablePoly]);

  /*let imageInverval = null;
  useEffect(() => {
    clearInterval(imageInverval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    imageInverval = setInterval(() => {
      if (selectedParcelle && selectedParcelle.name.indexOf("_") > -1) {
        const images = document.getElementsByClassName("leaflet-image-layer");
        for (const img of images) {
          img.style.zIndex = 1000;
        }
      }
    }, 1000);
    return () => clearInterval(imageInverval);
  }, [selectedParcelle]);*/

  useEffect(() => {
    if(map && map.leafletElement){
      map.leafletElement.eachLayer(function(layer){
        if(layer instanceof leaflet.Polygon && !(layer instanceof leaflet.Rectangle) && layer.options && layer.options.name && layer.options.name.indexOf('_') > -1){
          layer.bringToFront();
        }
      });
    }
  }, [selectedParcelle]);


  return (
    <>
      +<EventListener target="window" onResize={handleResize} />
      <AppBar position="fixed" className="subheader">
        <Toolbar>
          <Box className="search-box" display="flex">
            <FormControl className="flex">
              <Autocomplete
                freeSolo
                id="search-autocomplete"
                disableClearable
                onInputChange={(e, v) => findLocation(e, v)}
                options={findResults}
                getOptionLabel={(option) => option.description}
                onChange={(opt, val) => selectLocation(opt, val)}
                filterOptions={(options, state) => options}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="search-box"
                    placeholder={t("Search location")}
                    InputProps={{
                      ...params.InputProps,
                      type: "search",
                      disableUnderline: true,
                      startAdornment: (
                        <InputAdornment position="start">
                          <Icon>search</Icon>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>
            <IconButton
              aria-label="my-position"
              type="button"
              onClick={findMyPosition}
            >
              <Icon fontSize="small">location_on</Icon>
            </IconButton>
          </Box>
          <span>
            <FormControl variant="standard" className="selector">
              <Select
                labelId="vue-label"
                id="views"
                value={provider}
                label={t("Views")}
                renderValue={(value) => (
                  <span className="flex-gap">
                    <MapOutlined />
                    <div>{value.label}</div>
                  </span>
                )}
                onChange={(e) =>
                  setProvider(providers.find((p) => p.map === e.target.value))
                }
              >
                {providers.map((provider) => (
                  <MenuItem key={provider.label} value={provider.map}>
                    <ListItemIcon>
                      <MapOutlined />
                    </ListItemIcon>
                    <ListItemText primary={provider.label} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </span>
          <span className={classes.title}></span>
          <div className={classes.buttons}>
            {currentNewPoly && (
              <>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleClosePoly}
                  startIcon={<Icon>done</Icon>}
                >
                  <Trans>Update Polygon</Trans>
                </Button>
                <Button
                  variant="contained"
                  onClick={handleEndNewPoly}
                  startIcon={<Icon>delete</Icon>}
                >
                  <Trans>Cancel</Trans>
                </Button>
              </>
            )}
          </div>
        </Toolbar>
      </AppBar>
      {visible ? (
        <Map
          ref={(ref) => setMap(ref)}
          className="fullscreen-withbar"
          center={center}
          zoom={zoom}
          keyboard={false}
          onMovestart={handlePosition}
          onMoveend={handleMove}
        >
          <TileLayer
            attribution='&amp;copy <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
            url={url}
          />

          {myPosition ? (
            <Marker position={myPosition} icon={myIcon}></Marker>
          ) : null}
          <FeatureGroup>
            <EditControl
              onDrawCreated={(event) => drawEnd(event)}
              position="topright"
              ref={(ref) => setEditControl(ref)}
            />

            {currentComment &&
              currentComment.surface &&
              currentComment.shape && (
                <Polygon
                  onclick={(evt) =>
                    setCurrentComment({ ...currentComment, create: true })
                  }
                  positions={currentComment.shape.coordinates}
                >
                  <TooltipMap permanent>
                    {currentComment.label ? currentComment.label + " - " : ""}{" "}
                    {currentComment.surface && currentComment.surface > 0
                      ? (parseFloat(currentComment.surface) / 10000).toFixed(
                          2
                        ) + " (ha)"
                      : ""}
                  </TooltipMap>
                </Polygon>
              )}
            {selectedHisto && selectedHisto.comments
              ? selectedHisto.comments.map((comment) => {
                  return comment.shape ? (
                    <Polygon
                      ref={(ref) => (comment.ref = ref)}
                      interactive={interactiveMode}
                      fillOpacity={DISABLED_OPACITY}
                      positions={comment.shape.coordinates.map((v) => {
                        return [v[1], v[0]];
                      })}
                      onMouseOver={handleOver}
                      onMouseOut={(evt) => handleOut(evt, DISABLED_OPACITY)}
                      onClick={(evt) => handlePolyClick(evt, comment)}
                    ></Polygon>
                  ) : null;
                })
              : null}
          </FeatureGroup>

          <FeatureGroup>
            <EditControl
              onDrawCreated={(event) => drawEnd(event)}
              position="topright"
              ref={(ref) => setEditControlPoly(ref)}
            />

            {polygons.map((value, index) => {
              return (
                <FeatureGroup key={"span" + index}>
                  {selectedParcelle === value &&
                  selectedParcelle &&
                  selectedHisto &&
                  selectedHisto.name === selectedParcelle.name ? (
                    <>
                      <ImageOverlay
                        url={
                          selectedHisto.image.indexOf("images") > -1
                            ? getImageBucket(selectedHisto.image)
                            : getImageBucket(
                                "images/ndvi/" + selectedHisto.image
                              )
                        }
                        opacity={value !== selectedParcelle ? 0 : 1}
                        bounds={value.shape.coordinates.map((v) => {
                          return [v[1], v[0]];
                        })}
                      />

                      <FeatureGroup>
                        <Polygon
                          ref={(ref) => (value.ref = ref)}
                          fillOpacity={0}
                          color="green"
                          interactive={interactiveMode}
                          positions={value.shape.coordinates.map((v) => {
                            return [v[1], v[0]];
                          })}
                          onClick={(event) =>
                            handleSelectParcelle(value, event)
                          }
                        />
                        {currentNewPoly && currentNewPoly.shape && (
                          <Polygon
                            key={value.name}
                            name={value.name}
                            ref={(ref) => (value.ref = ref)}
                            fillOpacity={OUT_OPACITY}
                            color="#ff9100"
                            interactive={interactiveMode}
                            positions={currentNewPoly.shape.coordinates.map(
                              (v) => {
                                return [v[1], v[0]];
                              }
                            )}
                            onClick={(event) => centerToParelle(event)}
                          >
                            <TooltipMap permanent>
                              {value.label ? value.label + " - " : ""}{" "}
                              {currentNewPoly.surface &&
                              currentNewPoly.surface > 0
                                ? (
                                    parseFloat(currentNewPoly.surface) / 10000
                                  ).toFixed(2) + " (ha)"
                                : ""}{" "}
                              <b>
                                <Trans>
                                  Click on Update Polygon to save changes
                                </Trans>
                              </b>
                            </TooltipMap>
                          </Polygon>
                        )}
                      </FeatureGroup>
                    </>
                  ) : (
                    <Polygon
                      key={value.name}
                      name={value.name}
                      ref={(ref) => (value.ref = ref)}
                      fillOpacity={isSimilarPoly({options:{name: value.name || '' }}, selectedPoly) ? 0 : OUT_OPACITY}
                      color={value.user ? "#ff9100" : "green"}
                      interactive={interactiveMode}
                      positions={value.shape.coordinates.map((v) => {
                        return [v[1], v[0]];
                      })}
                      onClick={(event) => handleSelectParcelle(value, event)}
                      onMouseOver={handleOver}
                      onMouseOut={handleOut}
                    />
                  )}
                </FeatureGroup>
              );
            })}
          </FeatureGroup>
        </Map>
      ) : null}
      {selectedParcelle ? (
        <div className={"parcelle-info" + (collapsedInfo ? " col" : " exp")}>
          <Card>
            <CardContent className="card-content">
              <Typography
                variant="h5"
                component="h2"
                className="header-container"
              >
                <Fab onClick={() => setCollapsedInfo(!collapsedInfo)}>
                  <Icon>{collapsedInfo ? "unfold_less" : "unfold_more"}</Icon>
                </Fab>
                <span>
                  {selectedParcelle.label
                    ? selectedParcelle.label
                    : selectedParcelle.name}
                </span>
                <span style={{ flex: 1 }}></span>
                {histo && (
                  <>
                    <IconButton
                      disabled={histoIndex >= histo.length - 1}
                      onClick={prevHisto}
                    >
                      <Icon>keyboard_arrow_left</Icon>
                    </IconButton>
                    {selectedHisto && (
                      <Moment
                        format="DD/MM/YYYY"
                        style={{ fontSize: 14 }}
                        date={moment(selectedHisto.createdAt)}
                      />
                    )}
                    <IconButton disabled={histoIndex == 0} onClick={nextHisto}>
                      <Icon>keyboard_arrow_right</Icon>
                    </IconButton>
                  </>
                )}
              </Typography>
              <div className="report-buttons">
                {loadingReport ? (
                  <CircularProgress size={24} />
                ) : selectedHisto ? (
                  <>
                    <Button
                      size="small"
                      color={
                        isInReport(selectedHisto) ? "primary" : "secondary"
                      }
                      onClick={(event) => toggleToReport(selectedHisto)}
                    >
                      {isInReport(selectedHisto) ? (
                        <Trans>Remove from report</Trans>
                      ) : (
                        <Trans>Add to report</Trans>
                      )}
                    </Button>
                    <br />
                    {reportHistos.length > 0 && (
                      <Button
                        size="small"
                        color="default"
                        onClick={(event) => exportReport()}
                      >
                        <Trans>Export report</Trans>
                      </Button>
                    )}
                  </>
                ) : null}
              </div>
              <Typography
                variant="body2"
                className={
                  "info" + (collapsedInfo ? " collapsed" : " expanded")
                }
                component="div"
              >
                <p>
                  <Trans>Crop</Trans>:<span>&nbsp;</span>
                  <b>
                    {selectedParcelle.targetCulture
                      ? t(selectedParcelle.targetCulture.name)
                      : "?"}
                  </b>
                </p>
                <p>
                  <Trans>Surface</Trans>:<span>&nbsp;</span>
                  <b>
                    {selectedParcelle.surface
                      ? (selectedParcelle.surface / 10000).toFixed(2) + " (ha)"
                      : "?"}
                  </b>
                </p>
                <p className="extra-info">
                  <Trans>Last update</Trans>:<span>&nbsp;</span>
                  <b>
                    <Moment
                      format="DD/MM/YYYY"
                      date={moment(selectedParcelle.ndviLastUpdate)}
                    />
                  </b>
                </p>
                { histo && histo.length <= 0 && (<div className="spinner"><CircularProgress size={50} /></div>)}

                {selectedHisto && (
                  <>
                    <p>
                      {ndviMode ? (
                        <Trans>Average NDVI of parcel</Trans>
                      ) : (
                        <Trans>Average yield of parcel (q/ha)</Trans>
                      )}
                      :<span>&nbsp;</span>
                      <b>
                        {histoNotFound
                          ? "-"
                          : ndviMode
                            ? selectedHisto.average?.toFixed(2)
                            : selectedHisto.average?.toFixed(0)}
                      </b>
                    </p>
                    <p>
                      <ButtonGroup
                        variant="contained"
                        aria-label="outlined primary button group"
                      >
                        <Button
                          color={ndviMode ? "primary" : ""}
                          onClick={() => changeToNdviMode(true)}
                        >
                          <Trans>NDVI</Trans>
                        </Button>
                        <Button
                          color={!ndviMode ? "primary" : ""}
                          onClick={() => changeToNdviMode(false)}
                        >
                          <Trans>YIELD</Trans>
                        </Button>
                      </ButtonGroup>
                    </p>
                  </>
                )}
              </Typography>

              {selectedHisto && (
                <>
                  <div
                    className={
                      "anim " + (collapsedInfo ? "collapsed" : "expanded")
                    }
                  >
                    {!histoNotFound ? (
                      <Grid container spacing={0}>
                        <Grid item xs={6}>
                          <table
                            className="table-ndvi"
                            border="0"
                            cellSpacing="0"
                            cellPadding="2"
                          >
                            <tbody>
                              {(ndviMode ? ndviLegend : redementLegend).map(
                                (value, index) => {
                                  return (
                                    <tr key={"td" + index} valign="top">
                                      <td>{value.label}</td>
                                      <td width="40%">&nbsp;</td>
                                      <td>
                                        <div
                                          className="legend"
                                          style={{
                                            backgroundColor: value.color,
                                            color: value.textColor,
                                          }}
                                        >
                                          {selectedHisto.histo && selectedHisto.histo.length > index
                                            ? Math.ceil(
                                                parseFloat(
                                                  selectedHisto.histo[index]
                                                ) * 100
                                              ) + "%"
                                            : null}
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                }
                              )}
                            </tbody>
                          </table>
                        </Grid>
                        <Grid item xs={6}>
                          <FormGroup>
                            <FormControlLabel
                              labelPlacement="start"
                              style={{ color: rainColor }}
                              control={
                                <Switch
                                  color="primary"
                                  checked={hailMode}
                                  onChange={updateHailMode}
                                  name="hailMode"
                                />
                              }
                              label={t("Hail")}
                            />
                            <FormControlLabel
                              labelPlacement="start"
                              style={{ color: rainColor }}
                              control={
                                <Switch
                                  color="primary"
                                  checked={rainMode}
                                  onChange={updateRainMode}
                                  name="rainMode"
                                />
                              }
                              label={t("Rainfall")}
                            />
                            <FormControlLabel
                              labelPlacement="start"
                              style={{ color: tempColor }}
                              control={
                                <Switch
                                  color="primary"
                                  checked={tempMode}
                                  onChange={updateTempMode}
                                  name="temMode"
                                />
                              }
                              label={t("Temperatures")}
                            />
                          </FormGroup>
                          <Grid className="legend-clima" container>
                            {rainMode ? (
                              <>
                                <Grid item xs={2}>
                                  <div className="line"></div>
                                </Grid>
                                <Grid item xs={10}>
                                  <Trans>Seasonal cumulative</Trans>
                                </Grid>
                                <Grid item xs={2}>
                                  <div className="icon_line">
                                    <img src={dotted_02} alt="dotted_02" />
                                  </div>
                                </Grid>
                                <Grid item xs={10}>
                                  <Trans>Avg Historical accumulation</Trans>
                                </Grid>
                              </>
                            ) : null}
                            {tempMode ? (
                              <>
                                <Grid item xs={2}>
                                  <div className="line"></div>
                                </Grid>
                                <Grid item xs={10}>
                                  <Trans>Daily Temperature</Trans>
                                </Grid>
                                <Grid item xs={2}>
                                  <div className="icon_line">
                                    <img src={dotted_01} alt="dotted_01" />
                                  </div>
                                </Grid>
                                <Grid
                                  item
                                  xs={10}
                                  style={{ color: tempAvgColor }}
                                >
                                  <Trans>Avg Temperature</Trans>
                                </Grid>
                              </>
                            ) : null}
                          </Grid>
                        </Grid>
                      </Grid>
                    ) : null}
                  </div>
                  {chartVisible &&
                  selectedParcelle &&
                  selectedParcelle.ndvi &&
                  selectedParcelle.legend ? (
                    <div
                      className={
                        "anim " + (collapsedInfo ? "collapsed" : "expanded")
                      }
                    >
                      <ComposedChart
                        width={maxGraphWidth}
                        height={maxGraphHeight}
                        className="composed-chart"
                        data={selectedParcelle.ndvi}
                      >
                        <defs>
                          <linearGradient
                            id="colorUv"
                            x1="0"
                            y1="0"
                            x2="0"
                            y2="1"
                          >
                            {selectedParcelle.legend.map((value, index) => {
                              return (
                                <stop
                                  key={"color" + index}
                                  offset={
                                    Math.ceil(
                                      ((index + 1) /
                                        selectedParcelle.legend.length) *
                                        100
                                    ) + "%"
                                  }
                                  stopColor={value.color}
                                />
                              );
                            })}
                          </linearGradient>
                        </defs>
                        <XAxis dataKey="date" tick={{ fontSize: 12 }} />
                        <YAxis
                          yAxisId={1}
                          dataKey="value"
                          tick={{ fontSize: 12 }}
                        />

                        {hailMode && (
                          <XAxis
                            dataKey="date"
                            xAxisId="hail-icons"
                            orientation="top"
                            height={60}
                            axisLine={false}
                            tickLine={false}
                            tickFormatter={(tick) => ""}
                            tick={(props) => {
                              const { x, y, payload } = props;
                              const entry = selectedParcelle.ndvi.find(
                                (e) => e.date === payload.value
                              );
                              if (entry && entry.hail > 0) {
                                return (
                                  <TT
                                    title={
                                      entry.hailDate +
                                      " - " +
                                      entry.hail +
                                      " mm"
                                    }
                                  >
                                    <image
                                      x={x - 15}
                                      y={y - 40}
                                      width={30}
                                      height={30}
                                      alt={entry.hail.toFixed(4)}
                                      href={hail_img}
                                    />
                                  </TT>
                                );
                              }
                              return null;
                            }}
                          />
                        )}

                        <Tooltip
                          content={<CustomTooltip ndviMode={ndviMode} />}
                        />
                        <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
                        <Area
                          yAxisId={1}
                          type="monotone"
                          dataKey="value"
                          stroke="#8884d8"
                          fillOpacity={1}
                          fill="url(#colorUv)"
                        />

                        <YAxis
                          yAxisId={2}
                          orientation="right"
                          dataKey={
                            rainMode
                              ? "rainDaily"
                              : tempMode
                                ? "tempMaxDaily"
                                : null
                          }
                          allowDataOverflow={true}
                          tickCount={10}
                          tickFormatter={(tick) => Math.ceil(tick)}
                          tick={{
                            fontSize: 12,
                            fill: rainMode
                              ? rainColor
                              : tempMode
                                ? tempColor
                                : null,
                          }}
                          axisLine={{
                            stroke: rainMode
                              ? rainColor
                              : tempMode
                                ? tempColor
                                : null,
                          }}
                          domain={
                            rainMode
                              ? [minMaxVals.minRain, minMaxVals.maxRain]
                              : tempMode
                                ? [minMaxVals.minTemp, minMaxVals.maxTemp]
                                : null
                          }
                          label={
                            <Text
                              x={0}
                              y={0}
                              dx={435}
                              dy={10}
                              fill={
                                rainMode
                                  ? rainColor
                                  : tempMode
                                    ? tempColor
                                    : null
                              }
                              fontSize={12}
                              textAnchor="end"
                              offset={0}
                              angle={-90}
                            >
                              {rainMode
                                ? t("Cumulative rainfall (mm)")
                                : tempMode
                                  ? t("Temperature dec (Cº)")
                                  : null}
                            </Text>
                          }
                        />
                        <Line
                          type="monotone"
                          yAxisId={2}
                          dataKey={rainMode ? "rainDaily" : null}
                          stroke={rainColor}
                          dot={{ r: 0.1 }}
                        />
                        <Line
                          type="monotone"
                          yAxisId={2}
                          dataKey={rainMode ? "rainAvg" : null}
                          stroke={rainColor}
                          dot={{ r: 0.1 }}
                          strokeDasharray="5 5"
                        />

                        <Line
                          type="monotone"
                          yAxisId={2}
                          dataKey={tempMode ? "tempMinDaily" : null}
                          stroke={tempColor}
                          dot={{ r: 0.1 }}
                        />
                        <Line
                          type="monotone"
                          yAxisId={2}
                          dataKey={tempMode ? "tempMaxDaily" : null}
                          stroke={tempColor}
                          dot={{ r: 0.1 }}
                        />

                        <Line
                          type="monotone"
                          yAxisId={2}
                          dataKey={tempMode ? "tempMinAvg" : null}
                          strokeWidth={3}
                          stroke={tempAvgColor}
                          dot={{ r: 0.1 }}
                          strokeDasharray="5 5"
                        />
                        <Line
                          type="monotone"
                          yAxisId={2}
                          dataKey={tempMode ? "tempMaxAvg" : null}
                          strokeWidth={3}
                          stroke={tempAvgColor}
                          dot={{ r: 0.1 }}
                          strokeDasharray="5 5"
                        />
                      </ComposedChart>
                    </div>
                  ) : null}
                  {histoNotFound && yieldStatus ? (
                    <YieldStatus status={yieldStatus} />
                  ) : null}
                </>
              )}
            </CardContent>
            <CardActions>
              {currentComment ? (
                <>
                  <Button
                    size="small"
                    color="primary"
                    onClick={handleSaveComment}
                  >
                    <Trans>Save comment</Trans>
                  </Button>
                  <Button size="small" onClick={handleCancelComment}>
                    <Trans>Cancel</Trans>
                  </Button>
                </>
              ) : (
                <>
                  {histo && selectedHisto ? (
                    <>
                      <Button
                        size="small"
                        color="primary"
                        onClick={handleOpenCommentsMenu}
                      >
                        <Trans>Add comment</Trans>
                      </Button>
                      <Button
                        size="small"
                        color="secondary"
                        onClick={(event) => setShowComments(true)}
                      >
                        <Trans>Show comments</Trans>
                      </Button>
                    </>
                  ) : null}

                  <Button
                    size="small"
                    color="primary"
                    onClick={(event) => setAnchorElPoly(event.target)}
                  >
                    <Trans>Edit</Trans>
                  </Button>
                  <Button size="small" onClick={handleCloseParcelle}>
                    <Trans>Close</Trans>
                  </Button>
                </>
              )}
            </CardActions>
          </Card>

          <Menu
            id="fade-menu"
            anchorEl={anchorEl}
            keepMounted
            open={openMenu}
            onClose={handleCloseCommentsMenu}
            TransitionComponent={Fade}
          >
            <MenuItem onClick={handleAddComment}>
              <Trans>With Polygon</Trans>
            </MenuItem>
            <MenuItem onClick={handleAddSimpleComment}>
              <Trans>Only Comments</Trans>
            </MenuItem>
          </Menu>

          <Menu
            id="fade-menu2"
            anchorEl={anchorElPoly}
            keepMounted
            open={openMenuPoly}
            onClose={handleClosePolyMenu}
            TransitionComponent={Fade}
          >
            <MenuItem onClick={(event) => setShowEditParcelle(true)}>
              <Trans>Edit Metadata</Trans>
            </MenuItem>
            <MenuItem onClick={handleEditPoly}>
              <Trans>Edit shape</Trans>
            </MenuItem>
          </Menu>
        </div>
      ) : null}
      <Comments
        open={showComments}
        reload={loadComments}
        parcele={selectedParcelle}
        currentHisto={selectedHisto}
        histoIndex={histoIndex}
        histo={histo}
        comments={comments}
        onNextHisto={nextHisto}
        onPrevHisto={prevHisto}
        onLoadComments={onLoadComments}
        onSelectHisto={selectHisto}
        onEditComment={(comment) => handleEditComment(comment)}
        onClose={() => setShowComments(false)}
      />
      {
        currentComment && (
          <AddComment
        open={currentComment && currentComment.create === true}
        comment={currentComment}
        onSave={(comment) => saveComment(comment)}
        onClose={() => {
          setCurrentComment({ ...currentComment, create: false });
          setTimeout(() => {
            handleCancelComment();
          }, 300);
        }}
      />
        )
      }

      {
        showEditParcelle && (
          <EditParcelle
            open={showEditParcelle}
            parcelle={showEditParcelle ? selectedParcelle : null}
            onSave={() => {
              setShowEditParcelle(false);
              setTimeout(() => changeToNdviMode(ndviMode), 500);
            }}
            onClose={() => setShowEditParcelle(false)}
          />
        )
      }

      
    </>
  );
}

export default MyPosition;
