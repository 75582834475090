import React, { useRef, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { TextField, makeStyles, createStyles, Theme } from "@material-ui/core";
import "./Login.css";
import { Link, useHistory } from "react-router-dom";
import { SlideTransition } from "../Util/Animations";
import { authService } from "../../services/AuthService";
import { useForm } from "react-hook-form";
import { useTranslation, Trans } from "react-i18next";
import isCordova, { Keyboard } from "../Util/isCordova";
import isMobileWidth from "../Util/isMobileWidth";
import { modalService } from "../../services/ModalService";
import { getLang } from "../../i18n";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogPaper: {
      [theme.breakpoints.between("xs", "sm")]: {
        margin: 0,
        maxWidth: "100% !important",
        width: "100%",
        maxHeight: "100vh",
        minHeight: "100vh",
      },
    },
    field: {
      display: "flex",
      margin: theme.spacing(2),
    },
    button: {
      margin: "auto",
      "padding-left": "10px",
      "padding-right": "10px",
    },
  })
);

type FormData = {
  email: string;
  password: string;
};

function Login() {
  const classes = useStyles();
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const [open, setOpen] = useState(true);
  const [focus, setFocus] = useState(false);
  const [recoverMode, setRecoverMode] = useState(false);

  const { register, handleSubmit, watch, errors, setError } = useForm();

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = (data: any) => {
    if (recoverMode) {
      authService
        .recover(data.email, getLang())
        .then((res) => {
          modalService.info({
            text: t(
              "We have sent you an email to reset your password. Check your inbox and spam folder in case your mail provider blocks it."
            ),
          });
        })
        .catch((err) => {
          setError("email", "notFound", t(err.message));
        });
    } else {
      authService
        .login(data.email, data.password)
        .then((res) => {
          handleClose();
          if (isCordova && Keyboard) {
            Keyboard.hide();
          }
          if (res?.user?.role.includes("head")) {
            history.push("/yieldregions");
          } else {
            history.push("/my-position");
          }
        })
        .catch((err) => {
          console.log(err);
          setError("email", "notFound", t("Your email or password are wrong"));
        });
    }
  };

  if (authService.token) {
    if (authService.user?.role.includes("head")) {
      history.push("/yieldregions");
    } else {
      history.push("/my-position");
    }
  }

  return (
    <Dialog
      open={open}
      //TransitionComponent={Transition}
      keepMounted
      classes={{ paper: classes.dialogPaper }}
      TransitionComponent={SlideTransition as any}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      fullWidth={true}
      onClose={handleClose}
    >
      {((isMobileWidth() && !focus) || !isMobileWidth()) && (
        <DialogTitle>
          <div className="logo">
            <img src="img/logo-color.png" />
          </div>
        </DialogTitle>
      )}

      <DialogContent>
        <form
          noValidate
          onSubmit={handleSubmit(onSubmit)}
          className="form-container"
          autoComplete="off"
        >
          <TextField
            id="email"
            className={classes.field}
            label={<Trans>Email</Trans>}
            name="email"
            type="email"
            variant="outlined"
            error={errors.email != null}
            helperText={errors.email && errors.email.message}
            onFocus={(evt) => setFocus(true)}
            onBlur={(evt) => setFocus(false)}
            inputRef={register({
              required: t("Enter your e-mail") as string,
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: t("Enter a valid e-mail address") as string,
              },
            })}
          />
          {!recoverMode ? (
            <>
              <TextField
                id="password"
                className={classes.field}
                label={<Trans>Password</Trans>}
                name="password"
                type="password"
                variant="outlined"
                error={errors.password}
                helperText={errors.password && t("password required")}
                onFocus={(evt) => setFocus(true)}
                onBlur={(evt) => setFocus(false)}
                inputRef={register({ required: true, min: 6 })}
              />
              <div className="button-container">
                <Button type="submit" variant="contained" color="primary">
                  <Trans>ENTER</Trans>
                </Button>
              </div>
            </>
          ) : (
            <div className="button-container">
              <Button type="submit" variant="contained" color="primary">
                <Trans>SEND RECOVERY MAIL</Trans>
              </Button>
            </div>
          )}
        </form>
      </DialogContent>
      <DialogActions>
        {!recoverMode ? (
          <div className="recover-container">
            <Trans>Lost your password?</Trans>
            <Button
              onClick={() => setRecoverMode(true)}
              className={classes.button}
              color="primary"
            >
              <Trans>Recover it.</Trans>
            </Button>
          </div>
        ) : (
          <div className="recover-container">
            <Button
              onClick={() => setRecoverMode(false)}
              className={classes.button}
              color="primary"
            >
              <Trans>Back to login</Trans>
            </Button>
          </div>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default Login;
