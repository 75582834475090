import React, { Component, useState, useEffect } from "react"; // let's also import Component
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  DialogTitle,
  Grid,
  Checkbox,
  FormGroup,
  FormControlLabel,
} from "@material-ui/core";
import moment from "moment";
import "moment-timezone";
import { SlideTransition } from "../Util/Animations";
import MomentUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { Trans, useTranslation } from "react-i18next";
import { cultureService } from "../../services/CultureService";
import { ICulture } from "../../../../shared/models/parcelle";
import { useForm } from "react-hook-form";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

export class EditCultureProps {
  open: boolean = true;
  culture?: ICulture;
  onClose?: (event: Event) => void;
  onSave?: (culture?: ICulture) => void;
}

export class EditCultureState {
  open: boolean = true;
  culture: ICulture = {} as ICulture;
}

export function EditCulture(props: Readonly<EditCultureProps>) {
  const [open, setOpen] = useState(props.open);
  const [culture, setCulture] = useState(props.culture);

  const { register, handleSubmit, watch, errors, setError } = useForm();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    setCulture(props.culture);
    setOpen(props.open);
  }, [props]);

  const onClose = (event: any) => {
    if (props.onClose) {
      props.onClose(event);
    }
  };

  const onSave = async (data: any) => {
    if (props.onSave) {
      if (culture) {
        let newCulture = culture;
        if (culture._id) {
          newCulture = await cultureService.updateCulture(culture);
        } else {
          newCulture = await cultureService.createCulture(culture);
        }
        props.onSave(newCulture);
        return;
      }
    }

    onClose(null);
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: string,
    fn?: (val: any) => any
  ) => {
    const newCulture = { ...culture } as any;
    newCulture[field] = fn ? fn(event.target.value) : event.target.value;
    setCulture(newCulture);
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={SlideTransition as any}
      keepMounted
      className="regular-dialog"
      onClose={(event) => onClose(event)}
    >
      {culture ? (
        <form
          noValidate
          onSubmit={handleSubmit(onSave)}
          className="form-container"
          autoComplete="off"
        >
          <DialogTitle>
            {culture._id ? (
              <>
                <Trans>Edit culture</Trans> {culture.name}
              </>
            ) : (
              <Trans>New culture</Trans>
            )}
          </DialogTitle>

          <DialogContent>
            <TextField
              autoFocus
              id="name"
              name="name"
              label={<Trans>Name</Trans>}
              variant="outlined"
              margin="normal"
              fullWidth
              autoComplete="off"
              onChange={(evt) => handleChange(evt, "name")}
              value={culture.name || ""}
              error={errors.name != null}
              helperText={errors.name && t("name required")}
              inputRef={register({ required: true })}
            />
            <TextField
              id="abbr"
              name="abbr"
              label={<Trans>Abbr</Trans>}
              variant="outlined"
              margin="normal"
              fullWidth
              autoComplete="off"
              onChange={(evt) => handleChange(evt, "abbr")}
              value={culture.abbr || ""}
              error={errors.abbr != null}
              helperText={errors.abbr && t("abbr required")}
            />

            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="min"
                  name="min_yield"
                  label={<Trans>Min</Trans>}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  autoComplete="off"
                  onChange={(evt) => handleChange(evt, "min_yield")}
                  value={culture.min_yield || "0"}
                  error={errors.min_yield != null}
                  helperText={errors.min_yield && t("min_yield required")}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="max"
                  name="max_yield"
                  label={<Trans>Max</Trans>}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  autoComplete="off"
                  onChange={(evt) => handleChange(evt, "max_yield")}
                  value={culture.max_yield || "0"}
                  error={errors.max_yield != null}
                  helperText={errors.max_yield && t("max_yield required")}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="min_av"
                  name="min_yield_av"
                  label={<Trans>Min (bottom)</Trans>}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  autoComplete="off"
                  onChange={(evt) => handleChange(evt, "min_yield_av")}
                  value={culture.min_yield_av || "0"}
                  error={errors.min_yield_av != null}
                  helperText={errors.min_yield && t("min_yield_av required")}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="max_av"
                  name="max_yield_av"
                  label={<Trans>Max (top)</Trans>}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  autoComplete="off"
                  onChange={(evt) => handleChange(evt, "max_yield_av")}
                  value={culture.max_yield_av || "0"}
                  error={errors.max_yield_av != null}
                  helperText={errors.max_yield_av && t("max_yield_av required")}
                />
              </Grid>
            </Grid>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={culture.validated === true}
                    onChange={(evt, checked) =>
                      handleChange(
                        { target: { value: checked } } as any,
                        "validated"
                      )
                    }
                  />
                }
                label={t("Validated")}
              />
            </FormGroup>
          </DialogContent>

          <DialogActions>
            <Button type="submit" color="primary">
              <Trans>Save</Trans>
            </Button>
            <Button onClick={(event) => onClose(event)}>
              <Trans>Close</Trans>
            </Button>
          </DialogActions>
        </form>
      ) : null}
    </Dialog>
  );
}
