import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import LanguageDetector from "i18next-browser-languagedetector";
// not like to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init
import en from "./locales/en/translation.json";
import fr from "./locales/fr/translation.json";

i18n
  // load translation using xhr -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-xhr-backend
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options

  .init({
    fallbackLng: "en",
    debug: true,
    react: {
      wait: false,
      nsMode: "default",
    },
    resources: {
      en: { translation: en },
      fr: { translation: fr },
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export const langs = ["en", "fr"];

export const getLang = () => {
  const lang = i18n.language.indexOf("-") > -1 ? i18n.language.split("-")[0] : i18n.language;
  return langs.indexOf(lang) > -1 ? lang : langs[0];
};

i18n.on("languageChanged", (lng) => {
  document.documentElement.lang = lng;
});

document.documentElement.lang = getLang();

export default i18n;
