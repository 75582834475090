
export const isCordova =  (window.cordova) ? true : false;
export const Keyboard = window.Keyboard;
export default isCordova;

export const getUrl =  (url) => {
    let newUrl= url;
    if(!isCordova){
        newUrl = '/' + url;
    }
    return newUrl;
}



