import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { IYieldRegionsClient } from "../../../../../shared/models/regions";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ErrorBar,
  LabelList,
  ResponsiveContainer,
  Label,
} from "recharts";

import "./Chart.css";

export interface ChartProps {
  regions: IYieldRegionsClient[];
  onClickRegion?: (region: IYieldRegionsClient) => void;
  formatter?: (value: any) => any;
  fieldName?: string;
  fieldErrorName?: string;
  hideLegend?: boolean;
  title?: string;
}

const defaultFormatter = (value: any) => {
  return Math.floor(Number(value));
};

export const Chart = ({
  regions,
  onClickRegion,
  fieldName,
  fieldErrorName,
  formatter,
  hideLegend,
  title,
}: ChartProps) => {
  const { t } = useTranslation();
  const [data, setData] = useState<any>(regions);

  useEffect(() => {
    setData(regions);
  }, [regions]);

  return (
    <div>
      {title && <h3>{title}</h3>}

      <ResponsiveContainer width={"100%"} height={600}>
        <BarChart
          layout="vertical"
          width={650}
          height={600}
          data={data}
          margin={{
            top: 20,
            right: 0,
            left: 0,
            bottom: 10,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" horizontal={false} />
          <XAxis type="number" axisLine={false} stroke="#a0a0a0" />
          <YAxis type="category" width={210} dataKey="region_id.name" />
          <Tooltip formatter={formatter ? formatter : defaultFormatter} />
          {hideLegend ? null : (
            <Legend
              formatter={(value, entry, index) => (
                <span className="text-color-class">{t(value)}</span>
              )}
            />
          )}

          {fieldName && (
            <Bar dataKey={fieldName} fill="#1B5E20">
              {fieldErrorName && (
                <ErrorBar
                  dataKey={fieldErrorName}
                  strokeWidth={2}
                  stroke="#8CC981"
                />
              )}
            </Bar>
          )}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};
