
export class Comment{
    parcelle: any;
    date: Date = new Date();
    label?: string;
    surface?: number;
    comment?:string;
    shape: any;
    create = false;
    poly?: any;
    layer?: any;
}