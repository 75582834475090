import React, { useEffect } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { useTranslation, Trans } from "react-i18next";
import "./Cultures.css";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import {
  AppBar,
  Toolbar,
  Box,
  FormControl,
  Input,
  InputAdornment,
  Icon,
  IconButton,
  Button,
  TableSortLabel,
} from "@material-ui/core";
import { EditCulture } from "./EditCulture";
import { cultureService } from "../../services/CultureService";
import Moment from "react-moment";
import { ICulture } from "../../../../shared/models/parcelle";

export class CultureQuery {
  q?: string;
  abbr?: string;
  name?: string;

  limit: number = 10;
  skip: number = 0;
  orderBy?: string;
  order?: string;

  constructor(limit: number, skip: number, orderBy?: string, order?: string) {
    this.limit = limit;
    this.skip = skip;
    this.orderBy = orderBy;
    this.order = order;
  }
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      backgroundColor: "#8cc981",
      top: "60px",
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    buttons: {
      "& > *": {
        margin: theme.spacing(1),
      },
    },
    title: {
      flexGrow: 1,
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
  })
);

type Order = "asc" | "desc";

function Cultures() {
  const classes = useStyles();
  const rpp = 15;
  const cp = 0;

  const { t, i18n } = useTranslation();
  const [rows, setRows] = React.useState<ICulture[]>([]);
  const [open, setOpen] = React.useState(false);
  const [culture, setCulture] = React.useState({} as ICulture);
  const [orderBy, setOrderBy] = React.useState("createdAt");
  const [order, setOrder] = React.useState<Order>("desc");
  const [q, setQ] = React.useState<string | undefined>(undefined);

  const [page, setPage] = React.useState(0);
  const [count, setCount] = React.useState(0);

  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [cultureQuery, setCultureQuery] = React.useState(
    new CultureQuery(rpp, cp, "createdAt", "desc")
  );

  const createNewCulture = () => {
    setOpen(true);
    setCulture({} as ICulture);
  };

  const headCells = [
    { id: "name", label: t("Name"), align: "left" },
    { id: "abbr", label: t("Abbr") },
    { id: "validated", label: t("Validated") },
    { id: "actions", label: t("Actions") },
  ];

  const createSortHandler = (property: string, event: any) => {
    const newOrder = order == "asc" ? "desc" : "asc";
    setOrderBy(property);
    setOrder(newOrder);
    setCultureQuery({ ...cultureQuery, orderBy: property, order: newOrder });
  };

  const loadResults = async () => {
    const result = await cultureService.getCultures(cultureQuery);
    setCount(result.count);
    setRows(result.items);
  };

  const editRow = (culture: ICulture) => {
    setOpen(true);
    setCulture(culture);
  };

  const changePage = (evt: any, p: number) => {
    const limit = rowsPerPage;
    const skip = p * limit;
    setPage(p);
    setCultureQuery({ ...cultureQuery, skip, limit });
  };

  const changeRowsPerPage = (evt: any) => {
    const limit = parseInt(evt.target.value);
    const skip = page * limit;
    setRowsPerPage(limit);
    setCultureQuery({ ...cultureQuery, skip, limit });
  };

  const search = () => {
    setCultureQuery({ ...cultureQuery, q });
  };

  const resetSearch = () => {
    setQ("");
    setCultureQuery({ ...cultureQuery, q: "" });
  };

  const deleteCulture = async (culture: ICulture) => {
    const confirm = window.confirm(
      t("Do you want to delete this culture? This action cannot be undone.")
    );

    if (confirm) {
      await cultureService.deleteCulture(culture);
      await loadResults();
    }
  };

  useEffect(() => {
    loadResults();
  }, [cultureQuery]);

  return (
    <>
      <AppBar position="fixed" className={classes.root}>
        <Toolbar>
          <Box className="search-box" display="flex">
            <FormControl className="flex">
              <Input
                id="search-box"
                name="search-box"
                placeholder={t("Search cultures")}
                disableUnderline={true}
                autoComplete="off"
                value={q}
                onChange={(evt) => setQ(evt.target.value)}
                startAdornment={
                  <InputAdornment position="start">
                    <Icon>search</Icon>
                  </InputAdornment>
                }
                endAdornment={
                  <>
                    {q != "" ? (
                      <IconButton component="span" onClick={resetSearch}>
                        <Icon>close</Icon>
                      </IconButton>
                    ) : null}
                    <Button color="primary" onClick={search}>
                      <Trans>Search</Trans>
                    </Button>
                  </>
                }
              />
            </FormControl>
          </Box>
          <span className={classes.title}></span>
          <div className={classes.buttons}>
            <Button
              variant="contained"
              color="secondary"
              onClick={createNewCulture}
              startIcon={<Icon>agriculture</Icon>}
            >
              <Trans>NEW CULTURE</Trans>
            </Button>
          </div>
        </Toolbar>
      </AppBar>
      <div className="table-cultures-container">
        <TableContainer component={Paper}>
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align={headCell.align ? "left" : "center"}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={(evt) => createSortHandler(headCell.id, evt)}
                    >
                      {headCell.label}
                      {orderBy === headCell.id ? (
                        <span className={classes.visuallyHidden}>
                          {order === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </span>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.name}>
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    {row.abbr}
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    {row.validated ? "✔" : "✘"}
                  </TableCell>
                  <TableCell align="center">
                    <IconButton
                      color="primary"
                      onClick={() => editRow(row)}
                      component="span"
                    >
                      <Icon>edit</Icon>
                    </IconButton>
                    <IconButton
                      onClick={() => deleteCulture(row)}
                      color="secondary"
                      component="span"
                    >
                      <Icon>delete</Icon>
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 15, 25, 50]}
                  count={count}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  labelRowsPerPage=""
                  SelectProps={{
                    inputProps: { "aria-label": "rows per page" },
                    native: true,
                  }}
                  onPageChange={(evt, page) => changePage(evt, page)}
                  onRowsPerPageChange={(evt) => changeRowsPerPage(evt)}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
      <EditCulture
        open={open}
        culture={culture}
        onSave={() => {
          setOpen(false);
          loadResults();
        }}
        onClose={() => setOpen(false)}
      />
    </>
  );
}

export default Cultures;
