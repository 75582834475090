import React, { useRef, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { TextField, makeStyles, createStyles, Theme } from "@material-ui/core";
import "./Recover.css";
import { Link, useHistory, useParams } from "react-router-dom";
import { SlideTransition } from "../Util/Animations";
import { authService } from "../../services/AuthService";
import { useForm } from "react-hook-form";
import { AlertDialog, AlertDialogProps } from "../Dialogs/AlertDialog";
import { useTranslation, Trans } from "react-i18next";
import isCordova, { Keyboard } from "../Util/isCordova";
import isMobileWidth from "../Util/isMobileWidth";
import { modalService } from "../../services/ModalService";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogPaper: {
      [theme.breakpoints.between("xs", "sm")]: {
        margin: 0,
        maxWidth: "100% !important",
        width: "100%",
        maxHeight: "100vh",
        minHeight: "100vh",
      },
    },
    field: {
      display: "flex",
      margin: theme.spacing(2),
    },
    button: {
      margin: "auto",
      "padding-left": "10px",
      "padding-right": "10px",
    },
  })
);

function Recover() {
  let { token } = useParams<{ token: string }>();
  const classes = useStyles();
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const [open, setOpen] = useState(true);
  const [focus, setFocus] = useState(false);

  const { register, handleSubmit, watch, errors, setError } = useForm();

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = (data: any) => {
    authService
      .reset(token, data.password, data.repassword)
      .then((res) => {
        modalService.info({ text: t("Password reset, return to login") });
        history.push("/");
      })
      .catch((err) => {
        modalService.error({ text: "ERROR: " + JSON.stringify(err) });
      });
  };

  return (
    <Dialog
      open={open}
      //TransitionComponent={Transition}
      keepMounted
      classes={{ paper: classes.dialogPaper }}
      TransitionComponent={SlideTransition as any}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      fullWidth={true}
      onClose={handleClose}
    >
      {((isMobileWidth() && !focus) || !isMobileWidth()) && (
        <DialogTitle>
          <h1>
            <Trans>Change your password</Trans>
          </h1>
        </DialogTitle>
      )}

      <DialogContent>
        <form
          noValidate
          onSubmit={handleSubmit(onSubmit)}
          className="form-container"
          autoComplete="off"
        >
          <TextField
            id="password"
            className={classes.field}
            label={<Trans>Password</Trans>}
            name="password"
            type="password"
            variant="outlined"
            error={errors.password}
            helperText={
              errors.password &&
              t("the password must have at least 8 characters")
            }
            onFocus={(evt) => setFocus(true)}
            onBlur={(evt) => setFocus(false)}
            inputRef={register({ required: true, minLength: 8 })}
          />
          <TextField
            id="repassword"
            className={classes.field}
            label={<Trans>Repeat Password</Trans>}
            name="repassword"
            type="password"
            variant="outlined"
            error={errors.repassword}
            helperText={errors.repassword && t("passwords must be equal")}
            onFocus={(evt) => setFocus(true)}
            onBlur={(evt) => setFocus(false)}
            inputRef={register({
              required: true,
              minLength: 8,
              validate: ((value: any, formValues: any) => {
                return value === watch("password");
              }) as any,
            })}
          />
          <div className="button-container">
            <Button type="submit" variant="contained" color="primary">
              <Trans>RESET PASSWORD</Trans>
            </Button>
            <Button
              type="button"
              variant="contained"
              color="secondary"
              onClick={() => history.push("/my-position")}
            >
              <Trans>Cancel</Trans>
            </Button>
          </div>
        </form>
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  );
}

export default Recover;
