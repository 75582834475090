import React, { Component, useState, useEffect} from 'react'; // let's also import Component
import { Dialog, DialogContent, DialogActions, Button, TextField, DialogTitle, Grid, FormLabel, Divider, Box } from '@material-ui/core';
import moment from 'moment';
import "moment-timezone";
import { SlideTransition } from '../Util/Animations';
import MomentUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { Trans, useTranslation } from 'react-i18next';
import { restrictionService } from '../../services/RestrictionService';
import { IPolygon, IPolygonGeo, IRestriction } from '../../../../shared/models/parcelle';
import { useForm } from "react-hook-form";
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { JsonEditor as Editor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';
import Ajv from 'ajv';
import ace from 'brace';
import 'brace/mode/json';
import 'brace/theme/chrome';

const ajv = new Ajv({ allErrors: true, verbose: true });

const schema = {
  "type": "object",
  "required": ["type", "coordinates"],
  "geometry": {
    "hasArea": true
  },
  "properties": {
    "type": {
      "enum": ["Polygon", "MultiPolygon"]
    },
    "coordinates": {
      "type": "array"
    },
    "bbox": {
      "type": "array",
      "minItems": 4,
      "items": {
        "type": "number"
      }
    }
  }
};


export class EditRestrictionProps {
    open: boolean = true;
    restriction?: IRestriction;
    onClose?: (event: Event) => void;
    onSave?: (restriction?: IRestriction) => void;
  }

export class EditRestrictionState {
    open: boolean = true;
    restriction: IRestriction = {} as IRestriction;
  }


export function EditRestriction (props: Readonly<EditRestrictionProps>) {

    const [open,setOpen] = useState(props.open);  
    const [restriction,setRestriction] = useState(props.restriction);  
    const [bounds, setBounds] = useState(props.restriction?.bounds);  


    const { register, handleSubmit, watch, errors, setError } = useForm();
    const { t, i18n } = useTranslation();

    useEffect(() => {
      setRestriction(props.restriction);
      setBounds(undefined);
      setTimeout(()=>setBounds(props.restriction?.bounds), 100);
      setOpen(props.open);
    }, [props]);

    const onClose = (event: any) => {
      if (props.onClose) {
        props.onClose(event);
      }
    }

    const onSave = async (data: any) => {
      if (props.onSave) {
        if (restriction) {
          let newRestriction = restriction;
          if(restriction._id){
            newRestriction =  await restrictionService.updateRestriction(restriction);
          }else{
            newRestriction = await restrictionService.createRestriction(restriction);
          }
          props.onSave(newRestriction);
          return;
        }
      }

      onClose(null);
    };

         const handleChange = (
           event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
           field: string,
           fn?: (val: any) => any
         ) => {
           const newRestriction = { ...restriction } as any;
           newRestriction[field] = fn ? fn(event.target.value) : event.target.value;
           setRestriction(newRestriction);
         }


           return (
             <Dialog
               open={open}
               TransitionComponent={SlideTransition as any}
               keepMounted
               className="regular-dialog"
               onClose={(event) => onClose(event)}
             >
               {restriction ? (
                 <form
                   noValidate
                   onSubmit={handleSubmit(onSave)}
                   className="form-container"
                   autoComplete="off"
                 >
                   <DialogTitle>
                     {restriction._id ? (
                       <>
                         <Trans>Edit restriction</Trans> {restriction.name}
                       </>
                     ) : (
                       <Trans>New restriction</Trans>
                     )}
                   </DialogTitle>

                   <DialogContent>
                     <TextField
                       autoFocus
                       id="name"
                       name="name"
                       label={<Trans>Name</Trans>}
                       variant="outlined"
                       margin="normal"
                       fullWidth
                       autoComplete="off"
                       onChange={(evt) => handleChange(evt, "name")}
                       value={restriction.name || ""}
                       error={errors.name != null}
                       helperText={errors.name && t("name required")}
                       inputRef={register({ required: true })}
                     />
                    
                    <TextField
                       autoFocus
                       id="description"
                       name="description"
                       label={<Trans>Description</Trans>}
                       variant="outlined"
                       margin="normal"
                       fullWidth
                       autoComplete="off"
                       multiline={true}
                       rows={4}
                       onChange={(evt) => handleChange(evt, "description")}
                       value={restriction.description || ""}
                       error={errors.description != null}
                       helperText={errors.description && t("description required")}
                       inputRef={register({ required: true })}
                     />
                    <Box mt={2} mb={1}>
                    <FormLabel>{t('GeoJSON (Polygon and MultiPolygon)')}</FormLabel>
                    </Box>
                    <Divider></Divider>
                    {
                      bounds ?
                      <Editor
                        value={restriction.bounds}
                        ajv={ajv}
                        ace={ace}
                        allowedModes={['text', 'tree']}
                        mode={'text'}
                        onChange={(value) => handleChange({ target: { value } } as React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, "bounds")}
                        schema={schema}
                        sortObjectKeys={false}
                        theme="ace/theme/github"
                        /> : null
                      }
                   </DialogContent>

                   <DialogActions>
                     <Button type="submit" color="primary">
                       <Trans>Save</Trans>
                     </Button>
                     <Button onClick={(event) => onClose(event)}>
                       <Trans>Close</Trans>
                     </Button>
                   </DialogActions>
                 </form>
               ) : null}
             </Dialog>
           );
         
       }