import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { IYieldRegionsClient } from "../../../../../shared/models/regions";
import ZoomIn from "@material-ui/icons/ZoomIn";
import ZoomOut from "@material-ui/icons/ZoomOut";

import * as d3 from "d3";
import "d3-geo";
import "d3-transition";

import "./Map.css";
import { Slider, Grid, Tooltip } from "@material-ui/core";
import { ICulture } from "../../../../../shared/models/parcelle";
import { damageService } from "../../../services/DamageService";
import moment from "moment";

const MAX_STEPS = 15;
const MIN_STEPS = 5;

let isDragging = false;
let isMoving = false;

export interface MapProps {
  regions: IYieldRegionsClient[];
  onClickRegion?: (region: IYieldRegionsClient) => void;
  scaleFactor?: number;
  crop?: ICulture;
  fieldName?: string;
  gradientColors?: string[];
  title?: string;
  hail?: boolean;
  minMaxFactor?: "crop" | "minmax";
}

const parseFeatures = (
  regions: IYieldRegionsClient[] | any,
  fieldName: string
) => {
  let features: any = [];
  regions.forEach((region: any) => {
    features.push({
      type: "Feature",
      properties: {
        featurecla: "Country",
        scalerank: 1,
        name: region.region_id?.name,
        yield: region[fieldName],
        hail: region.hail && region.hail.length > 0 ? region.hail[0].quantity : null,
        hailData: region.hail,
      },
      geometry: region.region_id?.contour as any,
    });
  });

  return {
    type: "FeatureCollection",
    features: features,
  };
};

const rgbToHex = (r: number, g: number, b: number) => {
  return ((r << 16) | (g << 8) | b).toString(16);
};

let context: any,
  projection: any,
  geoGenerator: any = null;

const WIDTH = 600;
const HEIGHT = 600;
const barWidth = 30;
const padding = 20;

export const DEFAULT_SCALE = 2000;
export const MAX_SCALE = DEFAULT_SCALE * 20;
export const MIN_SCALE = DEFAULT_SCALE / 2;

export const Map = ({
  scaleFactor,
  regions,
  crop,
  fieldName,
  gradientColors,
  title,
  hail,
  minMaxFactor = "crop",
  onClickRegion,
}: MapProps) => {
  const { t } = useTranslation();
  const canvas = useRef<HTMLCanvasElement>(null);
  const tooltip = useRef<HTMLDivElement>(null);
  const canvasContainer = useRef<HTMLDivElement>(null);

  const [regionsState, setRegionsState] = useState<IYieldRegionsClient[]>(
    regions
  );
  const [geoData, setGeoData] = useState<any>();
  const [selectedRegion, setSelectedRegion] = useState<any>(undefined);

  const [scale, setScale] = useState<any>(DEFAULT_SCALE);
  const [width, setWidth] = useState<any>(WIDTH);

  const [minVal, setMinVal] = useState<any>(0);
  const [maxVal, setMaxVal] = useState<any>(100);
  const [damage, setDamage] = useState<any>();
  const [selectedCrop, setSelectedCrop] = useState<ICulture>(crop as ICulture);


  const [hailEnabled, setHailEnabled] = useState<boolean>(hail || false);


  const [offset, setOffset] = useState([0, 0]);
  const lastPosition = useRef({ x: 0, y: 0 });

  // Función para obtener la posición del mouse o del toque
  const getPosition = (event: any) => {
    if (event.touches && event.touches.length > 0) {
      return { x: event.touches[0].clientX, y: event.touches[0].clientY };
    }
    return { x: event.clientX, y: event.clientY };
  };

  const handleStart = (event: any) => {
    isDragging = true;
    isMoving = true;
    if (tooltip.current) {
      tooltip.current.style.display = "none";
    }
    const pos = getPosition(event);
    lastPosition.current = { x: pos.x, y: pos.y };
  };

  const handleMove = (event: any) => {
    if (isMoving) {
      const pos = getPosition(event);
      const dx = pos.x - lastPosition.current.x;
      const dy = pos.y - lastPosition.current.y;
      setOffset([offset[0] + dx, offset[1] + dy]);
      lastPosition.current = { x: pos.x, y: pos.y };
    }
  };

  const handleEnd = () => {
    isMoving = false;
    setTimeout(() => {
      isDragging = false;
    }, 100);
  };

  // Manejador para el zoom con la rueda del ratón
  const handleWheel = (event: any) => {
    const scaleChange = event.deltaY * -0.005; // Ajustar este factor según sea necesario
    const newScale = Math.max(
      MIN_SCALE,
      Math.min(MAX_SCALE, scale + scale * scaleChange)
    );
    updateScale(newScale);

    event.preventDefault();
    event.stopPropagation();
    return false;
  };

  const getFeatureColor = (feature: any) => {
    const min = minVal;
    const max = maxVal;

    const total = max - min;
    const STEPS = total < 10 ? MIN_STEPS : MAX_STEPS;

    const refineSteps = STEPS * 3;

    const step = total / refineSteps;
    let y = HEIGHT - padding;
    let heightStep = (HEIGHT - padding * 2) / refineSteps;

    let val = y;

    for (let i = min, j = 0; i < feature.properties.yield; i += step, j++) {
      val = y - heightStep * j;
      //console.log(feature.properties.name, val);
    }

    if (val <= padding) val = padding;
    if (val >= max) val -= 5;

    let p = context.getImageData(width - padding - barWidth, val, 1, 1).data;

    /*console.log(
      feature.properties.name,
      feature.properties.yield,
      WIDTH - padding - barWidth + 10,
      val
    );*/

    /* context.beginPath();
    context.arc(
      WIDTH - padding - barWidth + 10,
      val,
      10,
      0,
      2 * Math.PI,
      false
    );
    context.fillStyle = "black";
    context.fill();*/

    /* context.font = "9px Arial";
    context.fillStyle = "black";
    context.fillText(
      feature.properties.name + ": " + feature.properties.yield,
      100,
      val
    );*/

    if (
      p[0] === 0 &&
      p[1] === 0 &&
      p[3] === 0 &&
      gradientColors &&
      gradientColors.length > 0
    ) {
      return gradientColors[0];
    } else {
      return "#" + ("000000" + rgbToHex(p[0], p[1], p[2])).slice(-6);
    }
  };

  const render = () => {
    context.clearRect(0, 0, width, HEIGHT);
    if (geoData && geoData.features && geoData.features.length > 0) {
      renderBar();
      renderDamageBar(damage);

      geoData.features.forEach((d: any) => {

        let color = d.properties.yield < 0 || d.properties.yield === undefined ? "#CCC" : getFeatureColor(d); //Math.floor(Math.random() * 16777215).toString(16);

        if(hailEnabled && damage && d.properties.hail ){
          color = getDamageColor(d.properties.hail);
        }

        context.beginPath();
        context.strokeStyle = "#fff";
        context.lineWidth = 1;
        context.fillStyle = color; //state.clickedLocation && d3.geoContains(d, state.clickedLocation) ? 'red' : '#aaa';
        geoGenerator(d);
        context.fill();
        context.stroke();
        context.clearRect(width - 50, 0, width, HEIGHT);

        //console.log("RENDER");
        renderDamageBar(damage);
        renderBar();

      });


      if(hailEnabled && damage){
        renderDamageBar(damage);
      }
      renderBar();

    }
  };

  const renderBar = () => {
    let min_step = minVal;
    let max_step = maxVal;
    let min_av = selectedCrop && selectedCrop.min_yield_av ? selectedCrop.min_yield_av : 20;
    let max_av = selectedCrop && selectedCrop.max_yield_av ? selectedCrop.max_yield_av : 80;


    const gradient = context.createLinearGradient(
      0,
      HEIGHT - padding * 2,
      0,
      0
    );

    if (gradientColors && gradientColors.length > 1) {
      gradientColors.forEach((color, index) => {
        gradient.addColorStop(index / gradientColors.length, color);
      });
    } else {
      const max = max_av / max_step + 0.05;
      const min = min_av / max_step - 0.05;

      gradient.addColorStop(1, "#005000");
      gradient.addColorStop(max > 1 ? 1 : max, "#005000");
      gradient.addColorStop(0.5, "#FFFF00");
      gradient.addColorStop(min < 0 ? 0 : min, "#FF0000");
      gradient.addColorStop(0, "#FF0000");
    }

    context.fillStyle = gradient;
    context.fillRect(
      width - padding * 2 - barWidth,
      padding,
      barWidth,
      HEIGHT - padding * 2
    );

    const min = min_step;
    /*Math.floor(
        Math.min(...geoData.features.map((f: any) => f.properties.yield))
      ) - 1;*/
    const max = max_step; /*
      Math.floor(
        Math.max(...geoData.features.map((f: any) => f.properties.yield))
      ) + 0.5;*/

    if (min !== Infinity && max !== Infinity) {
      const total = max - min;
      const STEPS = total < 10 ? MIN_STEPS : MAX_STEPS;

      const step = total / STEPS;
      let y = HEIGHT - padding;
      let heightStep = (HEIGHT - padding * 2) / STEPS;

      const x = width - padding * 1.5;
      for (let i = min, j = 0; j <= STEPS; i += step, j += 1) {
        context.font = "11px Arial";
        context.fillStyle = "black";
        context.fillText(Math.floor(i), x, y - heightStep * j);
      }
    }
  };


  const renderDamageBar = (data: any): void => {
    if (data) {
      const { min, max, minColor, maxColor } = data;
  
      // Reducir la altura de la barra y ajustar el padding para los números
      const reducedBarHeight = barWidth - 10; // Reduce la altura de la barra para dejar espacio para los números
      const numberPadding = 10; // Espacio adicional para los números debajo de la barra
      const extraPadding = padding * 1.1;

      context.clearRect(
        0, // x inicial
        HEIGHT - reducedBarHeight - padding - numberPadding - extraPadding, // y inicial, ajustado para cubrir el área completa
        width, // ancho del área a borrar
        reducedBarHeight + padding + numberPadding + extraPadding // altura del área a borrar
      );

      const gradient = context.createLinearGradient(
        padding, 0,
        width - padding * 3 - barWidth, 0 // Acorta el ancho de la barra para no solapar la barra vertical
      );
  
      gradient.addColorStop(0, minColor);
      gradient.addColorStop(1, maxColor);
  
      context.fillStyle = gradient;
      // Dibuja la barra ajustada en la parte inferior del canvas
      context.fillRect(
        padding,
        HEIGHT - reducedBarHeight - padding - numberPadding, // Ajusta para que la barra quede en la parte inferior y deje espacio para los números
        width - extraPadding * 3 - barWidth, // Acorta el ancho para evitar solapamiento con la barra vertical
        reducedBarHeight
      );
  
      const total = max - min;
      let STEPS = Math.min(Math.ceil(total), MAX_STEPS);
      if (STEPS < MIN_STEPS) STEPS = MIN_STEPS;
  
      const stepValue = total / (STEPS - 1);
      const widthStep = (width - extraPadding * 3 - barWidth) / (STEPS - 1) - 0.4;
      // Ajusta la posición y para las etiquetas, colocándolas debajo de la barra ajustada
      const y = HEIGHT - padding;
  
      let index = 0;
      for (let i = 0; i < STEPS; i++) {
        const value = min + stepValue * i;
        context.font = "11px Arial";
        context.fillStyle = "black";
        // Ajusta la posición del texto para centrar, considerando la longitud del texto
        context.fillText(Math.floor(value).toString(), padding + widthStep * index - context.measureText(Math.floor(value).toString()).width / 2, y);
        index++;
      }
    }
  };
  
  const getDamageColor = (val: any) => {
    const min = damage.min;
    const max = damage.max;
  
    const value = val > max ? max : val < min ? min : val;
  
    // Calcular la proporción del valor dentro del rango [min, max]
    const proportion = (value - min) / (max - min);
  
    // Calcular la posición x del pixel basada en la proporción
    const extraPadding = padding * 1.1; // Asumiendo que se usa el mismo padding que en renderDamageBar
    const pixelX = padding + (width - extraPadding * 3 - barWidth) * proportion;
  
    // Usar una posición y que esté dentro de la altura de la barra
    const y = HEIGHT - barWidth - padding + 5; // Ajustar según la altura configurada de la barra en renderDamageBar
  
    // Obtener el color del pixel en esa posición
    let p = context.getImageData(pixelX, y, 1, 1).data;


    /*context.beginPath();
    context.arc(
      pixelX,
      y,
      10,
      0,
      2 * Math.PI,
      false
    );
    context.fillStyle = "black";
    context.fill();*/
  
    // Convertir los valores RGB a hexadecimal
    return "#" + ("000000" + rgbToHex(p[0], p[1], p[2])).slice(-6);
  };
  

const reloadHail =  async (enabled: boolean) => {
  if (enabled) {
    const d = await damageService.getDamage("hail");
    setDamage(d);
    setHailEnabled(true);
  }else{
    setHailEnabled(false);
    setDamage(undefined);

  }
};

  const updateScale = (newValue: any) => {
    setScale(newValue);
  };

  useEffect(() => {
    setSelectedCrop(crop as ICulture);
  }, [crop]);

  useEffect(() => {
    switch (minMaxFactor) {
      case "minmax":
        if (geoData && geoData.features && geoData.features.length > 0) {
          const min =  Math.min(...geoData.features.filter((f:any) => f.properties.yield !== undefined).map((f: any) => f.properties.yield));
          const max = Math.max(...geoData.features.filter((f:any) => f.properties.yield !== undefined).map((f: any) => f.properties.yield));
          setMinVal(min === max ? 0 : min);
          setMaxVal(max);
        }
        break;
      case "crop":
      default:
        setMinVal(selectedCrop && selectedCrop.min_yield ? selectedCrop.min_yield : 0);
        setMaxVal(selectedCrop && selectedCrop.max_yield ? selectedCrop.max_yield : 100);

        break;
    }
  }, [minMaxFactor, geoData, selectedCrop]);

  useEffect(() => {
    setScale(scaleFactor);
  }, [scaleFactor]);




  useEffect(() => {
    setRegionsState(regions);
  }, [regions]);

  useEffect(() => {
    setGeoData(parseFeatures(regionsState, fieldName || "yield_av"));
  }, [regionsState, fieldName]);



  useEffect(() => {
    if (projection && projection.translate) {
      projection.translate([width / 2 + offset[0], HEIGHT / 2 + offset[1]]);
      render();
    }
  }, [offset, width, HEIGHT, projection, render]);



  /* useEffect(() => {
    const resizeEvent = () => {
      if (canvasContainer && canvasContainer.current) {
        setWidth(canvasContainer.current?.clientWidth);
        setTimeout(() => render(), 500);
      }
    };

    window.addEventListener("resize", resizeEvent);

    return () => {
      window.removeEventListener("resize", resizeEvent);
    };
  }, []);*/



  
  useEffect(() => {
    if (canvas.current != null && geoData) {
      const center = d3.geoCentroid(geoData);
      const currentScale = scale;
      const offset = [width / 2, HEIGHT / 2];

      //console.log("currentScale", currentScale);

      context = canvas.current.getContext("2d");
      /*      let latitude = 3;
      let longitude = 46.5;

      if (scale > DEFAULT_SCALE) {
        latitude = (latitude * scale) / DEFAULT_SCALE;
        longitude = (longitude * scale) / DEFAULT_SCALE;
        console.log(latitude, longitude);
      }*/
      projection = d3
        .geoMercator()
        .scale(currentScale)
        .center(center)
        .translate(offset as any);

      geoGenerator = d3
        .geoPath()
        .projection(projection)
        .context(context);

      let isInner = false;
      let lastTimeout: any = null;
      d3.select(canvas.current)
        .on("wheel", handleWheel)
        .on("mousemove", null)
        .on("mousemove", (event) => {
          if (isDragging) return;
          var pos = projection.invert(d3.pointer(event));

          geoData.features.forEach((d: any) => {
            if (d3.geoContains(d, pos)) {
              clearTimeout(lastTimeout);
              setSelectedRegion(d);
              isInner = true;

              if (tooltip.current) {
                tooltip.current.style.display = "block";

                var x = event.clientX;
                var y = event.clientY;
                let yTooltip = y - 100;
                if(yTooltip < 100 || (d.properties.hail && hailEnabled)) yTooltip = y + 30;
                tooltip.current.style.top =  yTooltip + "px";
                tooltip.current.style.left = x - 80 + "px";
                //console.log("event", d.properties.name);
              }
            } else {
              //console.log("event", d.properties.name);

              isInner = false;
              clearTimeout(lastTimeout);
              lastTimeout = setTimeout(() => {
                if (!isInner) {
                  if (tooltip.current) {
                    tooltip.current.style.display = "none";
                  }
                }
              }, 800);
            }
          });
        })
        .on("mouseout", null)
        .on("mouseout", () => {
          if (tooltip.current) {
            tooltip.current.style.display = "none";
          }
        });

      render();
    }
  }, [canvas, scale, geoData, selectedCrop]);


  useEffect(() => {
    reloadHail(hail || false);
  }, [hail]);

  useEffect(() => {
    if (geoData) render();
  }, [geoData, damage, selectedCrop]);
  return (
    <>
      <div id="tooltip" ref={tooltip}>
        <div className="tooltitle">{title}</div>
        <span>
          {selectedRegion?.properties.name}:{" "}
          <b>
            {selectedRegion?.properties.yield > -1
              ? Math.floor(selectedRegion?.properties.yield)
              : "NO_DATA"}
          </b>
        </span>
        {hailEnabled && selectedRegion?.properties.hail > 0 && (
           <div>
              <hr></hr>
              <div>
              {selectedRegion?.properties.hailData.map((h:any) => (
                <div key={h.date}>
                 {t('Hail') + ': '} <b>{Math.ceil(h.quantity)} mm</b> - <b>{moment(h.date).format('DD/MM/YYYY')}</b>
                </div>
              ))}
              </div>
            </div>
            )}
          </div>
          <div id="geo-map">
            <Grid container direction="row" alignItems="center">
              <Grid id="controls" item>
                <Grid container direction="column" alignItems="center">
                  <Grid item>
                    <ZoomIn />
                  </Grid>
                  <Grid item>
                    <Slider
                      aria-label="Small steps"
                      style={{ height: HEIGHT - 80 }}
                      step={100}
                      min={MIN_SCALE}
                      max={MAX_SCALE}
                      value={scale}
                      onChange={(ev, newValue) => updateScale(newValue)}
                      orientation="vertical"
                    />
                  </Grid>
                  <Grid item>
                    <ZoomOut />
                  </Grid>
                </Grid>
              </Grid>
              <Grid style={{ flex: 1 }} ref={canvasContainer} item>
                <canvas
                  id="canvas"
                  ref={canvas}
                  width={width}
                  height={HEIGHT}
                  onMouseDown={handleStart}
                  onMouseMove={handleMove}
                  onMouseUp={handleEnd}
                  onMouseOut={handleEnd}
                  onTouchStart={handleStart}
                  onTouchMove={handleMove}
                  onTouchEnd={handleEnd}
                ></canvas>
                { damage && damage.name && (
                  <div className="AxisDamageLegend">{damage.name} mm</div>
                )}
              </Grid>
            </Grid>
            <div className="AxisLegend">{title}</div>
          </div>
    </>
  );
};
