import { BaseService } from "./BaseService";
import { IParcelle, IHisto, IExplotation, ICulture } from "../../../shared/models/parcelle";
import { IComment } from "../../../shared/models/comment";
import { AdminDataSet, AdminQuery, CultureQuery  } from "../../../shared/models/admin";

export class CultureService extends BaseService {

    getCultures(userQuery?: CultureQuery) {
        const params = userQuery ? JSON.stringify(userQuery) : null;
        console.log(userQuery, params)
        return this.get<AdminDataSet<ICulture>>('cultures',{filter: params});
    }

    createCulture(culture:ICulture){
        return this.post<ICulture>('cultures', culture);
    }

    updateCulture(culture: ICulture) {
        return this.put<ICulture>('cultures', culture);

    }

    deleteCulture(culture: ICulture) {
        return this.delete<ICulture>(`cultures/${culture._id}`);
    }

  
}

export const cultureService = new CultureService();