import { BaseService } from "./BaseService";
import { IDamage } from "../../../shared/models/damage";
import { AdminDataSet, AdminQuery  } from "../../../shared/models/admin";

export class DamageService extends BaseService {

    getDamages(userQuery?: AdminQuery) {
        const params = userQuery ? JSON.stringify(userQuery) : null;
        console.log(userQuery, params)
        return this.get<AdminDataSet<IDamage>>('damages',{filter: params});
    }

    createDamage(damage:IDamage){
        return this.post<IDamage>('damages', damage);
    }

    updateDamage(damage: IDamage) {
        return this.put<IDamage>('damages', damage);

    }

    deleteDamage(damage: IDamage) {
        return this.delete<IDamage>(`damages/${damage._id}`);
    }


    getDamage(code: string){
        return this.get<IDamage>(`damages/${code}`);
    }
  
}

export const damageService = new DamageService();