import React, { Component } from "react"; // let's also import Component
import {
  Dialog,
  Slide,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Button,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  TablePagination,
  IconButton,
  Icon,
} from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";
import { Trans } from "react-i18next";
import { Comment } from "./Comment.model";
import "moment-timezone";
import Moment from "react-moment";
import { SlideTransition } from "../Util/Animations";
import { IHisto, IParcelle } from "../../../../shared/models/parcelle";
import moment from "moment";
import { userService } from "../../services/UserService";
import { IComment } from "../../../../shared/models/comment";

export class CommentsProps {
  open: boolean = true;
  reload: boolean = false;
  parcele?: IParcelle;
  currentHisto?: IHisto;
  histoIndex: number = 0;
  histo?: IHisto[];
  comments?: IComment[];
  onNextHisto?: (event: Event) => void;
  onPrevHisto?: (event: Event) => void;
  onLoadComments?: (comments: IComment[], histos: IHisto[]) => void;
  onSelectHisto?: (histo_id: String, view_comment: boolean) => void;
  onEditComment?: (comment: IComment) => void;
  onClose?: (event: Event) => void;
}

export class CommentsState {
  open: boolean = false;
  loading = false;
  rows: IComment[] = [];
  parcele?: IParcelle;
}

export class Comments extends Component<CommentsProps, CommentsState> {
  state = new CommentsState();

  static getDerivedStateFromProps(props: CommentsProps, state: CommentsState) {
    return {
      ...state,
      open: props.open,
      rows: props.comments ? props.comments : [],
      /*props.currentHisto && props.currentHisto.comments
                 ? props.currentHisto.comments
                 : [],*/
    };
  }

  componentDidUpdate(prevProps: CommentsProps) {
    if (this.props.reload && !prevProps.reload) {
      this.loadComments();
    }
  }

  loadCommentsToHistos(comments: IComment[]) {
    let histos = this.props.histo;
    if (histos && histos.length > 0) {
      histos.forEach((h) => {
        h.comments = [];
        comments.forEach((comm) => {
          if (comm.histo.toString() == h._id) {
            h.comments?.push(comm);
          }
        });
      });
    }
    return histos;
  }

  async loadComments() {
    if (this.props.parcele) {
      if (this.props.parcele.name) {
        this.setState({ ...this.state, loading: true });
        const all_comments = await userService.getAllComments(
          this.props.parcele.name
        );
        const histos = this.loadCommentsToHistos(all_comments);
        this.setState({ ...this.state, loading: false, rows: all_comments });
        if (this.props.onLoadComments && histos) {
          this.props.onLoadComments(all_comments, histos);
        }
      }
    }
  }

  onClose(event: any) {
    if (this.props.onClose) {
      this.props.onClose(event);
    }
  }

  prevHisto(event: any) {
    if (this.props.onPrevHisto) {
      this.props.onPrevHisto(event);
    }
  }

  nextHisto(event: any) {
    if (this.props.onNextHisto) {
      this.props.onNextHisto(event);
    }
  }

  selectHisto(row: IComment) {
    if (this.props.onSelectHisto) {
      this.props.onSelectHisto(row.histo.toString(), false);
    }
  }

  editComment = async (row: IComment) => {
    if (this.props.onEditComment) {
      this.props.onEditComment(row);
    }
  };

  deleteComment = async (row: IComment) => {
    await userService.deleteComment(row);
    this.loadComments();
  };

  // render will know everything!
  render() {
    return (
      <Dialog
        className="dialog-bottom"
        open={this.state.open}
        TransitionComponent={SlideTransition as any}
        keepMounted
        onClose={(event) => this.onClose(event)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        {this.props.currentHisto && this.props.histo ? (
          <DialogTitle>
            <span className="d-title">
              <Trans>Comments in Parcel</Trans> {this.props.parcele?.name}{" "}
              <Trans>at date:</Trans>
            </span>
            <IconButton
              disabled={this.props.histoIndex >= this.props.histo.length - 1}
              onClick={(evt) => this.prevHisto(evt)}
            >
              <Icon>keyboard_arrow_left</Icon>
            </IconButton>
            <Moment
              format="DD/MM/YYYY"
              style={{ fontSize: 14 }}
              date={moment((this.props.currentHisto as any).createdAt)}
            />
            <IconButton
              disabled={this.props.histoIndex == 0}
              onClick={(evt) => this.nextHisto(evt)}
            >
              <Icon>keyboard_arrow_right</Icon>
            </IconButton>
          </DialogTitle>
        ) : null}

        <DialogContent>
          {this.state.rows.length ? (
            <TableContainer>
              <Table stickyHeader aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Trans>Polygon</Trans>
                    </TableCell>
                    <TableCell>
                      <Trans>Date</Trans>
                    </TableCell>

                    <TableCell align="center">
                      <Trans>Surface</Trans>
                    </TableCell>
                    <TableCell>
                      <Trans>Weather event</Trans>
                    </TableCell>
                    <TableCell>
                      <Trans>Estimated yield</Trans>
                    </TableCell>
                    <TableCell>
                      <Trans>Actual yield</Trans>
                    </TableCell>
                    <TableCell>
                      <Trans>Comments</Trans>
                    </TableCell>
                    <TableCell align="center">
                      <Trans>Peek</Trans>
                    </TableCell>
                    <TableCell align="center">&nbsp;</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.rows.map((row: IComment) => (
                    <TableRow key={row._id}>
                      <TableCell>{row.label ? row.label : "-"}</TableCell>

                      <TableCell component="th" scope="row">
                        <Moment
                          format="DD/MM/YYYY HH:mm"
                          date={row.createdAt}
                        />
                      </TableCell>
                      <TableCell align="center">
                        {row.surface
                          ? (row.surface / 10000).toFixed(2) + " (ha)"
                          : "-"}
                      </TableCell>
                      <TableCell>
                        {row.damage ? row.damage.name : "-"}
                      </TableCell>
                      <TableCell>{row.estimated_return}</TableCell>
                      <TableCell>{row.actual_return}</TableCell>
                      <TableCell>{row.comment}</TableCell>
                      <TableCell align="center">
                        <IconButton onClick={(event) => this.selectHisto(row)}>
                          <Icon>gps_fixed</Icon>
                        </IconButton>
                        <IconButton onClick={(event) => this.editComment(row)}>
                          <Icon>edit</Icon>
                        </IconButton>
                      </TableCell>
                      <TableCell align="center">
                        <IconButton
                          onClick={() => this.deleteComment(row)}
                          color="secondary"
                          component="span"
                        >
                          <Icon>delete</Icon>
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : this.state.loading ? (
            <p>
              <Trans>Loading...</Trans>
            </p>
          ) : (
            <p>
              <Trans>This parcelle hasn't comments at this date</Trans>
            </p>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={(event) => this.onClose(event)} color="primary">
            <Trans>Close</Trans>
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
