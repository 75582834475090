import React, { useEffect, useRef, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { TextField, makeStyles, createStyles, Theme } from "@material-ui/core";
import { Link, Redirect, useHistory, useParams } from "react-router-dom";
import { SlideTransition } from "../Util/Animations";
import { authService } from "../../services/AuthService";
import { useForm } from "react-hook-form";
import { AlertDialog, AlertDialogProps } from "../Dialogs/AlertDialog";
import { useTranslation, Trans } from "react-i18next";
import isCordova, { Keyboard } from "../Util/isCordova";
import isMobileWidth from "../Util/isMobileWidth";
import userEvent from "@testing-library/user-event";
import { IUser } from "../../../../shared/models/user";
import { yieldRegionService } from "../../services/YieldRegionService";

function Impersonate() {
  let { token } = useParams<{ token: string }>();

  const [user, setUser] = useState<IUser | undefined>();
  const [url, setUrl] = useState<string>("/my-position");

  const loadToken = async () => {
    const u = await authService.impersonate(token);
    if (u.role.includes("head")) {
      setUrl("/yieldregions");
    } else if (u.role.includes("admin")) {
      setUrl("/users");
    } else {
      setUrl("/my-position");
    }

    setUser(u);
  };

  useEffect(() => {
    yieldRegionService.resetFilters();

    if (token) {
      loadToken();
    }
  }, [token]);

  return user ? <Redirect to={url} /> : <></>;
}

export default Impersonate;
