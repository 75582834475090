import React, { Component, useState, useEffect } from "react"; // let's also import Component
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  DialogTitle,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  FormHelperText,
  ListItemText,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@material-ui/core";
import moment from "moment";
import "moment-timezone";
import { SlideTransition } from "../Util/Animations";
import MomentUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { Trans, useTranslation } from "react-i18next";
import { userService } from "../../services/UserService";
import { IUser } from "../../../../shared/models/user";
import { Controller, useForm } from "react-hook-form";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { ICulture, IRestriction } from "../../../../shared/models/parcelle";
import { restrictionService } from "../../services/RestrictionService";
import { yieldRegionService } from "../../services/YieldRegionService";
import { setSyntheticLeadingComments } from "typescript";
import { IClient } from "../../../../shared/models/regions";

export class EditUserProps {
  open: boolean = true;
  user?: IUser;
  onClose?: (event: Event) => void;
  onSave?: (user?: IUser) => void;
}

export class EditUserState {
  open: boolean = true;
  user: IUser = { role: ["user"] } as IUser;
}

export const createDefaultUser = (): IUser => {
  return {
    name: null,
    email: null,
    password: null,
    company: null,
    clients: [],
    cultures: [],
    views: [],
    role: ["user"],
    surfaceYieldLimit: 0,
    surfaceLimit: 0,
  } as any;
};

export const UserRoles = ["user", "head", "admin", "manager"];
export const Views = ["E1", "E2", "E3", "E4"];

export function EditUser(props: Readonly<EditUserProps>) {
  const [open, setOpen] = useState(props.open);
  const [user, setUser] = useState(
    props.user ? props.user : createDefaultUser()
  );
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(
    new Date()
  );

  const [restrictions, setRestrictions] = React.useState<IRestriction[]>([]);
  const [clients, setClients] = React.useState<IClient[]>([]);
  const [cultures, setCultures] = React.useState<ICulture[]>([]);

  const [fromDate, setFromDate] = React.useState<Date | null>(null);
  const [toDate, setToDate] = React.useState<Date | null>(null);

  const [years, setYears] = React.useState<number[]>([]);

  const fillYears = async () => {
    const {year} = await yieldRegionService.getMinYear();
    setYears(yieldRegionService.getYears(year));
  }

  const handleDateChange = (
    date: MaterialUiPickersDate,
    value?: string | null | undefined
  ) => {
    if (date) {
      const newDate = date.toDate();
      setSelectedDate(newDate);
      setUser({ ...user, expiresIn: newDate } as IUser);
    }
  };

  const handleFromDateChange = (
    date: MaterialUiPickersDate,
    value?: string | null | undefined
  ) => {
    if (date) {
      const newDate = date.toDate();
      setFromDate(newDate);
      setUser({ ...user, startDate: newDate } as IUser);
    } else if (date === null) {
      setFromDate(null);
      setUser({ ...user, startDate: null } as any);
    }
  };

  const handleToDateChange = (
    date: MaterialUiPickersDate,
    value?: string | null | undefined
  ) => {
    if (date) {
      const newDate = date.toDate();
      setToDate(newDate);
      setUser({ ...user, endDate: newDate } as IUser);
    } else if (date === null) {
      setToDate(null);
      setUser({ ...user, endDate: null } as any);
    }
  };

  const handleChangeRestriction = (event: any) => {
    const value = event.target.value?.map((x: any) =>
      typeof x === "string" ? x : x._id
    );
    if (value) {
      setUser({ ...user, restriction: value } as any);
    } else {
      setUser({ ...user, restriction: undefined } as any);
    }
  };

  const handleChangeRole = (event: any) => {
    const value = event.target.value;
    const cli = value.includes("head") ? clients.map((c) => c._id) : [];
    if (value) {
      setValue("role", value);
      setUser({ ...user, role: value, clients: cli } as any);
    } else {
      setUser({ ...user, role: undefined, clients: cli } as any);
      setValue("role", undefined);
    }
  };

  const handleChangeYear = (event: any) => {
    const value = event.target.value;
    if (value) {
      setUser({ ...user, years: value } as any);
    } else {
      setUser({ ...user, years: undefined } as any);
    }
  };

  const handleChangeClient = (event: any) => {
    const value = event.target.value;
    if (value) {
      setUser({ ...user, clients: value } as any);
    } else {
      setUser({ ...user, clients: [] } as any);
    }
  };

  const handleChangeCulture = (event: any) => {
    const value = event.target.value;
    if (value) {
      setUser({ ...user, cultures: value } as any);
    } else {
      setUser({ ...user, cultures: [] } as any);
    }
  };

  const handleChangeView = (event: any) => {
    const value = event.target.value;
    if (value) {
      setUser({ ...user, views: value } as any);
    } else {
      setUser({ ...user, views: [] } as any);
    }
  };

  const handleChangeExport = (event: any) => {
    setUser({ ...user, exportCSV: event.target.checked } as any);
  };

  const {
    register,
    handleSubmit,
    watch,
    errors,
    setError,
    reset,
    triggerValidation,
    setValue,
    control,
  } = useForm();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    setUser(createDefaultUser());
    setTimeout(() => {
      if (
        (props.user && props.user?.role === undefined) ||
        props.user?.role === null ||
        props.user?.role?.length === 0
      ) {
        props.user.role = ["user"];
      }
      setUser(props.user as IUser);
    }, 100);

    setOpen(props.open);
    if (props.user?.expiresIn) {
      setSelectedDate(moment(props.user.expiresIn).toDate());
    }
    if (props.user?.startDate) {
      setFromDate(moment(props.user.startDate).toDate());
    }
    if (props.user?.endDate) {
      setToDate(moment(props.user.endDate).toDate());
    }
  }, [props]);

  const loadClients = async () => {
    const cs = await yieldRegionService.getClients();
    setClients(cs);
  };

  const loadCultures = async () => {
    const cs = await userService.getCultures();
    setCultures(cs);
  };

  const loadRestrictions = async () => {
    const r = await restrictionService.getRestrictions({
      limit: 1000000000,
      skip: 0,
    });
    setRestrictions(r.items);
  };

  useEffect(() => {
    setValue("name", user?.name || "");
    setValue("company", user?.company || "");
    setValue("email", user?.email || "");
    setValue("password", user?.password || "");
    setValue("surfaceLimit", user?.surfaceLimit);
    setValue("surfaceYieldLimit", user?.surfaceYieldLimit);
    setValue("role", user?.role || ["user"]);
    if (user?._id) {
      triggerValidation();
    } else {
      reset();
    }
  }, [user]);

  useEffect(() => {
    loadClients();
    loadCultures();
    loadRestrictions();
    fillYears();
  }, []);

  const onClose = (event: any) => {
    if (props.onClose) {
      props.onClose(event);
    }
  };

  const onSave = async (data: any) => {
    if (props.onSave) {
      if (user) {
        let newUser = user;
        if (user._id) {
          newUser = await userService.updateUser(user);
        } else {
          newUser = await userService.createUser(user);
        }
        props.onSave(newUser);
        return;
      }
    }

    onClose(null);
  };

  const handleChange = (ev: any, field: string, fn?: (val: any) => any) => {
    const event = ev.nativeEvent ? ev : ev[0];
    const newUser = { ...user } as any;
    newUser[field] = fn ? fn(event.target.value) : event.target.value;
    setUser(newUser);
    setValue(field, newUser[field]);
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={SlideTransition as any}
      keepMounted
      className="regular-dialog"
      onClose={(event) => onClose(event)}
    >
      {user ? (
        <form
          noValidate
          onSubmit={handleSubmit(onSave)}
          className="form-container"
          autoComplete="off"
        >
          <DialogTitle>
            {user._id ? (
              <>
                <Trans>Edit user</Trans> {user.email}
              </>
            ) : (
              <Trans>New user</Trans>
            )}
          </DialogTitle>

          <DialogContent>
            <Grid container spacing={3}>
              <Grid
                item
                xs={12}
                sm={user.role && user.role.includes("head") ? 6 : 12}
              >
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="role-label">{t("Role")}</InputLabel>
                  <Select
                    labelId="role-label"
                    id="role"
                    value={user.role}
                    label={t("Role")}
                    multiple={true}
                    renderValue={(selected) =>
                      (selected as string[]).join(", ")
                    }
                    onChange={handleChangeRole}
                  >
                    {UserRoles.map((role) => (
                      <MenuItem key={role} value={role}>
                        <Checkbox
                          checked={
                            user && user.role && user.role.includes(role)
                          }
                        />

                        <ListItemText primary={t(role)} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {user.role && user.role.includes("head") ? (
                <Grid item xs={12} sm={6}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="client-label">{t("Client")}</InputLabel>
                    <Select
                      labelId="client-label"
                      id="client"
                      value={user.clients || []}
                      label={t("Client")}
                      multiple={true}
                      onChange={handleChangeClient}
                      renderValue={(selected: any) =>
                        selected
                          .map((x: any) => {
                            const client = clients.find((c) => c._id === x);
                            return client?.name;
                          })
                          .join(", ")
                      }
                      error={errors.clients != null}
                    >
                      {clients.map((client) => (
                        <MenuItem key={client._id} value={client._id}>
                          <Checkbox
                            checked={
                              user &&
                              user.clients &&
                              user.clients?.indexOf(client._id as any) > -1
                            }
                          ></Checkbox>
                          <ListItemText primary={client.name} />
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.clients ? (
                      <FormHelperText>
                        {t("You must select one client unleast")}
                      </FormHelperText>
                    ) : null}
                  </FormControl>
                </Grid>
              ) : null}
            </Grid>
            {user.role && user.role.includes("head") ? (
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="culture-label">{t("Culture")}</InputLabel>
                    <Select
                      labelId="culture-label"
                      id="culture"
                      value={user.cultures || []}
                      label={t("Culture")}
                      multiple={true}
                      onChange={handleChangeCulture}
                      renderValue={(selected: any) =>
                        selected
                          .map((x: any) => {
                            const culture = cultures.find((c) => c._id === x);
                            return culture?.name;
                          })
                          .join(", ")
                      }
                      error={errors.cultures != null}
                    >
                      {cultures.map((culture) => (
                        <MenuItem key={culture._id} value={culture._id}>
                          <Checkbox
                            checked={
                              user &&
                              user.cultures &&
                              user.cultures?.indexOf(culture._id as any) > -1
                            }
                          ></Checkbox>
                          <ListItemText primary={culture.name} />
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.cultures ? (
                      <FormHelperText>
                        {t("You must select one culture unleast")}
                      </FormHelperText>
                    ) : null}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="vue-label">{t("Views")}</InputLabel>
                    <Select
                      labelId="vue-label"
                      id="views"
                      value={user.views || []}
                      label={t("Views")}
                      multiple={true}
                      onChange={handleChangeView}
                      error={errors.views != null}
                      renderValue={(selected: any) => selected.join(", ")}
                    >
                      {Views.map((view) => (
                        <MenuItem key={view} value={view}>
                          <Checkbox
                            checked={
                              user &&
                              user.views &&
                              user.views?.indexOf(view) > -1
                            }
                          ></Checkbox>
                          <ListItemText primary={view} />
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.clients ? (
                      <FormHelperText>
                        {t("You must select one view unleast")}
                      </FormHelperText>
                    ) : null}
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={handleChangeExport}
                          checked={user.exportCSV === true}
                        />
                      }
                      label={t("Can export to CSV")}
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="role-label">{t("Year")}</InputLabel>
                    <Select
                      labelId="year-label"
                      id="year"
                      value={user.years || []}
                      label={t("Year")}
                      multiple={true}
                      renderValue={(selected) =>
                        (selected as number[]).join(", ")
                      }
                      onChange={handleChangeYear}
                    >
                      {years.map((year) => (
                        <MenuItem key={year} value={year}>
                          <Checkbox
                            checked={
                              user && user.years && user.years.includes(year)
                            }
                          />

                          <ListItemText primary={year} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            ) : null}

            <Controller
              as={TextField}
              control={control}
              defaultValue=""
              rules={{ required: true }}
              autoFocus
              id="name"
              name="name"
              label={<Trans>Name</Trans>}
              variant="outlined"
              margin="normal"
              fullWidth
              autoComplete="off"
              onChange={(evt) => handleChange(evt, "name")}
              error={!!errors.name}
              helperText={errors.name && t("name required")}
              InputLabelProps={{ shrink: user.name != null }}
            />

            <Controller
              as={TextField}
              control={control}
              autoFocus
              id="company"
              name="company"
              label={<Trans>Enterprise</Trans>}
              variant="outlined"
              margin="normal"
              fullWidth
              autoComplete="off"
              onChange={(evt) => handleChange(evt, "company")}
              error={errors.company != null}
              helperText={errors.company && t("a valid enterprise required")}
              rules={{ minLength: 3 }}
              InputLabelProps={{ shrink: user.company != null }}
            />
            <Controller
              as={TextField}
              control={control}
              id="email"
              label={<Trans>Email</Trans>}
              name="email"
              type="email"
              variant="outlined"
              margin="normal"
              fullWidth
              autoComplete="off"
              error={errors.email != null}
              helperText={errors.email && errors.email.message}
              onChange={(evt) => handleChange(evt, "email")}
              rules={{
                required: t("Enter e-mail") as string,
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: t("Enter a valid e-mail address") as string,
                },
              }}
              InputLabelProps={{ shrink: user.email != null }}
            />
            <Controller
              as={TextField}
              control={control}
              id="password"
              label={<Trans>Password</Trans>}
              name="password"
              type="password"
              variant="outlined"
              margin="normal"
              autoComplete="off"
              fullWidth
              error={errors.password != null}
              helperText={errors.password && t("password required")}
              rules={{ required: true, min: 6 }}
              onChange={(evt) => handleChange(evt, "password")}
              inputProps={{
                autoComplete: "new-password",
                form: {
                  autoComplete: "off",
                },
              }}
              InputLabelProps={{ shrink: user.password != null }}
            />

            {user.role && user.role.includes("user") && (
              <>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      as={TextField}
                      control={control}
                      id="surfaceLimit"
                      defaultValue={0}
                      label={
                        <>
                          <Trans>Surface (ha)</Trans> - <Trans>NDVI</Trans>
                        </>
                      }
                      name="surfaceLimit"
                      type="number"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      error={errors.surfaceLimit != null}
                      onChange={(evt) =>
                        handleChange(evt, "surfaceLimit", (val) =>
                          parseInt(val)
                        )
                      }
                      helperText={
                        errors.surfaceLimit && t("surface limit required")
                      }
                      rules={{ min: 0 }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Controller
                      as={TextField}
                      control={control}
                      defaultValue={0}
                      id="surfaceYieldLimit"
                      label={
                        <>
                          <Trans>Surface (ha)</Trans> - <Trans>RENDEMENT</Trans>
                        </>
                      }
                      name="surfaceYieldLimit"
                      type="number"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      error={errors.surfaceYieldLimit != null}
                      onChange={(evt) =>
                        handleChange(evt, "surfaceYieldLimit", (val) =>
                          parseInt(val)
                        )
                      }
                      helperText={
                        errors.surfaceYieldLimit && t("surface limit required")
                      }
                      rules={{ min: 0 }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDatePicker
                        margin="normal"
                        inputVariant="outlined"
                        id="date-picker-dialog"
                        label={<Trans>History From date</Trans>}
                        format="DD/MM/YYYY"
                        value={fromDate}
                        fullWidth
                        onChange={handleFromDateChange}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDatePicker
                        margin="normal"
                        inputVariant="outlined"
                        id="date-picker-dialog"
                        label={<Trans>History To date</Trans>}
                        format="DD/MM/YYYY"
                        value={toDate}
                        fullWidth
                        onChange={handleToDateChange}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                </Grid>
              </>
            )}
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDatePicker
                    margin="normal"
                    inputVariant="outlined"
                    id="date-picker-dialog"
                    label={<Trans>Subscription ends</Trans>}
                    format="DD/MM/YYYY"
                    value={selectedDate}
                    fullWidth
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box mt={2}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="restriction-label">
                      {t("GeoJSON region")}
                    </InputLabel>
                    <Select
                      labelId="restriction-label"
                      id="restriction"
                      value={user.restriction || []}
                      label={t("GeoJSON region")}
                      multiple={true}
                      onChange={handleChangeRestriction}
                      renderValue={(selected: any) =>
                        selected
                          .map((x: any) => {
                            const restriction = x.name
                              ? x
                              : restrictions.find((r) => r._id === x);
                            return restriction?.name;
                          })
                          .join(", ")
                      }
                    >
                      <MenuItem value={undefined}>{t("None")}</MenuItem>
                      {restrictions.map((restriction: IRestriction) => (
                        <MenuItem key={restriction._id} value={restriction._id}>
                          <Checkbox
                            checked={
                              user &&
                              user.restriction &&
                              (user.restriction?.indexOf(
                                restriction._id as any
                              ) > -1 ||
                                user.restriction.find(
                                  (r) => r._id === restriction._id
                                ) !== undefined)
                            }
                          ></Checkbox>
                          <ListItemText primary={restriction.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
            <Button type="submit" color="primary">
              <Trans>Save</Trans>
            </Button>
            <Button onClick={(event) => onClose(event)}>
              <Trans>Close</Trans>
            </Button>
          </DialogActions>
        </form>
      ) : null}
    </Dialog>
  );
}
