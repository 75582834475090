import React, { Suspense } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import {
  IconButton,
  Icon,
  makeStyles,
  Theme,
  createStyles,
  Divider,
  Select,
  MenuItem,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { useTranslation, Trans } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { authService } from "../../services/AuthService";
import "./Header.css";
import { getLang } from "../../i18n";
import { getUrl } from "../Util/isCordova";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
      paddingTop: 0,
    },
    title: {
      flexGrow: 1,
    },
    whiteSelect: {
      color: "white",
      fontSize: "30px",
      ":before": { display: "none" },
      "& .MuiSelect-icon": {
        color: "white",
      },
    },
  })
);

function Header() {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const [currentLanguage, setCurrentLanguage] = React.useState(getLang());

  const changeLanguage = async (lng: string) => {
    const l = await i18n.changeLanguage(lng);
    document.documentElement.lang = lng;

    setCurrentLanguage(lng);
  };

  const [state, setState] = React.useState({
    open: false,
  });

  const [expanded, setExpanded] = React.useState(false);
  const [expandedYield, setExpandedYield] = React.useState(false);

  const toggleDrawer = (open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent
  ) => {
    if (
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" ||
        (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }

    setState({ ...state, open });
  };

  const url = (url: string) => {
    history.push(url);
    setState({ open: false });
  };

  const handleExit = () => {
    authService.logout();
    history.push("/");
  };

  return (
    <>
      <AppBar className="Header" position="fixed">
        <Toolbar className={classes.menuButton}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer(!state.open)}
            edge="start"
          >
            <MenuIcon />
          </IconButton>
          <div className="logo-header">
            <img src={getUrl("img/logo-h-white.png")} />
          </div>
          <span className={classes.title}></span>

          <Select
            className={classes.whiteSelect}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={currentLanguage}
            disableUnderline={true}
            onChange={(evt) => changeLanguage(evt.target.value as string)}
          >
            <MenuItem value={"fr"}>
              <img
                src={getUrl("img/flags/france.png")}
                style={{ maxHeight: "30px" }}
              />
            </MenuItem>
            <MenuItem value={"en"}>
              <img
                src={getUrl("img/flags/united-kingdom.png")}
                style={{ maxHeight: "30px" }}
              />
            </MenuItem>
          </Select>
        </Toolbar>
      </AppBar>
      <Drawer anchor="left" open={state.open} onClose={toggleDrawer(false)}>
        <div className="avatar-header">
          <div className="avatar">
            <img src={getUrl("img/user.png")} style={{ maxWidth: "100%" }} />
          </div>
          <h2>
            {authService.token && authService.user ? authService.user.name : ""}
          </h2>
        </div>
        <Divider />
        {authService.token &&
        authService.user &&
        authService.user.role.includes("admin") ? (
          <Accordion expanded={expanded} onChange={(ev, ex) => setExpanded(ex)}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Icon>lock</Icon>&nbsp;
              <Typography>{t("Administration")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List className="list">
                <ListItem button onClick={() => url("/companies")}>
                  <ListItemIcon>
                    <Icon>corporate_fare</Icon>
                  </ListItemIcon>
                  <ListItemText primary={t("Companies")} />
                </ListItem>
                <ListItem button onClick={() => url("/users")}>
                  <ListItemIcon>
                    <Icon>people</Icon>
                  </ListItemIcon>
                  <ListItemText primary={t("Users")} />
                </ListItem>
                <ListItem button onClick={() => url("/cultures")}>
                  <ListItemIcon>
                    <Icon>agriculture</Icon>
                  </ListItemIcon>
                  <ListItemText primary={t("Cultures")} />
                </ListItem>
                <ListItem button onClick={() => url("/damages")}>
                  <ListItemIcon>
                    <Icon>air</Icon>
                  </ListItemIcon>
                  <ListItemText primary={t("Damages")} />
                </ListItem>
                <ListItem button onClick={() => url("/restrictions")}>
                  <ListItemIcon>
                    <Icon>polyline</Icon>
                  </ListItemIcon>
                  <ListItemText primary={t("Regions")} />
                </ListItem>
              </List>
            </AccordionDetails>
          </Accordion>
        ) : null}

        {authService.token &&
        authService.user &&
        (authService.user.role.includes("admin") ||
          authService.user.role.includes("head")) ? (
          <Accordion
            expanded={expandedYield}
            onChange={(ev, ex) => setExpandedYield(ex)}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Icon>bar_chart</Icon>&nbsp;
              <Typography>{t("Yield stats")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List className="list">
                <ListItem button onClick={() => url("/yieldregions")}>
                  <ListItemIcon>
                    <Icon>dashboard</Icon>
                  </ListItemIcon>
                  <ListItemText primary={t("Dashboard")} />
                </ListItem>
                <ListItem button onClick={() => url("/simulation")}>
                  <ListItemIcon>
                    <Icon>dashboard</Icon>
                  </ListItemIcon>
                  <ListItemText primary={t("Simulation")} />
                </ListItem>
              </List>
            </AccordionDetails>
          </Accordion>
        ) : null}
        <List className="list">
          {authService.token &&
          authService.user &&
          (authService.user.role.includes("user") ||
            authService.user.role.includes("admin")) ? (
            <>
              <ListItem button onClick={() => url("/my-position")}>
                <ListItemIcon>
                  <Icon>location_on</Icon>
                </ListItemIcon>
                <ListItemText primary={t("My Position")} />
              </ListItem>
              <ListItem button onClick={() => url("/explotations")}>
                <ListItemIcon>
                  <Icon>crop_free</Icon>
                </ListItemIcon>
                <ListItemText primary={t("My farms")} />
              </ListItem>
            </>
          ) : null}
           {authService.token &&
          authService.user &&
          (
            authService.user.role.includes("manager")) ? (
            <>
              <ListItem button onClick={() => url("/manager")}>
                <ListItemIcon>
                  <Icon>group</Icon>
                </ListItemIcon>
                <ListItemText primary={t("Manager")} />
              </ListItem>
            </>
          ) : null}
          <ListItem button>
            <ListItemIcon>
              <Icon>key</Icon>
            </ListItemIcon>
            <ListItemText
              primary={t("Change password")}
              onClick={() => url("/recover/" + authService.token)}
            />
          </ListItem>
          <ListItem button>
            <ListItemIcon>
              <Icon>mail</Icon>
            </ListItemIcon>
            <ListItemText
              primary={t("Contact")}
              onClick={() =>
                (document.location.href = "mailto:info@synomen.com")
              }
            />
          </ListItem>
          <ListItem button onClick={() => url("/")}>
            <ListItemIcon>
              <Icon>exit_to_app</Icon>
            </ListItemIcon>
            <ListItemText onClick={handleExit} primary={t("Exit")} />
          </ListItem>
        </List>
      </Drawer>
    </>
  );
}

export default Header;
