import React, { useEffect } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { useTranslation, Trans } from "react-i18next";
import "./Manager.css";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import {
  AppBar,
  Toolbar,
  Box,
  FormControl,
  Input,
  InputAdornment,
  Icon,
  IconButton,
  Button,
  TableSortLabel,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  ListItemIcon,
  CircularProgress,
} from "@material-ui/core";
import { ManagerExplotations } from "./Manager.model";
import { userService } from "../../services/UserService";
import { useHistory } from "react-router";
import { unparse } from "papaparse";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      backgroundColor: "#8cc981",
      top: "60px",
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    buttons: {
      "& > *": {
        margin: theme.spacing(1),
      },
    },
    title: {
      flexGrow: 1,
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
  })
);

type Order = "asc" | "desc";

function Manager() {
  const classes = useStyles();
  const history = useHistory();

  const { t, i18n } = useTranslation();
  const [rows, setRows] = React.useState<ManagerExplotations[]>([]);
  const [filteredRows, setFilteredRows] = React.useState<ManagerExplotations[]>([]);
  const [open, setOpen] = React.useState(false);
  const [orderBy, setOrderBy] = React.useState("createdAt");
  const [order, setOrder] = React.useState<Order>("desc");
  const [q, setQ] = React.useState<string | undefined>(undefined);

  const [count, setCount] = React.useState(0);
  const [explotations, setExplotations] = React.useState<any[]>([]);
  const [crops, setCrops] = React.useState<any[]>([]);
  const [selectedCrops, setSelectedCrops] = React.useState<any[]>([]);
  const [selectedExplotations, setSelectedExplotations] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(false);

  const headCells = [
    { id: "name", label: t("Name") + " / " + t("Email"), align: "left" },
    { id: "explotation", label: t("Farm") },
    { id: "culture", label: t("Crop") },
    { id: "parcelles", label: t("Parcelles") },
    { id: "surface", label: t("Surface (ha)") },
    { id: "yieldAverage", label: t("Simulated yield (qt/ha)") },
    { id: "yieldEstimated", label: t("Estimated yield (qt/ha)") },
    { id: "percent_garanteed_losses", label: t("Percentage of indemnisable losses (%)") },
    { id: "percent_non_garanteed_losses", label: t("Percentage of non-indemnisable losses (%)") },

  ];

  const createSortHandler = (property: string, event: any) => {
    const newOrder = order === "asc" ? "desc" : "asc";
    setOrderBy(property);
    setOrder(newOrder);
  };

  const loadResults = async () => {
    setLoading(true);
    const result = await userService.getManagerExplotations();
    setCount(result.length);
    setRows(result);
    setCrops(result.reduce((unique: any[], item: ManagerExplotations) => {
      return unique.findIndex(culture => culture._id === item.culture._id) < 0 ? [...unique, item.culture] : unique;
    }, []));

    setExplotations(result.reduce((unique: any[], item: ManagerExplotations) => {
      return unique.findIndex(explotation => explotation._id === item.explotation._id) < 0 ? [...unique, item.explotation] : unique;
    }, []));

    setLoading(false);
  };

  const resetSearch = () => {
    setQ("");
  };

  const openUsers = (company: string) => {
    history.push("/users/" + company);
  };
  const handleChangeCrop = (event: any) => {
    const values = event.target.value;
  
    setSelectedCrops(values);
  };

  const handleChangeExplotation = (event: any) => {
    const value = event.target.value;
    setSelectedExplotations(value);
  };

  const formatNumberToCSV = (num: any, decimals = 3) => {
    const formatterNum = new Intl.NumberFormat("fr-FR", {
      style: "decimal",
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    });

    return formatterNum.format(num)?.replace(/[^0-9.,]/g, "");
  };


  const exportToCSV = () => {
      const csv = unparse(
        {
          fields: [...headCells.map((h) => t(h.label))],
          data: filteredRows.map((row) => {
            const newItem = [];
            newItem.push(row.user.name + '<' + row.user.email + '>'); 
            newItem.push(row.explotation.name);
            newItem.push(row.culture.name);
            newItem.push(row.parcelles.length);

            newItem.push(formatNumberToCSV(row.surface, 2));
            newItem.push(row.yieldAverage  && row.yieldAverage > 0 ? row.yieldAverage.toFixed(0) : 'N.A');
            newItem.push(row.yieldEstimated  && row.yieldEstimated > 0 ? row.yieldEstimated.toFixed(0) : 'N.A');
            newItem.push(row.percent_garanteed_losses !== undefined ? (row.percent_garanteed_losses || 0) + '%' : 'N.A');
            newItem.push(row.percent_non_garanteed_losses !== undefined ? (row.percent_non_garanteed_losses || 0) + '%' : 'N.A');


            return newItem;
          }),
        },
        { delimiter: ";" }
      );
      //console.log(csv);
      const universalBOM = "\uFEFF";

      const csvData = new Blob([universalBOM + csv], {
        type: "text/csv; charset=utf-8;",
      });
      const csvURL = window.URL.createObjectURL(csvData);

      const tempLink = document.createElement("a");
      tempLink.href = csvURL;
      tempLink.setAttribute("download", "download.csv");
      tempLink.click();
    
  };

  const handleClickParcele = (parcelle: any) => {
    history.push(`/my-position/parcelle/${parcelle.name}`);
  };

  const handleClickExplotation = (explotation: any) => {
    history.push(`/my-position/explotation/${explotation._id}`);
  };


  useEffect(() => {
    loadResults();
  }, []);

  useEffect(() => {
    setFilteredRows(rows.filter((row) => {
      return (!selectedCrops || selectedCrops.length === 0 || selectedCrops.find(c=>c._id === row.culture._id)) &&
      (!selectedExplotations || selectedExplotations.length === 0 || selectedExplotations.find(c=>c._id === row.explotation._id));
    }));
  },[rows, selectedCrops, selectedExplotations]);

  return (
    <>
      <AppBar position="fixed" className={classes.root}>
        <Toolbar>
          <Grid container spacing={1}>
            <Grid xs={6} sm={2} item>
              <FormControl size="small" variant="outlined" fullWidth>
                <InputLabel id="role-label">{t("Crop")}</InputLabel>
                <Select
                  labelId="crop-label"
                  id="crop"
                  value={selectedCrops}
                  label={t("Crop")}
                  multiple={true}
                  renderValue={(selected: any) =>
                    selected.map((c: any) => c.name).join(", ")
                  }
                  onChange={handleChangeCrop}
                >
                  {crops.map((crop) => (
                    <MenuItem key={crop._id} value={crop}>
                      <Checkbox
                        checked={
                          selectedCrops?.find((c) => c._id === crop._id) !==
                          undefined
                        }
                      />

                      <ListItemText primary={t(crop.name)} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid xs={6} sm={2} item>
              <FormControl size="small" variant="outlined" fullWidth>
                <InputLabel id="role-label">{t("Explotation")}</InputLabel>
                <Select
                  labelId="explotation-label"
                  id="crop"
                  value={selectedExplotations}
                  label={t("Explotation")}
                  multiple={true}
                  renderValue={(selected: any) =>
                    selected.map((c: any) => c.name).join(", ")
                  }
                  onChange={handleChangeExplotation}
                >
                  {explotations.map((explotation) => (
                    <MenuItem key={explotation._id} value={explotation}>
                      <Checkbox
                        checked={
                          selectedExplotations?.find(
                            (c) => c._id === explotation._id
                          ) !== undefined
                        }
                      />

                      <ListItemText primary={explotation.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item className="commands">
              <Button
                variant="outlined"
                onClick={() => exportToCSV()}
                color="primary"
              >
                <Trans>Download CSV</Trans>
              </Button>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>

      <div className="table-cultures-container">
        <TableContainer component={Paper}>
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align={headCell.align ? "left" : "center"}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={(evt) => createSortHandler(headCell.id, evt)}
                    >
                      {headCell.label}
                      {orderBy === headCell.id ? (
                        <span className={classes.visuallyHidden}>
                          {order === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </span>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {loading && (
                <TableRow>
                  <TableCell
                    colSpan={headCells.length}
                    align="center"
                    component="th"
                    scope="row"
                  >
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              )}
              {filteredRows.map((row, index) => (
                <TableRow key={row.explotation._id + index}>
                  <TableCell component="th" scope="row">
                    {row.user.name}
                    <br></br>
                    <a href={"mailto:" + row.user.email} className="link">
                      {row.user.email}
                    </a>
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    <IconButton
                      onClick={(event) =>
                        handleClickExplotation(row.explotation)
                      }
                    >
                      <Icon>gps_fixed</Icon>
                    </IconButton>
                    {row.explotation.name}
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    {t(row.culture.name)}
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    <FormControl size="small">
                      <Select
                        labelId="parcelles-label"
                        id="parcelles"
                        value={row.parcelles.length}
                        renderValue={(selected: any) => (
                          <div>{row.parcelles.length}</div>
                        )}
                        onChange={(event) =>
                          handleClickParcele(event.target.value)
                        }
                      >
                        {row.parcelles.map((parcelle) => (
                          <MenuItem key={parcelle._id} value={parcelle}>
                            <ListItemIcon>
                              <Icon>gps_fixed</Icon>
                            </ListItemIcon>
                            <ListItemText
                              primary={parcelle.label || parcelle.name}
                            />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    {row.surface.toFixed(2)}
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    {row.yieldAverage > 0 ? row.yieldAverage.toFixed(0) : "N.A"}
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    {row.yieldEstimated > 0
                      ? row.yieldEstimated.toFixed(0)
                      : "N.A"}
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    {
                    row.percent_garanteed_losses !== undefined
                      ? row.percent_garanteed_losses.toFixed(0) + "%"
                      : "N.A"}
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    {
                    row.percent_non_garanteed_losses !== undefined
                      ? row.percent_non_garanteed_losses.toFixed(0) + "%"
                      : "N.A"}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
}

export default Manager;
