import React, { useEffect } from "react";
import "./App.css";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import green from "@material-ui/core/colors/green";
import orange from "@material-ui/core/colors/orange";
import Header from "../Header/Header";
import Login from "../Login/Login";
import { Switch, Route } from "react-router";
import { BrowserRouter, HashRouter } from "react-router-dom";
import MyPosition from "../MyPosition/MyPosition";
import Users from "../Users/Users";
import Explotations from "../Explotations/Explotations";
import { PrivateRoute, AdminRoute } from "../Util/PrivateRoute";
import { userService } from "../../services/UserService";
import isCordova, { Keyboard } from "../Util/isCordova";
import Cultures from "../Cultures/Cultures";
import Damages from "../Damages/Damages";
import Restrictions from "../Restrictions/Restrictions";
import YieldRegions from "../Yieldregions/Yieldregions";
import Impersonate from "../Impersonate/Impersonate";
import Simulation from "../Yieldregions/Simulation";
import Recover from "../Recover/Recover";
import { authService } from "../../services/AuthService";
import Companies from "../Companies/Companies";
import Manager from "../Manager/Manager";

(green as any).main = green["900"];

const theme = createTheme({
  palette: {
    primary: green,
    secondary: orange,
  },
});

function PeekeliteRouter(props: any) {
  if (isCordova) {
    return <HashRouter>{props.children}</HashRouter>;
  } else {
    return <BrowserRouter>{props.children}</BrowserRouter>;
  }
}

function App() {
  userService.registerSessionActivity();
  
  document.addEventListener("deviceready", () => {
    if (isCordova && Keyboard) {
        try {
          Keyboard.shrinkView(true);
          Keyboard.automaticScrollToTopOnHiding = true;
        }catch(e){
          console.error(e);
      }
    }
  }, false);


  useEffect(() => {
    if (authService.token && isCordova) {
      document.body.classList.add("green");
    } else {
      document.body.classList.remove("green");
    }
  });

  return (
    <div className={"App"}>
      <ThemeProvider theme={theme}>
        <PeekeliteRouter>
          <Header />
          <div className="main-content">
            <Switch>
              <Route path="/" exact component={Login} />
              <Route path="/recover/:token" exact component={Recover} />
              <PrivateRoute
                path="/my-position/:type?/:id?"
                component={MyPosition}
              />

              <AdminRoute path="/companies" component={Companies} />
              <AdminRoute path="/users/:company?" component={Users} />
              <AdminRoute path="/cultures" component={Cultures} />
              <AdminRoute path="/damages" component={Damages} />
              <AdminRoute path="/restrictions" component={Restrictions} />
              <PrivateRoute path="/yieldregions" component={YieldRegions} />
              <PrivateRoute path="/simulation" component={Simulation} />
              <PrivateRoute path="/explotations" component={Explotations} />
              <PrivateRoute path="/manager" component={Manager} />
              <AdminRoute path="/impersonate/:token" component={Impersonate} />
            </Switch>
          </div>
        </PeekeliteRouter>
      </ThemeProvider>
    </div>
  );
}

export default App;
