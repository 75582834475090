import { BaseService } from "./BaseService";
import {
  IParcelle,
  IHisto,
  IExplotation,
  ICulture,
  IYieldHisto,
  YieldStatus,
  ICultureDetails,
} from "../../../shared/models/parcelle";
import { IComment } from "../../../shared/models/comment";
import { UserDataSet, UserQuery, IUser } from "../../../shared/models/user";
import { IDamage } from "../../../shared/models/damage";
import { CompanyStats } from "../components/Companies/Company.model";
import i18n, { getLang } from "../i18n";
import { modalService } from "./ModalService";
import { getImageBucket } from "../config";
import { ManagerExplotations } from "../components/Manager/Manager.model";

declare const cordova: any;
declare const FileTransfer: any;

export class UserService extends BaseService {
  getParcelles(lat: number, lng: number) {
    return this.get<IParcelle[]>("parcelles", { lat, lng });
  }

  getParcelleByName(name: string) {
    return this.get<IParcelle[]>(`parcelles/${name}`);
  }

  search(q: string) {
    return this.get<any[]>(`parcelles/search/${q}`);
  }

  place(place_id: string) {
    return this.get<any>(`parcelles/search/place/${place_id}`);
  }

  getHisto(name: string) {
    return this.get<IHisto[]>(`parcelles/${name}/histo`);
  }

  getYield(name: string) {
    return this.get<IYieldHisto[]>(`parcelles/${name}/yield`);
  }

  getLastYield(name: string) {
    return this.get<IYieldHisto>(`parcelles/${name}/yield-last`);
  }

  getYieldStatus(name: string) {
    return this.get<{ status: YieldStatus }>(`parcelles/${name}/yield-status`);
  }

  getAllComments(name: string) {
    return this.get<IComment[]>(`parcelles/${name}/comments`);
  }

  getComments(name: string, histoId: string) {
    return this.get<IComment[]>(`parcelles/${name}/histo/${histoId}/comments`);
  }

  createComment(name: string, comment: IComment) {
    return this.post<IComment>(`parcelles/${name}/comments`, comment);
  }

  deleteComment(comment: IComment) {
    return this.delete<IComment>(
      `parcelles/${comment.parcelleName}/comments/${comment._id}`
    );
  }

  deleteParcelle(parcelle: IParcelle, explotation: IExplotation) {
    return this.delete<IParcelle>(
      `users/explotations/${explotation._id}/${parcelle._id}`
    );
  }

  deleteExplotation(explotation: IExplotation) {
    return this.delete<IParcelle>(`users/explotations/${explotation._id}`);
  }

  getExplotation(id: string) {
    return this.get<IExplotation>(`users/explotations/${id}`);
  }

  updateCultureDetails(id: string, details: ICultureDetails[]) {
    return this.put<ICulture>(`users/explotations/${id}/culture-details`, details);
  }

  getExplotations() {
    return this.get<IExplotation[]>("users/explotations");
  }

  getCompanies() {
    return this.get<CompanyStats[]>("users/companies");
  }

  getExplotationsWithParcelles() {
    return this.get<IExplotation[]>("users/explotations/with-parcelles");
  }

  getCultures() {
    return this.get<ICulture[]>("parcelles/cultures");
  }

  getDamages() {
    return this.get<IDamage[]>("parcelles/damages");
  }

  editParcelle(name: string, parcelle: IParcelle) {
    return this.put<IParcelle>(`parcelles/${name}`, parcelle);
  }

  editParcelleContour(name: string, shape: any) {
    return this.put<IParcelle>(`parcelles/${name}/contour`, { shape });
  }

  deleteParcelleContour(name: string) {
    return this.delete<IParcelle>(`parcelles/${name}`);
  }

  getUsers(userQuery?: UserQuery) {
    const params = userQuery ? JSON.stringify(userQuery) : null;
    console.log(userQuery, params);
    return this.get<UserDataSet>("users", { filter: params });
  }

  createUser(user: IUser) {
    return this.post<IUser>("users", user);
  }

  updateUser(user: IUser) {
    return this.put<IUser>("users", user);
  }

  deleteUser(user: IUser) {
    return this.delete<IUser>(`users/${user._id}`);
  }

  getUserToken(user: IUser) {
    return this.get<{ token: string }>(`users/token/${user._id}`);
  }

  updateTime(time: number) {
    return this.put<any>("users/time", { time });
  }

  isCopy(parcelle: IParcelle) {
    return parcelle.user || parcelle.parent;
  }

  registerSessionActivity() {
    let isTabActive = true;
    let secondsActive = 0;
    const SENDING_INTERVAL = 30;
    const self = this;

    window.onfocus = function () {
      isTabActive = true;
    };

    window.onblur = function () {
      isTabActive = false;
    };

    setInterval(async () => {
      if (isTabActive) {
        secondsActive++;
      }
      if (secondsActive >= SENDING_INTERVAL) {
        console.log("send Stats", secondsActive);
        try {
          await self.updateTime(secondsActive);
        } catch (ex) {}
        secondsActive = 0;
      }
    }, 1000);
  }

  uploadFileComment(file: File | Blob, name: string): Promise<{ fileName: string }> {
    const formData = new FormData();

    formData.append("file", file);

    return this.post<any>(`parcelles/${name}/comments/image`, formData);
  }

  lastReport: any = null;

  getReportStream(name: string, histo: string[], onFinish?: (url: string) => void) {
    const lang = getLang();

    if (this.lastReport) {
      window.URL.revokeObjectURL(this.lastReport);
    }

    this.postCustom(`parcelles/${name}/report/${lang}`, {
      method: "POST",
      body: JSON.stringify({ histo }),
    })
      .then((response) => {
        // Extraer el nombre del archivo del encabezado `Content-Disposition`
        const contentDisposition = response.headers.get("Content-Disposition");
        const dateTimeFormat = new Date()
          .toISOString()
          .replace(/[^0-9]/g, "")
          .slice(0, 14);

        let filename = name + "_" + dateTimeFormat + ".pdf"; // Un nombre por defecto
        if (contentDisposition) {
          const filenameMatch = contentDisposition.match(/filename="?(.+)"?/);
          if (filenameMatch && filenameMatch.length > 1) {
            filename = filenameMatch[1];
          }
        }

        // Asegurarse de que la respuesta es de tipo PDF
        const contentType = response.headers.get("Content-Type");
        if (contentType === "application/pdf") {
          return response.blob().then((blob) => ({ blob, filename }));
        } else {
          throw new Error("Not valid file");
        }
      })
      .then(({ blob, filename }) => {
        // Crear un URL para el blob
        const url = window.URL.createObjectURL(blob);
        // Crear un enlace para descargar el blob
        const link = document.createElement("a");
        link.href = url;
        link.target = "_blank";
        link.download = filename;
        document.body.appendChild(link);
        link.click();

        const linkOpen = document.createElement("a");
        linkOpen.href = url;
        linkOpen.target = "_blank";
        document.body.appendChild(linkOpen);
        linkOpen.click();

        this.lastReport = url;

        // Limpiar después de la descarga
        document.body.removeChild(link);
        document.body.removeChild(linkOpen);

        if (onFinish) {
          onFinish(url);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        modalService.error({ text: error.message });
        if (onFinish) {
          onFinish("");
        }
      });
  }

  getReport(name: string, histo: string[], onFinish?: (url: string) => void) {
    const lang = getLang();

    this.postCustom(`parcelles/${name}/report/${lang}`, {
      method: "POST",
      body: JSON.stringify({ histo }),
    })
      .then((response) => response.json())
      .then(({fileName }) => {
        window.open(getImageBucket(fileName), '_blank');


        if (onFinish) {
          onFinish(fileName);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        modalService.error({ text: error.message });
        if (onFinish) {
          onFinish("");
        }
      });
  }

  getReportCordova(name: string, histo: string[], onFinish?: (url: string) => void) {
    const lang = getLang();
    const self = this;

    this.postCustom(`parcelles/${name}/report/${lang}`, {
      method: "POST",
      body: JSON.stringify({ histo }),
    })
      .then((response) => response.json())
      .then(({fileName }) => {
        const url = getImageBucket(fileName);
        const fileTransfer = new FileTransfer();
        const filename = `report_${new Date().toISOString().replace(/[^0-9]/g, "").slice(0, 14)}.pdf`;
        var uri = encodeURI(url);
        
        var filePath = self.getDataDirectory( filename);
        fileTransfer.download(
          uri,
          filePath,
          (entry: any) => {
            console.log("download complete: " + entry.toURL());
            cordova.plugins.fileOpener2.open(
              filePath,
              'application/pdf',
              {
                error: (e: any) => {
                  console.error('Error opening file', e);
                  cordova.InAppBrowser.open(uri, '_system', 'location=yes');
                },
                success: () => console.log('File opened successfully'),
              }
            );
            if (onFinish) {
              onFinish(fileName);
            }
    
          },
          (error: any) => {
              console.error("Error al descargar archivo", error);
              if (onFinish) {
                onFinish(fileName);
              }
          },
          false
      );
       


      })
      .catch((error) => {
        console.error("Error:", error);
        modalService.error({ text: error.message });
        if (onFinish) {
          onFinish("");
        }
      });
  }

  getDataDirectory(file = "") {
    return cordova.file.cacheDirectory + file;
  }


  getReportCordovaStream(name: string, histo: string[], onFinish?: (url: string) => void) {
    const lang = getLang();
    const self = this;

  this.postCustom(`parcelles/${name}/report/${lang}`, {
    method: "POST",
    body: JSON.stringify({ histo }),
  })
    .then((response) => {
      // Verificar si el contenido es PDF
      const contentType = response.headers.get("Content-Type");
      if (contentType === "application/pdf") {
        return response.blob();
      } else {
        throw new Error("Not valid file");
      }
    })
    .then((blob) => {
      // Usar cordova-plugin-file para guardar el archivo en el dispositivo
      const directory = self.getDataDirectory(); // cordova.file.applicationStorageDirectory; // O el directorio que prefieras
      const filename = `report_${new Date().toISOString().replace(/[^0-9]/g, "").slice(0, 14)}.pdf`;

      return new Promise((resolve, reject) => {
        (window as any).resolveLocalFileSystemURL(directory, (dirEntry: any) => {
          dirEntry.getFile(filename, { create: true, exclusive: false }, (fileEntry: any) => {
            fileEntry.createWriter((fileWriter: any) => {
              fileWriter.write(blob);
              resolve(directory + filename); // Retorna la ruta completa del archivo
            }, reject);
          }, reject);
        }, reject);
      });
    })
    .then((filePath: any) => {
      // Usar cordova-plugin-file-opener2 para abrir el archivo
      cordova.plugins.fileOpener2.open(
        filePath,
        'application/pdf',
        {
          error: (e: any) => console.error('Error opening file', e),
          success: () => console.log('File opened successfully'),
        }
      );

      if (onFinish) {
        onFinish(filePath.toString());
      }
    })
    .catch((error) => {
      console.error("Error:", error);
      if (onFinish) {
        onFinish("");
      }
    });
  }

  getManagerExplotations() {
    return this.get<ManagerExplotations[]>("parcelles/manager/explotations");
  }
}

export const userService = new UserService();
