import React, { useEffect } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { useTranslation, Trans } from "react-i18next";
import "./Users.css";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import {
  AppBar,
  Toolbar,
  Box,
  FormControl,
  Input,
  InputAdornment,
  Icon,
  IconButton,
  Button,
  TableSortLabel,
} from "@material-ui/core";
import { IUser } from "../../../../shared/models/user";
import { EditUser } from "./EditUser";
import { userService } from "../../services/UserService";
import Moment from "react-moment";
import { authService } from "../../services/AuthService";
import { modalService } from "../../services/ModalService";
import { useParams } from "react-router";
import { getLang } from "../../i18n";

export class UserQuery {
  q?: string;
  limit: number = 10;
  skip: number = 0;
  orderBy?: string;
  order?: string;
  company?: string;

  constructor(
    limit: number,
    skip: number,
    orderBy?: string,
    order?: string,
    company?: string
  ) {
    this.limit = limit;
    this.skip = skip;
    this.orderBy = orderBy;
    this.order = order;
    this.company = company;
  }
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      backgroundColor: "#8cc981",
      top: "60px",
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    buttons: {
      "& > *": {
        margin: theme.spacing(1),
      },
    },
    title: {
      flexGrow: 1,
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
  })
);

type Order = "asc" | "desc";

function Users() {
  let { company } = useParams<{ company: string }>();

  const classes = useStyles();
  const rpp = 15;
  const cp = 0;

  const { t, i18n } = useTranslation();
  const [rows, setRows] = React.useState<IUser[]>([]);
  const [open, setOpen] = React.useState(false);
  const [user, setUser] = React.useState({} as IUser);
  const [orderBy, setOrderBy] = React.useState("createdAt");
  const [order, setOrder] = React.useState<Order>("desc");
  const [q, setQ] = React.useState<string | undefined>(undefined);

  const [page, setPage] = React.useState(0);
  const [count, setCount] = React.useState(0);

  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [userQuery, setUserQuery] = React.useState(
    new UserQuery(rpp, cp, "createdAt", "desc", company)
  );

  const createNewUser = () => {
    setOpen(true);
    setUser(({
      cultures: [],
      views: [],
      clients: [],
      role: ["user"],
    } as unknown) as IUser);
  };

  const headCells = [
    { id: "name", label: t("Name") + " / " + t("Email"), align: "left" },
    { id: "role", label: t("Role") },
    { id: "company", label: t("Enterprise") },
    { id: "restriction", label: t("Region") },
    { id: "expiresIn", label: t("Subscription"), type: "moment" },
    { id: "surfaceLimit", label: t("Surface") + " - " + t("NDVI") },
    { id: "surfaceYieldLimit", label: t("Surface") + " - " + t("YIELD") },
    { id: "timeUsed", label: t("Time used"), type: "time" },
    { id: "createdAt", label: t("Registered"), type: "moment" },
    { id: "actions", label: t("Actions") },
  ];

  const createSortHandler = (property: string, event: any) => {
    const newOrder = order === "asc" ? "desc" : "asc";
    setOrderBy(property);
    setOrder(newOrder);
    setUserQuery({ ...userQuery, orderBy: property, order: newOrder });
  };

  const loadResults = async () => {
    const result = await userService.getUsers(userQuery);
    setCount(result.count);
    setRows(result.users);
  };

  const editRow = (user: IUser) => {
    setOpen(true);
    setUser(user);
  };

  const changePage = (evt: any, p: number) => {
    const limit = rowsPerPage;
    const skip = p * limit;
    setPage(p);
    setUserQuery({ ...userQuery, skip, limit });
  };

  const changeRowsPerPage = (evt: any) => {
    const limit = parseInt(evt.target.value);
    const skip = page * limit;
    setRowsPerPage(limit);
    setUserQuery({ ...userQuery, skip, limit });
  };

  const search = () => {
    setUserQuery({ ...userQuery, q });
  };

  const resetSearch = () => {
    setQ("");
    setUserQuery({ ...userQuery, q: "" });
  };

  const deleteUser = async (user: IUser) => {
    const confirm = window.confirm(
      t("Do you want to delete this user? This action cannot be undone.")
    );

    if (confirm) {
      await userService.deleteUser(user);
      await loadResults();
    }
  };

  const impersonateUser = async (user: IUser) => {
    const token = await userService.getUserToken(user);
    window.open("/impersonate/" + token.token, "_blank");
  };

  const resetPassword = async (user: IUser) => {
    authService
      .recover(user.email, getLang())
      .then((res) => {
        modalService.info({
          text: t("Password sent to user"),
        });
      })
      .catch((err) => {
        modalService.error({
          text: t(err.message),
        });
      });
  };

  useEffect(() => {
    loadResults();
  }, [userQuery]);

  return (
    <>
      <AppBar position="fixed" className={classes.root}>
        <Toolbar>
          <Box className="search-box" display="flex">
            <FormControl className="flex">
              <Input
                id="search-box"
                name="search-box"
                placeholder={t("Search users")}
                disableUnderline={true}
                autoComplete="off"
                value={q}
                onChange={(evt) => setQ(evt.target.value)}
                startAdornment={
                  <InputAdornment position="start">
                    <Icon>search</Icon>
                  </InputAdornment>
                }
                endAdornment={
                  <>
                    {q != "" ? (
                      <IconButton component="span" onClick={resetSearch}>
                        <Icon>close</Icon>
                      </IconButton>
                    ) : null}
                    <Button color="primary" onClick={search}>
                      <Trans>Search</Trans>
                    </Button>
                  </>
                }
              />
            </FormControl>
          </Box>
          <span className={classes.title}></span>
          <div className={classes.buttons}>
            <Button
              variant="contained"
              color="secondary"
              onClick={createNewUser}
              startIcon={<Icon>person</Icon>}
            >
              <Trans>NEW USER</Trans>
            </Button>
          </div>
        </Toolbar>
      </AppBar>
      <div className="table-users-container">
        <TableContainer component={Paper}>
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align={headCell.align ? "left" : "center"}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={(evt) => createSortHandler(headCell.id, evt)}
                    >
                      {headCell.label}
                      {orderBy === headCell.id ? (
                        <span className={classes.visuallyHidden}>
                          {order === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </span>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.email}>
                  <TableCell component="th" scope="row">
                    {row.name}
                    <br></br>
                    <a href={"mailto:" + row.email} className="link">
                      {row.email}
                    </a>
                  </TableCell>
                  <TableCell align="center">{row.role.join(", ")}</TableCell>
                  <TableCell>{row.company}</TableCell>
                  <TableCell>
                    {row.restriction
                      ? row.restriction.map((r) => r.name).join(",")
                      : "-"}
                  </TableCell>
                  <TableCell align="center">
                    {row.expiresIn ? (
                      <Moment format="DD/MM/YYYY" date={row.expiresIn} />
                    ) : (
                      "-"
                    )}
                  </TableCell>
                  <TableCell align="center">
                    <Trans>limit</Trans>: {row.surfaceLimit} <br></br>{" "}
                    <Trans>used</Trans>: {row.surfaceUsed.toFixed(2)}
                  </TableCell>
                  <TableCell align="center">
                    <Trans>limit</Trans>: {row.surfaceYieldLimit} <br></br>{" "}
                    <Trans>used</Trans>: {row.surfaceYieldUsed.toFixed(2)}
                  </TableCell>
                  <TableCell align="center">
                    {(row.timeUsed / 60).toFixed(2)} min
                  </TableCell>
                  <TableCell align="center">
                    {row.createdAt ? (
                      <Moment format="DD/MM/YYYY" date={row.createdAt} />
                    ) : (
                      "-"
                    )}
                  </TableCell>
                  <TableCell align="center">
                    <IconButton
                      color="primary"
                      onClick={() => editRow(row)}
                      component="span"
                    >
                      <Icon>edit</Icon>
                    </IconButton>
                    <IconButton
                      onClick={() => deleteUser(row)}
                      color="secondary"
                      component="span"
                    >
                      <Icon>delete</Icon>
                    </IconButton>
                    <IconButton
                      onClick={() => impersonateUser(row)}
                      color="primary"
                      component="span"
                    >
                      <Icon>visibility</Icon>
                    </IconButton>
                    <IconButton
                      onClick={() => resetPassword(row)}
                      color="primary"
                      component="span"
                    >
                      <Icon>mail</Icon>
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 15, 25, 50]}
                  count={count}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  labelRowsPerPage=""
                  SelectProps={{
                    inputProps: { "aria-label": "rows per page" },
                    native: true,
                  }}
                  onPageChange={(evt, page) => changePage(evt, page)}
                  onChangeRowsPerPage={(evt) => changeRowsPerPage(evt)}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
      <EditUser
        open={open}
        user={user}
        onSave={() => {
          setOpen(false);
          loadResults();
        }}
        onClose={() => setOpen(false)}
      />
    </>
  );
}

export default Users;
