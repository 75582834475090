import { BaseService } from "./BaseService";
import { IClient, IYieldRegionsClient } from "../../../shared/models/regions";
import { AdminDataSet, YieldRegionQuery } from "../../../shared/models/admin";
import {
  MIN_YEAR,
  YieldScale,
} from "../components/Yieldregions/Filters/Filters";
import { authService } from "./AuthService";

export class YieldFilters {
  orderBy: string = "name";
  order: string = "asc";
  selectedCulture: string = "BTH";
  selectedVue: string = YieldScale.E1;
  selectedRegion?: string = undefined;
  selectedClient?: string = undefined;
  selectedYear: number = 0;

  constructor() {}
}

export class YieldRegionService extends BaseService {
  init() {
    if (this.filters === undefined || this.filters === null) {
      this.filters = new YieldFilters();
    }
    return this;
  }

  get filters(): YieldFilters | undefined | null {
    return this.getItem<YieldFilters>("_yield_filters");
  }

  set filters(_filters: YieldFilters | undefined | null) {
    this.setItem("_yield_filters", _filters);
  }

  resetFilters() {
    this.removeItem("_yield_filters");
  }

  yieldRegionsControler?: AbortController;

  async getYieldRegionByLevel(filter?: any, ignoreAbort?: boolean) {
    const years = filter?.client ? await yieldRegionService.getValidYears(filter?.client as string) : authService.user?.years; 

    if (filter && (filter?.year === undefined)) {
      filter.year = years && years.length > 0 && !years.includes(filter.year ) ? years[0] : MIN_YEAR;
    }else{
      filter.year = years && years.length > 0 && !years.includes(filter.year ) ? years[0] : filter.year;
    }

    if (this.yieldRegionsControler && ignoreAbort !== true) {
      this.yieldRegionsControler.abort();
      this.yieldRegionsControler = new AbortController();
    }


    const result = await this.get<AdminDataSet<IYieldRegionsClient>>(
      "yieldregions",
      { filter: JSON.stringify(filter) },
      this.yieldRegionsControler
        ? { signal: this.yieldRegionsControler?.signal }
        : {}
    );
    const items = result.items.map((r) => {
      const item = { ...r };
      item.region_id = item.region_id
        ? result.regions[item.region_id as any]
        : undefined;
      item.parent_region_id = item.parent_region_id
        ? result.regions[item.parent_region_id as any]
        : undefined;
      return item;
    });

    const dataSet = { items, count: items.length } as AdminDataSet<
      IYieldRegionsClient
    >;

    return dataSet;
  }

  getYieldRegionByIds(ids: any[]) {
    return this.post<AdminDataSet<IYieldRegionsClient>>(
      "yieldregions/ids",
      ids
    );
  }

  getClients() {
    return this.get<IClient[]>("yieldregions/clients");
  }

  getMinYear() {
    return this.get<{ year: number }>("yieldregions/min-year");
  }

  getValidYearsCached(client_id: string) {
    try{
      const years = JSON.parse(sessionStorage.getItem("__VALID_YEARS__") || "{}");
      if(years && years[client_id]) {
        return years[client_id];
      }
    }catch(ex){
      console.error(ex);
    }

    return undefined;

  }

  setValidYearsCached(client_id: string, years: number[]) {
    try{
      let cache = JSON.parse(sessionStorage.getItem("__VALID_YEARS__") || "{}");
      cache[client_id] = years;
      sessionStorage.setItem("__VALID_YEARS__", JSON.stringify(cache));
    }catch(ex){
      console.error(ex);
    }
  }

  async getValidYears(client_id: string) {
    let yearsCached = this.getValidYearsCached(client_id);
      if(yearsCached && yearsCached.length > 0) {
        return yearsCached;
      }else{
      const years = await this.get<{ years: number[] }>(`yieldregions/years/${client_id}`);
      
      if(authService.user && authService.user.years && authService.user.years.length > 0) {
        yearsCached =  years.years.filter(year => authService.user!.years!.includes(year));
      }else{
        yearsCached =  years.years;
      }
      this.setValidYearsCached(client_id, yearsCached);
      return yearsCached;
    }
  }

  resetValidYearsCache() {
    sessionStorage.removeItem("__VALID_YEARS__");
  }


  getLosses(yieldClient: IYieldRegionsClient) {
    return this.get<{ losses: number }>("yieldregions/losses", {
      yieldClient: JSON.stringify({
        _id: yieldClient._id,
        yield_ref: yieldClient.yield_ref,
        deductible: yieldClient.deductible,
        pricing: yieldClient.pricing,
        market_shares: yieldClient.market_shares,
      }),
    });
  }

  save(rowsToSave: IYieldRegionsClient[]) {
    return this.post<{ count: number }>("yieldregions/update", rowsToSave);
  }

  getYears(minYear: number): number[] {
    const currentYear: number = new Date().getFullYear();
    const years: number[] = [];

    for (let year = minYear; year <= currentYear; year++) {
      years.push(year);
    }

    return years;
  }

  getEfectiveYear(year?: number) {
    const currentYear: number = year
      ? year
      : this.filters?.selectedYear
      ? this.filters?.selectedYear
      : new Date().getFullYear();
    if (
      authService.user &&
      authService.user.years &&
      authService.user.years.length > 0
    ) {
      return authService.user.years.includes(currentYear)
        ? currentYear
        : authService.user.years[0];
    } else {
      return MIN_YEAR;
    }
  }

  getDefaultYear(): number {
    const currentYear: number = this.filters?.selectedYear
      ? this.filters?.selectedYear
      : new Date().getFullYear();
    if (
      authService.user &&
      authService.user.years &&
      authService.user.years.length > 0
    ) {
      return authService.user.years.includes(currentYear)
        ? currentYear
        : authService.user.years[0];
    } else {
      return 0;
    }
  }

  DEFAULT_MIN_YEAR = 2023;
}

export const yieldRegionService = new YieldRegionService().init();
