import React, { useEffect } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { useTranslation, Trans } from "react-i18next";
import { unparse } from "papaparse";
import {
  Box,
  Button,
  TableSortLabel,
  Grid,
  Card,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  LinearProgress,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import { IYieldRegionsClient } from "../../../../shared/models/regions";
import { yieldRegionService } from "../../services/YieldRegionService";
import { Filters, YieldScale } from "./Filters/Filters";
import { DEFAULT_SCALE, Map } from "./Map/Map";

import "./Yieldregions.css";
import { Chart } from "./Chart/Chart";
import {
  ChartDistribution,
  YieldDistribution,
} from "./Chart/ChartDistribution";
import { authService } from "../../services/AuthService";
import { ICulture } from "../../../../shared/models/parcelle";
import { useHistory } from "react-router-dom";
import { modalService } from "../../services/ModalService";

let isLoading = false;
export class YieldRegionQuery {
  q?: string;
  scale?: string = "E1";
  region?: string;
  crop?: string;
  client?: string;
  year: number = yieldRegionService.getDefaultYear();

  limit: number = 10;
  skip: number = 0;
  orderBy?: string;
  order?: string;

  constructor(
    limit: number,
    skip: number,
    orderBy?: string,
    order?: string,
    initialVue?: string,
    region?: string,
    crop?: string,
    client?: string,
    year?: number
  ) {
    this.limit = limit;
    this.skip = skip;
    this.orderBy = orderBy;
    this.order = order;
    this.scale = initialVue ? initialVue : "E1";
    this.region = region;
    this.crop = crop;
    this.client = client;
    this.year = yieldRegionService.getEfectiveYear(year);
  }
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      backgroundColor: "#8cc981",
      top: "60px",
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    buttons: {
      "& > *": {
        margin: theme.spacing(1),
      },
    },
    title: {
      flexGrow: 1,
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
  })
);

type Order = "asc" | "desc";

const headCellsE1 = [{ id: "name", label: "Region", align: "left" }];

const headCellsE2 = (label: string) => [
  { id: "departement", label, align: "left" },
];

const headCellsBase = [
  { id: "surface", label: "Surface (milliers ha)", align: "left" },
  { id: "yield_av", label: "Yield (qt/ha)", align: "left" },
  {
    id: "yield_std",
    label: "Relative dispersion of yield (%)",
    align: "left",
  },
  {
    id: "yield_av_surface",
    label: "Production (thousand tons)",
    align: "left",
  },
  {
    id: "insured_capital",
    label: "Sum insured (thousands of euros)",
    align: "left",
  },
  {
    id: "losses",
    label: "Theoretical loss (thousands of euros)",
    align: "left",
  },
];

function YieldRegions() {
  const filters = yieldRegionService.filters;
  const classes = useStyles();
  const rpp = 15;
  const cp = 0;
  const history = useHistory();

  let initialVue = filters?.selectedVue || YieldScale.E1;

  if (
    authService.user &&
    authService.user.views &&
    authService.user?.views.length > 0
  ) {
    if (authService.user?.views.indexOf(initialVue) === -1) {
      initialVue = authService.user?.views[0];
    }
  }

  const { t } = useTranslation();
  const [rows, setRows] = React.useState<IYieldRegionsClient[]>([]);
  const [distribution, setDistribution] = React.useState<YieldDistribution[]>(
    []
  );


  const [hailEnabled, setHailEnabled] = React.useState<boolean>(true);

  const [headCells, setHeadCells] = React.useState([
    ...headCellsE1,
    ...headCellsBase,
  ]);

  const [selectedRegions, setSelectedRegions] = React.useState<
    IYieldRegionsClient[]
  >([]);

  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const [noExportAllowed, setNoExportAllowed] = React.useState(false);
  const [distributionMode, setDistributionMode] = React.useState(false);

  const [orderBy, setOrderBy] = React.useState(filters?.orderBy || "createdAt");
  const [order, setOrder] = React.useState<Order>(
    (filters?.order as Order) || "desc"
  );
  const [updateOrder, setUpdateOrder] = React.useState<number>(0);

  const [q, setQ] = React.useState<string | undefined>(undefined);
  const [selectedCulture, setSelectedCulture] = React.useState<string>(
    filters?.selectedCulture || "BTH"
  );

  const [selectedClient, setSelectedClient] = React.useState<string>(
    filters?.selectedClient || ""
  );

  const [selectedYear, setSelectedYear] = React.useState<number>(
    yieldRegionService.getEfectiveYear(filters?.selectedYear)
  );

  const [selectedCrop, setSelectedCrop] = React.useState<ICulture>();

  const [scaleFactor, setScaleFactor] = React.useState<number>(DEFAULT_SCALE);

  const [selectedVue, setSelectedVue] = React.useState<string | unknown>(
    initialVue
  );

  const [selectedRegion, setSelectedRegion] = React.useState<string | unknown>(
    filters?.selectedRegion
  );

  const [page, setPage] = React.useState(0);
  const [count, setCount] = React.useState(0);

  const [noResults, setNoResults] = React.useState(false);

  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [yieldRegionQuery, setYieldRegionQuery] = React.useState(
    new YieldRegionQuery(
      rpp,
      cp,
      filters?.orderBy || "name",
      (filters?.order as Order) || "asc",
      initialVue,
      filters?.selectedRegion,
      filters?.selectedCulture || "BTH",
      filters?.selectedClient,
      filters?.selectedYear
    )
  );

  const sortRows = (a: any, b: any) => {
    const direction = order || "asc";
    const property = orderBy || "name";
    if (property === "name" || property === "departement") {
      const a_name =
        property === "departement"
          ? a.region_id?.name
          : a.parent_region_id?.name || a.name;
      const b_name =
        property === "departement"
          ? b.region_id?.name
          : b.parent_region_id?.name || b.name;

      return direction !== "asc"
        ? a_name.localeCompare(b_name)
        : b_name.localeCompare(a_name);
    } else if (property === "surface") {
      const a_name = a.surface;
      const b_name = b.surface;
      return direction !== "asc" ? a_name - b_name : b_name - a_name;
    } else if (property === "yield_av_surface") {
      const a_name = (a.yield_av * a.surface) / 100;
      const b_name = (b.yield_av * b.surface) / 100;
      return direction !== "asc" ? a_name - b_name : b_name - a_name;
    } else {
      const a_name = parseFloat(a[property]);
      const b_name = parseFloat(b[property]);

      return direction === "asc" ? a_name - b_name : b_name - a_name;
    }
  };

  const createSortHandler = (property: string) => {
    const newOrder = order === "asc" ? "desc" : "asc";
    setOrderBy(property);
    setOrder(newOrder);
    setUpdateOrder(new Date().getTime());

    /*setYieldRegionQuery({
      ...yieldRegionQuery,
      orderBy: property,
      order: newOrder,
    });*/
  };

  const formatter = new Intl.NumberFormat("fr-FR", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 3,
    maximumFractionDigits: 3,
  });

  const formatterPercent = new Intl.NumberFormat("fr-FR", {
    style: "decimal",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const formatterNum = new Intl.NumberFormat("fr-FR", {
    style: "decimal",
    minimumFractionDigits: 3,
    maximumFractionDigits: 3,
  });

  useEffect(() => {
    setRows([...rows].sort(sortRows));
  }, [updateOrder]);

  const calculateDistribution = (items: IYieldRegionsClient[]) => {
    const yields = items.map((i) =>
      i.yield_av !== undefined ? i.yield_av : 0
    );
    const step = 5;
    const max = Math.max(...yields) + step;
    const min = Math.min(...yields) - step;
    const vals = [];
    for (let i = min; i <= max; i += step) {
      const count =
        yields.filter((y) => y >= i && y <= i + (step - 1)).length || 0;
      if (count > 0 && i >= 0) {
        const yieldVal = Math.ceil(parseFloat(i as any) || 0);
        vals.push({
          yield: yieldVal,
          count,
        });
      }
    }

    setDistribution(vals);
    setDistributionMode(true);
  };

  const loadResults = async () => {
    setNoResults(false);
    if (!isLoading) {
      try {
        setLoading(true);
        isLoading = true;
        const query =
          yieldRegionQuery.region === ""
            ? { ...yieldRegionQuery, region: undefined }
            : { ...yieldRegionQuery };
        if (
          query.region === undefined &&
          query.scale === "E4" &&
          authService.user &&
          (authService.user.restriction === undefined || authService.user.restriction.length <= 0)
        ) {
          modalService.info({
            text: t("Select a region to access this View"),
          });
        } else {
          const result = await yieldRegionService.getYieldRegionByLevel(query);

          if (result.items.length === 0) {
            setNoResults(true);
          }

          if (result.items.length > 20) {
            calculateDistribution(result.items);
          } else {
            setDistributionMode(false);
          }

          const newRows = result.items.sort(sortRows);

          setCount(newRows.length);

          if (selectedVue === "E1") {
            setHeadCells([...headCellsE1, ...headCellsBase]);
            setRows(newRows);
          } else {
            setHeadCells([
              ...headCellsE1,
              ...headCellsE2(t(selectedVue as string)),
              ...headCellsBase,
            ]);
            setRows(newRows);
          }

          if (selectedRegions.length <= 0) {
            setSelectedRegions(newRows);
          }
        }
      } catch (ex) {
        console.error(ex);
      } finally {
        isLoading = false;
        setLoading(false);
      }
    }
  };

  const handleChangeView = (view: any) => {
    if (
      selectedRegion === undefined &&
      view === "E4" &&
      authService.user &&
      (authService.user.restriction === undefined || authService.user.restriction.length <= 0)
      ) {
      modalService.info({
        text: t("Select a region to access this View"),
      });
      setSelectedVue(undefined);
      setTimeout(() => {
        setSelectedVue(selectedVue);
      });
    } else {
      setPage(1);
      setYieldRegionQuery({
        ...yieldRegionQuery,
        skip: 0,
        scale: view,
        region: view === "E1" ? undefined : (selectedRegion as any),
      });
      setSelectedVue(view);
    }

    if (view === "E1") {
      setSelectedRegion(undefined);
    }
  };

  const handleChangeCulture = (culture: any, crop: any) => {
    //console.log("CULTURE", crop);
    setPage(1);
    setYieldRegionQuery({ ...yieldRegionQuery, skip: 0, crop: culture });
    setSelectedCulture(culture);
    setSelectedCrop(crop);
    // debugger;
  };

  const handleChangeClient = (client: any) => {
    //console.log("CULTURE", crop);
    if (client !== "" && client !== undefined && client !== null) {
      setPage(1);
      setYieldRegionQuery({ ...yieldRegionQuery, skip: 0, client });
      setSelectedClient(client);
    }
  };

  const handleChangeYear = (year: number) => {
    if (year !== undefined && year !== null && year !== 0) {
      setPage(1);
      setYieldRegionQuery({ ...yieldRegionQuery, skip: 0, year });
      setSelectedYear(year);
    }
  };

  const handleChangeRegion = (region: any) => {
    //console.log("REGION", region);
    setPage(1);

    setSelectedRegion(region);

    if (region && selectedVue === "E1") {
      setSelectedVue(YieldScale.E2);
    } else if (region === "") {
      setSelectedVue(YieldScale.E1);
    } else {
      setYieldRegionQuery({
        ...yieldRegionQuery,
        skip: 0,
        region,
        scale: region && selectedVue === "E1" ? "E2" : (selectedVue as any),
      });
    }

    // debugger;
  };

  useEffect(() => {
    yieldRegionService.filters = {
      ...yieldRegionService.filters,
      selectedVue: selectedVue,
    } as any;
  }, [selectedVue]);

  useEffect(() => {
    yieldRegionService.filters = {
      ...yieldRegionService.filters,
      selectedRegion: selectedRegion,
    } as any;
  }, [selectedRegion]);

  useEffect(() => {
    yieldRegionService.filters = {
      ...yieldRegionService.filters,
      selectedCulture: selectedCrop?.abbr,
    } as any;
  }, [selectedCrop]);

  useEffect(() => {
    yieldRegionService.filters = {
      ...yieldRegionService.filters,
      selectedYear: selectedYear,
    } as any;
  }, [selectedYear]);

  useEffect(() => {
    if (
      selectedClient !== "" &&
      selectedClient !== undefined &&
      selectedClient !== null
    ) {
      yieldRegionService.filters = {
        ...yieldRegionService.filters,
        selectedClient: selectedClient,
      } as any;
    }
  }, [selectedClient]);

  const formatNumberToCSV = (num: any, decimals = 3) => {
    const formatterNum = new Intl.NumberFormat("fr-FR", {
      style: "decimal",
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    });

    return formatterNum.format(num)?.replace(/[^0-9.,]/g, "");
  };

  const exportToCSV = () => {
    if (
      authService.user?.exportCSV === true ||
      authService.user?.role.includes("admin")
    ) {
      const csv = unparse(
        {
          fields: ["_id", t('Crop'), ...headCells.map((h) => t(h.label))],
          data: rows
            .filter(
              (r) => Math.ceil(r.surface) > 0 && Math.ceil(r.yield_av) > 0
            )
            .map((row) => {
              const newItem = [];
              newItem.push(row._id);
              newItem.push(selectedCulture);

              if (selectedVue === "E1") {
                newItem.push(row.name || row.region_id?.name);
              } else {
                newItem.push(
                  row.parent_region_id?.name
                    ? row.parent_region_id!.name
                    : row.name
                );
                newItem.push(row.name || row.region_id?.name);
              }

              newItem.push(formatterNum.format(row.surface || 0));
              newItem.push(formatterPercent.format(row?.yield_av || 0));
              newItem.push(
                formatterPercent.format((row.yield_std / row.yield_av) * 100)
              );
              newItem.push(formatNumberToCSV(row.yield_av * row.surface * 0.1));
              newItem.push(formatNumberToCSV(row.insured_capital));
              newItem.push(formatNumberToCSV(row.losses));
              return newItem;
            }),
        },
        { delimiter: ";" }
      );
      //console.log(csv);

      const universalBOM = "\uFEFF";
      const csvData = new Blob([universalBOM + csv], {
        type: "text/csv; charset=utf-8;",
      });
      const csvURL = window.URL.createObjectURL(csvData);

      const tempLink = document.createElement("a");
      tempLink.href = csvURL;
      tempLink.setAttribute("download", "download.csv");
      tempLink.click();
    } else {
      setNoExportAllowed(true);
    }
  };

  useEffect(() => {
    if (selectedClient && selectedCulture && selectedVue) {
      loadResults();
    }
  }, [yieldRegionQuery]);

  return (
    <>
      <Filters
        disabled={loading}
        crop={selectedCulture}
        vue={selectedVue}
        region={selectedRegion}
        regions={selectedRegions}
        client={selectedClient}
        year={selectedYear}
        onChangeView={handleChangeView}
        onChangeCulture={handleChangeCulture}
        onChangeRegion={handleChangeRegion}
        onChangeClient={handleChangeClient}
        onChangeYear={handleChangeYear}
        command={
          <>
            <Button
              color="primary"
              variant="contained"
              size="small"
              onClick={() => exportToCSV()}
            >
              {t("Export CSV")}
            </Button>
            <Button
              color="primary"
              variant="contained"
              size="small"
              style={{ marginLeft: 10 }}
              onClick={() => history.push("/simulation")}
            >
              {t("Simulation")}
            </Button>
          </>
        }
      />
      <div
        className="table-yieldRegions-container"
        style={{ opacity: loading ? 0.4 : 1 }}
      >
        <Card>
          {loading && (
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          )}

          {noResults && (
            <div className="no-results">
              <img src="/img/not_found.png" alt="No results" />
              <h2>{t("NO RESULTS FOUND")}</h2>
            </div>
          )}

          {!noResults && (
            <Grid container spacing={4}>
              <Grid xs={12} md={6} item>
                {distributionMode ? (
                  <ChartDistribution
                    title={t("Distribution des rendements")}
                    labelX={t("Rendement (qt/ha)")}
                    labelY={t("Nombre d'occurrences")}
                    stats={distribution}
                  />
                ) : (
                  <Chart
                    title={t("Distribution des rendements")}
                    fieldName="yield_av"
                    fieldErrorName="yield_std"
                    regions={rows}
                  />
                )}
              </Grid>
              <Grid xs={12} md={6} item>
                <Map
                  regions={rows}
                  scaleFactor={scaleFactor}
                  crop={selectedCrop}
                  fieldName="yield_av"
                  title={t("Rendement (qt/ha)")}
                  hail={hailEnabled}
                />
                              <FormControlLabel
                          labelPlacement="start"
                          control={<Switch color="primary" checked={hailEnabled} onChange={(event)=>setHailEnabled(event.target.checked)} name="hailMode" />}
                          label={t("Hail")}
                        />
              </Grid>
            </Grid>
          )}
        </Card>
        <br />

        {rows && (
          <TableContainer component={Paper}>
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <TableRow>
                  {headCells.map((headCell) => (
                    <TableCell
                      key={headCell.id}
                      align={headCell.align ? "left" : "center"}
                      sortDirection={orderBy === headCell.id ? order : false}
                    >
                      <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : "asc"}
                        onClick={(evt) => createSortHandler(headCell.id)}
                      >
                        {t(headCell.label)}
                        {orderBy === headCell.id ? (
                          <span className={classes.visuallyHidden}>
                            {order === "desc"
                              ? "sorted descending"
                              : "sorted ascending"}
                          </span>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows
                  .filter(
                    (r) => Math.ceil(r.surface) > 0 && Math.ceil(r.yield_av) > 0
                  )
                  .map((row) => (
                    <TableRow key={row._id}>
                      {selectedVue === "E1" ? (
                        <TableCell component="th" scope="row">
                          {row.name || row.region_id?.name}
                        </TableCell>
                      ) : (
                        <>
                          <TableCell component="th" scope="row">
                            {row.parent_region_id?.name
                              ? row.parent_region_id!.name
                              : row.name}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row.name || row.region_id?.name}
                          </TableCell>
                        </>
                      )}

                      <TableCell component="th" scope="row">
                        {formatterNum.format(row.surface || 0)}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {formatterPercent.format(row?.yield_av || 0)}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {formatterPercent.format(
                          (row.yield_std / row.yield_av) * 100
                        )}
                        {"%"}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {formatterNum.format(row.yield_av * row.surface * 0.1)}
                      </TableCell>
                      <TableCell
                        style={{
                          backgroundColor: "#eee",
                          fontWeight: "bold",
                          textAlign: "right",
                          padding: 30,
                        }}
                        component="th"
                        scope="row"
                      >
                        {formatter
                          .format(row.insured_capital)
                          ?.replace("€", "k€")}
                      </TableCell>
                      <TableCell
                        style={{
                          backgroundColor: "#eee",
                          fontWeight: "bold",
                          textAlign: "right",
                          padding: 30,
                        }}
                        component="th"
                        scope="row"
                      >
                        {formatter.format(row.losses)?.replace("€", "k€")}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
      <Dialog
        open={noExportAllowed}
        //TransitionComponent={Transition}
        keepMounted
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        fullWidth={true}
        onClose={() => setNoExportAllowed(false)}
      >
        <DialogTitle>
          <Trans>No allowed</Trans>
        </DialogTitle>
        <DialogContent>
          <Trans>You dont have permissions for this action</Trans>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setNoExportAllowed(false)} color="primary">
            <Trans>Ok</Trans>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default YieldRegions;
