import React, { Component, useState, useEffect } from "react"; // let's also import Component
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import moment from "moment";
import "moment-timezone";
import { SlideTransition } from "../Util/Animations";
import MomentUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { Trans, useTranslation } from "react-i18next";
import { damageService } from "../../services/DamageService";
import { IDamage } from "../../../../shared/models/damage";
import { useForm } from "react-hook-form";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

export class EditDamageProps {
  open: boolean = true;
  damage?: IDamage;
  onClose?: (event: Event) => void;
  onSave?: (damage?: IDamage) => void;
}

export class EditDamageState {
  open: boolean = true;
  damage: IDamage = {} as IDamage;
}

export function EditDamage(props: Readonly<EditDamageProps>) {
  const [open, setOpen] = useState(props.open);
  const [damage, setDamage] = useState(props.damage);

  const { register, handleSubmit, watch, errors, setError } = useForm();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    setDamage(props.damage);
    setOpen(props.open);
  }, [props]);

  const onClose = (event: any) => {
    if (props.onClose) {
      props.onClose(event);
    }
  };

  const onSave = async (data: any) => {
    if (props.onSave) {
      if (damage) {
        let newDamage = damage;
        if (damage._id) {
          newDamage = await damageService.updateDamage(damage);
        } else {
          newDamage = await damageService.createDamage(damage);
        }
        props.onSave(newDamage);
        return;
      }
    }

    onClose(null);
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: string,
    fn?: (val: any) => any
  ) => {
    const newDamage = { ...damage } as any;
    newDamage[field] = fn ? fn(event.target.value) : event.target.value;
    setDamage(newDamage);
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={SlideTransition as any}
      keepMounted
      className="regular-dialog"
      onClose={(event) => onClose(event)}
    >
      {damage ? (
        <form
          noValidate
          onSubmit={handleSubmit(onSave)}
          className="form-container"
          autoComplete="off"
        >
          <DialogTitle>
            {damage._id ? (
              <>
                <Trans>Edit damage</Trans> {damage.name}
              </>
            ) : (
              <Trans>New damage</Trans>
            )}
          </DialogTitle>

          <DialogContent>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <TextField
                  autoFocus
                  id="name"
                  name="name"
                  label={<Trans>Name</Trans>}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  autoComplete="off"
                  onChange={(evt) => handleChange(evt, "name")}
                  value={damage.name || ""}
                  error={errors.name != null}
                  helperText={errors.name && t("name required")}
                  inputRef={register({ required: true })}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  autoFocus
                  id="code"
                  name="code"
                  label={<Trans>Code</Trans>}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  autoComplete="off"
                  onChange={(evt) => handleChange(evt, "code")}
                  value={damage.code || ""}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="min"
                  name="min"
                  label={<Trans>Min</Trans>}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  autoComplete="off"
                  onChange={(evt) => handleChange(evt, "min")}
                  value={damage.min || "0"}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="max"
                  name="max"
                  label={<Trans>Max</Trans>}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  autoComplete="off"
                  onChange={(evt) => handleChange(evt, "max")}
                  value={damage.max || "1"}

                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="minColor"
                  name="minColor"
                  label={<Trans>Min (color)</Trans>}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  autoComplete="off"
                  onChange={(evt) => handleChange(evt, "minColor")}
                  value={damage.minColor || ""}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="maxColor"
                  name="maxColor"
                  label={<Trans>Max (color)</Trans>}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  autoComplete="off"
                  onChange={(evt) => handleChange(evt, "maxColor")}
                  value={damage.maxColor || ""}
                />
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
            <Button type="submit" color="primary">
              <Trans>Save</Trans>
            </Button>
            <Button onClick={(event) => onClose(event)}>
              <Trans>Close</Trans>
            </Button>
          </DialogActions>
        </form>
      ) : null}
    </Dialog>
  );
}
