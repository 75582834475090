import { BaseService } from "./BaseService";
import { IRestriction } from "../../../shared/models/parcelle";
import { AdminDataSet, AdminQuery  } from "../../../shared/models/admin";

export class RestrictionService extends BaseService {

    getRestrictions(userQuery?: AdminQuery) {
        const params = userQuery ? JSON.stringify(userQuery) : null;
        console.log(userQuery, params)
        return this.get<AdminDataSet<IRestriction>>('restrictions',{filter: params});
    }

    createRestriction(restriction:IRestriction){
        return this.post<IRestriction>('restrictions', restriction);
    }

    updateRestriction(restriction: IRestriction) {
        return this.put<IRestriction>('restrictions', restriction);

    }

    deleteRestriction(restriction: IRestriction) {
        return this.delete<IRestriction>(`restrictions/${restriction._id}`);
    }

    importFile(file:File) {
        const formData = new FormData();
        formData.append('file', file);
        return this.post<any>('restrictions/import',formData);
    }

  
}

export const restrictionService = new RestrictionService();