import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { useTranslation, Trans } from "react-i18next";
import { Explotation, Parcele } from "./Explotation.model";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import Grid from "@material-ui/core/Grid";
import "./Explotations.css";
import {
  Checkbox,
  Radio,
  Icon,
  IconButton,
  CircularProgress,
  Input,
  InputAdornment,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import {
  ICulture,
  ICultureDetails,
  IExplotation,
  IParcelle,
} from "../../../../shared/models/parcelle";
import { userService } from "../../services/UserService";
import { utilService } from "../../services/UtilService";
import { modalService } from "../../services/ModalService";
import { set } from "lodash-es";
import find from 'lodash/find';

interface ItemSummatory {
  culture: ICulture;
  yield: number;
  estimated: number;
  surface: number;
  surfaceYieldAverage: number;
  surfaceYieldEstimated: number;

  yieldAverage: number;
  yieldEstimated: number;
  percent_garanteed_losses?: number;
  percent_non_garanteed_losses?: number;
}

interface CultureDetails{
  culture: string;
  percent_garanteed_losses?: number;
  percent_non_garanteed_losses?: number;

}

function Explotations() {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [
    selectedExplotation,
    setSelectedExplotation,
  ] = React.useState<IExplotation | null>(null);

  const [selected, setSelected] = useState<string[]>([]);
  const [explotations, setExplotations] = useState<IExplotation[] | null>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const [summatory, setSummatory] = useState<ItemSummatory[]>([]);
  const [editCultureDetails, setEditCultureDetails] = useState<CultureDetails | null>(null);


  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const handleClick = (
    event: React.MouseEvent<unknown>,
    explotation: IExplotation
  ) => {
    let name = explotation._id || "";
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [name];

    setSelected(newSelected);
    setSelectedExplotation(explotation);
  };

  const handleClickParcele = (parcelle: IParcelle) => {
    history.push(`/my-position/parcelle/${parcelle.name}`);
  };

  const handleClickExplotation = (explotation: IExplotation) => {
    history.push(`/my-position/explotation/${explotation._id}`);
  };

  const loadExplotations = async () => {
    const expl = await userService.getExplotationsWithParcelles();
    setExplotations(expl && expl.length > 0 ? expl : null);
  };

  const deleteExplotation = async (explotation: IExplotation) => {
    if (window.confirm(t("Are you sure you want to delete this farm?"))) {
      try {
        await userService.deleteExplotation(explotation);
        await loadExplotations();
      } catch (err) {
        modalService.error({ text: "ERROR: " + JSON.stringify(err) });
      }
    }
  };

  const deleteParcelle = async (
    parcelle: IParcelle,
    explotation: IExplotation
  ) => {
    if (
      window.confirm(
        t("Are you sure you want to remove this parcel from this farm?")
      )
    ) {
      try {
        await userService.deleteParcelle(parcelle, explotation);
        await loadExplotations();
        setSelectedExplotation({
          ...explotation,
          parcelles: explotation.parcelles.filter(
            (p) => p._id !== parcelle._id
          ),
        });
      } catch (err) {
        modalService.error({ text: "ERROR: " + JSON.stringify(err) });
      }
    }
  };

  const updateSummatory = async () => {
    const items: { [key: string]: ItemSummatory } = {};
    for (const parcelle of selectedExplotation?.parcelles || []) {
      const cultureObject = parcelle.targetCulture
        ? parcelle.targetCulture
        : {_id: parcelle.culture, name: parcelle.culture} as ICulture;

      const culture = cultureObject?._id || "";
      if (!items[culture])
        items[culture] = {
          culture: cultureObject,
          yield: 0,
          surface: 0,
          yieldAverage: 0,
          surfaceYieldAverage: 0,
          surfaceYieldEstimated: 0,
          yieldEstimated: 0,
          estimated: 0,
        };
      const ys = await userService.getYieldStatus(parcelle.name);
      const surface = utilService.getSurfaceFromShapeInHa(parcelle.shape);
      items[culture].surface += surface || 0;
      if (ys.status === "ready") {
        const y = await userService.getLastYield(parcelle.name);

        if (y && y.average) {
          parcelle.yieldAverage = parseFloat(y.average as any);
          items[culture].yield += parcelle.yieldAverage * surface;
          if(items[culture].yield > 0)
            items[culture].surfaceYieldAverage += surface || 0;
        }
      }


      const comments = ((await userService.getAllComments(parcelle.name)) || []).filter(c => !c.shape);

      if (comments && comments.length > 0) {
        let count = 0;
        let totalEstimated = 0;
        for (const comment of comments) {
          if (comment && comment.estimated_return) {
            totalEstimated += comment.estimated_return;
            count++;
          }
        }
        parcelle.yieldEstimated = count > 0 ? totalEstimated / count : 0;
        if(parcelle.yieldEstimated && parcelle.yieldEstimated > 0){
           items[culture].estimated += parcelle.yieldEstimated * surface;
            items[culture].surfaceYieldEstimated += surface || 0;
        }
      }
    }

    const summatory: ItemSummatory[] = Object.values(items);
    summatory.forEach((item) => {
      item.yieldAverage = item.yield / item.surfaceYieldAverage;
      item.yieldEstimated = item.estimated / item.surfaceYieldEstimated;
  
      const cultureDetails = selectedExplotation?.cultureDetails?.find(c => c.culture === item.culture._id);
      item.percent_garanteed_losses = cultureDetails?.percent_garanteed_losses
      item.percent_non_garanteed_losses = cultureDetails?.percent_non_garanteed_losses;
    });

    setSummatory(summatory);
    setLoading(false);
  };

  const handleEditCultureDetails = (item: ItemSummatory) => {
   const currentCulture = {
      culture: item.culture._id,
      percent_garanteed_losses: item.percent_garanteed_losses ,
      percent_non_garanteed_losses: item.percent_non_garanteed_losses 
    } as ICultureDetails;

    setEditCultureDetails(currentCulture);

  };

  const saveEditCultureDetails = async (culture: ICulture, reset = false) => {
    if(editCultureDetails){
      const newCultureDetails = reset ? {culture:editCultureDetails.culture, percent_garanteed_losses: undefined, percent_non_garanteed_losses: undefined} : editCultureDetails;
      const currentCultureDetails =  selectedExplotation?.cultureDetails?.find(c => c.culture === culture._id);
      let cultureDetails = selectedExplotation?.cultureDetails ? selectedExplotation?.cultureDetails?.map(c => {
        if(c.culture === culture._id){
          return newCultureDetails;
        }
        return c;
      }) : [];

      if((cultureDetails.length === 0 || !currentCultureDetails )&& newCultureDetails){
        cultureDetails.push(editCultureDetails);
      }

      if(cultureDetails){
        try {
          debugger;
          await userService.updateCultureDetails(selectedExplotation?._id || "", cultureDetails as any);
          setSelectedExplotation((prevState:any) => ({
            ...prevState,
            cultureDetails: cultureDetails
          }));
          setEditCultureDetails(null);
        
        } catch (err) {
          modalService.error({ text: "ERROR: " + JSON.stringify(err) });
        }
      }

    }
  }

  const resetCultureDetails = (culture: ICulture) => {
    saveEditCultureDetails(culture, true);
  };

  useEffect(() => {
    loadExplotations();
  }, [loading]);

  useEffect(() => {
    setLoading(true);
    updateSummatory();
  }, [selectedExplotation]);

  return (
    <div className="table-container">
      {explotations ? (
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <TableContainer component={Paper}>
              <Table stickyHeader aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>{t("Name")}</TableCell>
                    <TableCell align="center">{t("Position")}</TableCell>
                    <TableCell align="center">{t("Delete")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {explotations.map((row, index) => {
                    const isItemSelected = isSelected(row._id || "");
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        hover
                        key={row._id}
                        onClick={(event) => handleClick(event, row)}
                        role="checkbox"
                      >
                        <TableCell padding="checkbox">
                          <Radio
                            checked={isItemSelected}
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.name}
                        </TableCell>
                        <TableCell align="center">
                          <IconButton
                            onClick={(event) => handleClickExplotation(row)}
                          >
                            <Icon>gps_fixed</Icon>
                          </IconButton>
                        </TableCell>
                        <TableCell align="center">
                          <IconButton
                            onClick={() => deleteExplotation(row)}
                            color="secondary"
                            component="span"
                          >
                            <Icon>delete</Icon>
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          {selectedExplotation ? (
            <Grid item xs={12} sm={8}>
              <TableContainer component={Paper}>
                <Table stickyHeader aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">{t("Peek")}</TableCell>
                      <TableCell>{t("ID")}</TableCell>
                      <TableCell>{t("Parcel")}</TableCell>
                      <TableCell align="center">{t("Crop")}</TableCell>
                      <TableCell align="center">{t("Surface (ha)")}</TableCell>
                      <TableCell align="center">
                        {t("Simulated yield (qt/ha)")}
                      </TableCell>
                      <TableCell align="center">
                        {t("Estimated yield (qt/ha)")}
                      </TableCell>
                      <TableCell align="center">{t("Delete")}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedExplotation.parcelles.map((parcelle, index) => (
                      <TableRow key={parcelle._id}>
                        <TableCell align="center">
                          <IconButton
                            onClick={(event) => handleClickParcele(parcelle)}
                          >
                            <Icon>gps_fixed</Icon>
                          </IconButton>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {parcelle.name}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {parcelle.label}
                        </TableCell>
                        <TableCell align="center" style={{ width: "25%" }}>
                          {parcelle.targetCulture
                            ? parcelle.targetCulture.name
                            : parcelle.culture}
                        </TableCell>
                        <TableCell align="center" style={{ width: "10%" }}>
                          {utilService.getSurfaceFromShapeInHa(parcelle.shape)}
                        </TableCell>
                        <TableCell align="center" style={{ width: "10%" }}>
                          {parcelle.yieldAverage === undefined || parcelle.yieldAverage <= 0
                            ? "N.A"
                            : parcelle.yieldAverage?.toFixed(0)}
                        </TableCell>
                        <TableCell align="center" style={{ width: "10%" }}>
                          {parcelle.yieldEstimated === undefined || parcelle.yieldEstimated <= 0
                            ? "N.A"
                            : parcelle.yieldEstimated?.toFixed(0)}
                        </TableCell>
                        <TableCell style={{ width: "60px" }} align="center">
                          <IconButton
                            onClick={() =>
                              deleteParcelle(parcelle, selectedExplotation)
                            }
                            color="secondary"
                            component="span"
                          >
                            <Icon>delete</Icon>
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <br></br>
              {loading && (
                <Paper className="container">
                  <CircularProgress />
                </Paper>
              )}

              {!loading && summatory && summatory.length > 0 && (
                <TableContainer component={Paper}>
                <Table stickyHeader aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">{t("")}</TableCell>
                      <TableCell align="center">{t("Crop")}</TableCell>
                      <TableCell align="center">{t("Surface (ha)")}</TableCell>
                      <TableCell align="center">
                        {t("Simulated yield (qt/ha)")}
                      </TableCell>
                      <TableCell align="center">
                        {t("Estimated yield (qt/ha)")}
                      </TableCell>
                      <TableCell align="center">{t("Percentage of indemnisable losses (%)")}</TableCell>
                      <TableCell align="center">{t("Percentage of non-indemnisable losses (%)")}</TableCell>
                      <TableCell align="center">{t("Edit")}</TableCell>
                    </TableRow>
                  </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell
                          align="center"
                          rowSpan={summatory.length + 1}
                          style={{ fontSize: "1.0em" }}
                        >
                          {t("Valeurs à l’exploitation")}
                        </TableCell>
                      </TableRow>
                      {summatory.map((item, index) => (
                        <TableRow key={item.culture._id + "_culture"}>

                          <TableCell
                            style={{ width: "25%" }}
                            component="th"
                            scope="row"
                            align="center"
                          >
                            {item.culture?.name}
                          </TableCell>
                          <TableCell
                            style={{ width: "10%" }}
                            component="th"
                            scope="row"
                            align="center"
                          >
                            {item.surface.toFixed(2)}
                          </TableCell>
                          <TableCell
                            style={{ width: "10%" }}
                            component="th"
                            scope="row"
                            align="center"
                          >
                            {item.yieldAverage > 0
                              ? item.yieldAverage.toFixed(0)
                              : "N.A"}
                          </TableCell>
                          <TableCell
                            style={{ width: "10%" }}
                            component="th"
                            scope="row"
                            align="center"
                          >
                            {item.yieldEstimated > 0
                              ? item.yieldEstimated.toFixed(0)
                              : "N.A"}
                          </TableCell>
                          <TableCell
                            style={{ width: "10%" }}
                            component="th"
                            scope="row"
                            align="center"
                          >
                            {
                              editCultureDetails && editCultureDetails?.culture === item.culture._id ? (
                                <Input
                                  value={editCultureDetails.percent_garanteed_losses}
                                  type="number"
                                  inputProps={{
                                    className: "rightAlign",
                                    pattern: "[0-9]*",
                                    min: 0, // Minimo valor permitido
                                    max: 100, // Maximo valor permitido
                                  }}
                                  onChange={(evt) => {
                                    const value = Math.max(0, Math.min(100, Number(evt.target.value)));
                                    setEditCultureDetails((prevState:any) => ({
                                      ...prevState,
                                      percent_garanteed_losses: value,
                                    }));
                                  }}
                                  endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                />
                              ) : item.percent_garanteed_losses !== undefined ? (
                                `${item.percent_garanteed_losses.toFixed(0)}%`
                              ) : (
                                "N.A"
                              )
                            }

                          </TableCell>
                          <TableCell
                            style={{ width: "10%" }}
                            component="th"
                            scope="row"
                            align="center"
                          >
                            {
                              editCultureDetails && editCultureDetails?.culture === item.culture._id ? (
                                <Input
                                  value={editCultureDetails.percent_non_garanteed_losses}
                                  type="number"
                                  inputProps={{
                                    className: "rightAlign",
                                    pattern: "[0-9]*",
                                    min: 0, // Minimo valor permitido
                                    max: 100, // Maximo valor permitido
                                  }}
                                  onChange={(evt) => {
                                    const value = Math.max(0, Math.min(100, Number(evt.target.value)));
                                    setEditCultureDetails((prevState:any) => ({
                                      ...prevState,
                                      percent_non_garanteed_losses: value,
                                    }));
                                  }}
                                  endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                />
                              ) : item.percent_non_garanteed_losses !== undefined  ? (
                                `${item.percent_non_garanteed_losses.toFixed(0)}%`
                              ) : (
                                "N.A"
                              )
                            }

                          </TableCell>
                          <TableCell align="center">
                            {
                                editCultureDetails && editCultureDetails?.culture === item.culture._id ? (
                                  <>
                                  <IconButton
                                  onClick={() =>
                                    saveEditCultureDetails(item.culture)
                                  }
                                  color="secondary"
                                  component="span"
                                >
                                  <Icon>save</Icon>
                                </IconButton>
                                <IconButton
                                  onClick={() =>
                                    resetCultureDetails(item.culture)
                                  }
                                  color="secondary"
                                  component="span"
                                >
                                  <Icon>delete</Icon>
                                </IconButton>
                                  <IconButton
                                  onClick={() =>
                                    setEditCultureDetails(null)
                                  }
                                  color="primary"
                                  component="span"
                                >
                                  <Icon>cancel</Icon>
                                </IconButton>
                                  </>

                                ):(
                                  <IconButton
                                  onClick={() =>
                                    handleEditCultureDetails(item)
                                  }
                                  color="secondary"
                                  component="span"
                                >
                                  <Icon>edit</Icon>
                                </IconButton>
                                )
                            }

                        </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Grid>
          ) : null}
        </Grid>
      ) : (
        <Paper className="empty">
          <Trans>You don't have any explotation</Trans>
        </Paper>
      )}
    </div>
  );
}

export default Explotations;
