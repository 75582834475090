import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { IYieldRegionsClient } from "../../../../../shared/models/regions";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ErrorBar,
  LabelList,
  ResponsiveContainer,
  Label,
} from "recharts";

import "./Chart.css";

export interface YieldDistribution {
  yield: number;
  count: number;
}

export interface ChartDistributionProps {
  stats: YieldDistribution[];
  formatter?: (value: any) => any;
  title?: string;
  labelX?: string;
  labelY?: string;
  tickXFormatter?: (value: any) => any;
}

export const ChartDistribution = ({
  stats,
  formatter,
  title,
  labelX,
  labelY,
  tickXFormatter,
}: ChartDistributionProps) => {
  const { t } = useTranslation();
  const [data, setData] = useState<any>(stats);

  useEffect(() => {
    setData(stats);
  }, [stats]);

  return (
    <div>
      {title && <h3>{title}</h3>}

      <ResponsiveContainer width={"100%"} height={500}>
        <BarChart
          width={650}
          height={500}
          data={data}
          margin={{
            top: 20,
            right: 20,
            left: 20,
            bottom: 20,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            type="category"
            dataKey="yield"
            stroke="#a0a0a0"
            interval={0}
            tickFormatter={tickXFormatter}
            domain={["dataMin", "dataMax"]}
          >
            {labelX && <Label dy={20}>{labelX}</Label>}
          </XAxis>
          <YAxis type="number" interval={0} dataKey="count">
            {labelY && (
              <Label dx={-10} angle={-90}>
                {labelY}
              </Label>
            )}
          </YAxis>
          <Tooltip />

          <Bar dataKey="count" fill="#1B5E20"></Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};
