import React, { Component } from "react"; // let's also import Component
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Icon,
} from "@material-ui/core";
import { SlideTransition } from "../Util/Animations";
import { Trans } from "react-i18next";


export class AlertDialogProps {
    open: boolean = true;
    title: string;
    description: string;
    email:boolean = false;
    onClose?: (event: Event) => void;

    constructor(
        title: string,
        description: string,
        email:boolean,
        onClose?: (event: Event) => void
    ){
        this.open = true;
        this.title = title;
        this.description =  description;
        this.email = email;
        this.onClose = onClose;
        }
}

export class AlertDialogState {
  open: boolean = true;
}

export class AlertDialog extends Component<AlertDialogProps, AlertDialogState> {
         state = {
           open: false,
         };

         static getDerivedStateFromProps(props: AlertDialogProps) {
           return {
             open: props.open || false,
           };
         }

         componentDidMount() {}

         onClose(event: any) {
           if (this.props.onClose) {
             this.props.onClose(event);
           }
           this.setState({ open: false });
         }

         sendMail(event:any){
           document.location.href = 'mailto:info@synomen.com';
         }

         // render will know everything!
         render() {
           return (
             <Dialog
               open={this.state.open}
               TransitionComponent={SlideTransition as any}
               keepMounted
               onClose={(event) => this.onClose(event)}
               aria-labelledby="alert-dialog-slide-title"
               aria-describedby="alert-dialog-slide-description"
             >
               <DialogTitle>
                 <Trans>{this.props.title}</Trans>
               </DialogTitle>
               <DialogContent>
                 <Trans>{this.props.description}</Trans>
               </DialogContent>

               <DialogActions>
                 {this.props.email && (
                   <Button
                     color="primary"
                     onClick={(event) => this.sendMail(event)}
                   >
                     <Icon>mail</Icon>
                     <Trans>Contact</Trans>
                   </Button>
                 )}
                 <Button onClick={(event) => this.onClose(event)}>
                   <Trans>Close</Trans>
                 </Button>
               </DialogActions>
             </Dialog>
           );
         }
       }
