import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { CircularProgress } from '@material-ui/core';

import './index.css';
import App from './components/App/App';
import * as serviceWorker from './serviceWorker';

// import i18n (needs to be bundled ;)) 
import './i18n';
import { ErrorBoundary } from './components/App/ErrorBoundary';
import isCordova from './components/Util/isCordova';

if (isCordova) {
  document.addEventListener(
    "deviceready",
    () => {
      ReactDOM.render(
        <React.Fragment>
          <ErrorBoundary>
            <App />
          </ErrorBoundary>
        </React.Fragment>,
        document.getElementById("root")
      );
    },
    false
  );

} else {
  ReactDOM.render(
    <React.Fragment>
      <Suspense fallback={<CircularProgress />}>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </Suspense>
    </React.Fragment>,
    document.getElementById("root")
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
