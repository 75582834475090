import { area, distance, point } from "@turf/turf";

export class UtilService {
  // Función para convertir LatLng a Array si es necesario
  private convertLatLngToArray(coordinates: any): any {
    // Verificar si es un array de arrays (por ejemplo, un polígono)
    if (Array.isArray(coordinates) && coordinates.length > 0) {
      return coordinates.map((coord) => {
        // Verificar si el elemento es un array de LatLng y convertir cada uno
        if (Array.isArray(coord)) {
          return this.convertLatLngToArray(coord); // Recursividad para manejar múltiples niveles
        } else {
          return this.convertLatLngIfNeeded(coord);
        }
      });
    } else {
      return this.convertLatLngIfNeeded(coordinates);
    }
  }

  // Convierte un objeto LatLng a un array, si es necesario
  private convertLatLngIfNeeded(coord: any): any {
    // Verificar si coord es un objeto LatLng
    if (
      coord &&
      typeof coord === "object" &&
      "lat" in coord &&
      "lng" in coord
    ) {
      return [coord.lng, coord.lat]; // Convertir a array [lng, lat]
    }
    return coord; // Devolver el elemento sin cambios si no es LatLng
  }

  getSurfaceFromShape(shape: any) {
    const convertedCoordinates = this.convertLatLngToArray(shape.coordinates);
    return parseFloat(
      area({
        type: shape.type,
        coordinates: [convertedCoordinates],
      }).toFixed(2)
    );
  }

  getSurfaceFromShapeInHa(shape: any) {
    const convertedCoordinates = this.convertLatLngToArray(shape.coordinates);
    return parseFloat(
      (
        area({
          type: shape.type,
          coordinates: [convertedCoordinates],
        }) / 10000
      ).toFixed(2)
    );
  }
}

export const utilService = new UtilService();
