import React, { ChangeEvent, Component } from "react"; // let's also import Component
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  DialogTitle,
} from "@material-ui/core";
import "moment-timezone";
import { SlideTransition } from "../Util/Animations";
import {
  IParcelle,
  IExplotation,
  ICulture,
} from "../../../../shared/models/parcelle";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { Trans } from "react-i18next";
import i18next from "i18next";

import { userService } from "../../services/UserService";
import ReactDOM from "react-dom";
export const OTHER_CULTURE = "OTHER_CULTURE";

export class EditParcelleProps {
  open: boolean = true;
  parcelle?: IParcelle;
  explotation?: IExplotation;
  onClose?: (event: Event) => void;
  onSave?: (parcelle?: IParcelle) => void;
}

export class EditParcelleState {
  open: boolean = true;
  parcelle: IParcelle = {} as IParcelle;
  cultures: ICulture[] = [];
  culture?: ICulture;
  targetCulture?: ICulture;
  otherCrop?: boolean;
  explotation: IExplotation = { name: "" } as IExplotation;
}

const filter = createFilterOptions<IExplotation>();
const filterCulture = createFilterOptions<ICulture>();

export class EditParcelle extends Component<
  EditParcelleProps,
  EditParcelleState
> {
  constructor(props: Readonly<EditParcelleProps>) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  state = new EditParcelleState();
  explotations: IExplotation[] = [];
  cultures: ICulture[] = [];

  static getDerivedStateFromProps(
    props: EditParcelleProps,
    state: EditParcelleState
  ) {
    //debugger;

    return {
      ...state,
      open: props.open || false,
      parcelle: props.parcelle || { name: "" },
      targetCulture: state.targetCulture || props.parcelle?.targetCulture,
    };
  }

  async componentDidMount() {
    //debugger;
    this.loadCultures();
    this.updateExplotations();
  }

  async loadCultures() {
    const cultures = await userService.getCultures();
    this.cultures = [{ _id: OTHER_CULTURE, name: "Another crop" }, ...cultures];
  }

  async updateExplotations() {
    this.explotations = await userService.getExplotations();
  }

  componentDidUpdate(newProps: any) {
    if (this.explotations && newProps.parcelle != this.props.parcelle) {
      const explotation = this.explotations.find((ex) => {
        if (
          ex.parcelles &&
          this.state.parcelle &&
          (ex.parcelles as any[]).indexOf(this.state.parcelle?._id) > -1
        ) {
          return true;
        } else {
          return false;
        }
      });

      if (explotation) {
        this.setState({ ...this.state, explotation });
      } else {
        this.setState({
          ...this.state,
          explotation: { name: "" } as IExplotation,
        });
      }
    }
  }

  onClose(event: any) {
    if (this.props.onClose) {
      this.props.onClose(event);
      this.setState({
        ...this.state,
        otherCrop: false,
        targetCulture: undefined,
      });
    }
  }

  async onSave() {
    if (this.props.onSave) {
      //debugger;
      if (this.state.parcelle) {
        const parcelle = {
          label: this.state.parcelle.label,
          culture: this.state.parcelle.culture,
          explotation: this.state.explotation,
          targetCulture: this.state.targetCulture,
        };

        const newParcelle = await userService.editParcelle(
          this.state.parcelle.name,
          parcelle as any
        );
        if (this.props.parcelle) {
          this.props.parcelle.culture = this.state.parcelle.culture;
          this.props.parcelle.label = this.state.parcelle.label;
          this.props.parcelle.targetCulture = newParcelle.targetCulture;
          this.setState({
            ...this.state,
            explotation: { name: "" } as IExplotation,
          });
          this.updateExplotations();
        }
        this.props.onSave(newParcelle);
        this.setState({
          ...this.state,
          otherCrop: false,
          targetCulture: undefined,
        });
        return;
      }
    }

    this.onClose(null);
  }

  handleChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: string
  ) {
    const newState = { ...this.state } as any;
    newState.parcelle[field] = event.target.value;
    this.setState(newState);
  }

  handleChangeCulture(event: React.ChangeEvent<{}>, culture: ICulture | null) {
    this.setState({
      ...this.state,
      parcelle: {
        ...this.state.parcelle,
        targetCulture: culture ? culture : undefined,
      },
    });
  }

  // render will know everything!
  render() {
    return (
      <Dialog
        open={this.state.open}
        TransitionComponent={SlideTransition as any}
        keepMounted
        className="regular-dialog"
        onClose={(event) => this.onClose(event)}
      >
        {this.state.parcelle ? (
          <>
            <DialogTitle>
              <Trans>Edit parcel</Trans> ({this.state.parcelle?.name})
            </DialogTitle>

            <DialogContent>
              <TextField
                autoFocus
                id="label"
                label={<Trans>Parcel name</Trans>}
                variant="outlined"
                margin="normal"
                fullWidth
                onChange={(evt) => this.handleChange(evt, "label")}
                value={this.state.parcelle.label || ""}
              />
              <Autocomplete
                value={this.state.explotation}
                onInputChange={(event, newInputValue, reason) => {
                  if (reason === "clear" || newInputValue === "") {
                    const { explotation, ...newState } = this.state;
                    this.setState(newState);
                  }
                }}
                onClose={(event, reason) => {
                  const newInputValue = (event.currentTarget as any).value;
                  debugger;
                  if (
                    newInputValue !== "" &&
                    this.state.explotation.name !== newInputValue
                  ) {
                    if (reason === "blur") {
                      this.setState({
                        ...this.state,
                        explotation: newInputValue.name
                          ? newInputValue
                          : ({
                              __name: newInputValue,
                              name: ` "${newInputValue}"`,
                            } as IExplotation),
                      });
                    }
                  }
                }}
                onChange={(event, newValue: any) => {
                  if (newValue) {
                    const explotation = newValue.name
                      ? newValue
                      : { name: newValue, __name: newValue };

                    this.setState({
                      ...this.state,
                      explotation: explotation as IExplotation,
                    });
                  } else {
                    const { explotation, ...newState } = this.state;
                    this.setState(newState);
                  }
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);
                  // Suggest the creation of a new value
                  if (params.inputValue !== "") {
                    const newExplotation = {
                      __name: params.inputValue,
                      name: ` "${params.inputValue}"`,
                    } as IExplotation;

                    filtered.push(newExplotation);
                  }
                  return filtered;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                id="explotation"
                options={this.explotations}
                getOptionLabel={(option) => {
                  // Value selected with enter, right from the input
                  if (typeof option === "string") {
                    return option;
                  }
                  // Add "xxx" option created dynamically
                  if (option.__name) {
                    return option.__name;
                  }
                  // Regular option
                  return option.name;
                }}
                renderOption={(option) => option.name}
                fullWidth
                freeSolo
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={<Trans>Farm name</Trans>}
                    variant="outlined"
                    margin="normal"
                  />
                )}
              />
              <Autocomplete
                value={this.state.targetCulture || null}
                onChange={(event, newValue: any) => {
                  const otherCrop = newValue && newValue._id === OTHER_CULTURE;
                  if (newValue) {
                    this.setState({
                      ...this.state,
                      otherCrop,
                      targetCulture: newValue as ICulture,
                    });
                  } else {
                    const newState = {
                      ...this.state,
                      otherCrop,
                    };
                    delete newState.targetCulture;
                    this.setState(newState);
                  }
                }}
                filterOptions={(options, params) => {
                  const filtered = filterCulture(options, params);

                  // Suggest the creation of a new value
                  if (params.inputValue !== "") {
                    filtered.push(({
                      __name: params.inputValue,
                      name: ` "${params.inputValue}"`,
                    } as unknown) as ICulture);
                  }

                  return filtered;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                id="culture"
                options={this.cultures}
                getOptionLabel={(option) => {
                  // Value selected with enter, right from the input
                  if (typeof option === "string") {
                    return option;
                  }
                  // Add "xxx" option created dynamically
                  if (option.__name) {
                    return option.__name;
                  }
                  // Regular option
                  return i18next.t(option.name);
                }}
                getOptionSelected={(option) =>
                  option._id === this.state.targetCulture?._id
                }
                renderOption={(option) => <Trans>{option.name}</Trans>}
                fullWidth
                freeSolo
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={<Trans>Crop</Trans>}
                    variant="outlined"
                    margin="normal"
                  />
                )}
              />
              {this.state.otherCrop ? (
                <TextField
                  autoFocus
                  id="culture"
                  label={<Trans>New crop</Trans>}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  onChange={(evt) => this.handleChange(evt, "culture")}
                  value={this.state.parcelle.culture || ""}
                />
              ) : null}
            </DialogContent>

            <DialogActions>
              <Button onClick={(event) => this.onSave()} color="primary">
                <Trans>Save</Trans>
              </Button>
              <Button onClick={(event) => this.onClose(event)}>
                <Trans>Close</Trans>
              </Button>
            </DialogActions>
          </>
        ) : null}
      </Dialog>
    );
  }
}
